import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import AppContent from './layouts/AppContent';
import Login from './views/auth/Login';
import ForgotPassword from './views/auth/ForgotPassword';
import ResetPassword from './views/auth/ResetPassword';
import FormTicketFeedback from './views/ticket_feedback';
import './assets/main.scss';
import './assets/size.css';

function App() {
  const [authenticated, setAuthenticated] = useState(true)

  useEffect(() => {
    let currentUser = localStorage.getItem('currentUser')
    let pathname = window.location.pathname

    if (!currentUser) {
      setAuthenticated(false)
      if (pathname !== "/login" && pathname !== "/forgot-password" && pathname !== "/reset-password" && pathname !== "/ticket-feedback") {
        window.location.href = "/login"
      }
    } else {
      setAuthenticated(true)
      if (pathname === "/login") {
        window.location.href = "/"
      }
    }

  }, []);

  return (
    <div>
      <Router>
        <Layout theme="light">
          <Layout style={{ minHeight: '100vh' }}>

            {
              (authenticated) ?
                <AppContent />
                :
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/forgot-password" component={ForgotPassword} />
                  <Route exact path="/reset-password" component={ResetPassword} />
                  <Route exact path="/ticket-feedback" component={FormTicketFeedback} />
                </Switch>
            }
          </Layout>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
