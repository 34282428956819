import React, { useState, useEffect } from "react";
import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import { Card, Space, Button, Typography, Select, Drawer, Row, Col, Form, DatePicker, Avatar, Image, Popover } from 'antd'
import { FilterOutlined } from '@ant-design/icons';
import { DataTable, PageTitle } from '../../components'
import { api } from '../../services/api'
import moment from 'moment'

const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker;

const Tracking = () => {
    const [activeMarker, setActiveMarker] = useState(null);
    const [showDrawer, setShowDrawer] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [markers, setMakers] = useState([]);
    const [markerLocations, setMakerLocations] = useState([]);
    const [positions, setPositions] = useState([]);
    const [users, setUsers] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [positionIds, setPositionIds] = useState([]);
    const [trackingActivty, setTrackingActivity] = useState([]);
    const [centerValue, setCenterValue] = useState("-6.1753924, 106.8249641")

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAEfE7q8qQx1rRrO8ZA-gpq7YrpiFiBrzk" // Add your API key
    });

    const fetchPosition = () => {
        api("GET", "positions").then((res) => {
            let position = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                position.push(obj)
            });
            setPositions(position)
        })
    }

    const fetchUser = (position_id = []) => {
        api("GET", `users?position_id=${position_id}`).then((res) => {
            let user = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                user.push(obj)
            });
            setUsers(user)
        })
    }

    const fetchLocation = () => {
        api("GET", `locations`).then((res) => {
            let marker = []
            res.map((item, i) => {
                if (parseFloat(item.latitude) && parseFloat(item.longitude)) {
                    marker.push({
                        id: item.id,
                        name: `${item.name}, ${item.address}`,
                        position: {
                            lat: parseFloat(item.latitude),
                            lng: parseFloat(item.longitude)
                        },
                    })
                }
            })

            setLoaded(true)
            setMakerLocations(marker)
        })
    }

    const fetchTrackingActivity = () => {
        api("GET", `tracking-activities?`).then((res) => {
            setTrackingActivity(res)
            setLoaded(true)
        })
    }

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach(({ position }) => bounds.extend(position));
        markerLocations.forEach(({ position }) => bounds.extend(position));
        map.fitBounds(bounds);
    };

    const fetchTracking = () => {
        api("GET", `trackings`).then((res) => {
            let marker = []
            res.map((item, i) => {
                let lat = parseFloat(item.coordinate.split(",")[0])
                let lng = parseFloat(item.coordinate.split(",")[1])

                marker.push({
                    id: 1000 + i + 1,
                    name: `${item.name} - ${item.position_name}, ${item.address} - ${moment(item.created_at).format('YYYY-MM-DD HH:mm')}`,
                    position: {
                        lat: lat,
                        lng: lng
                    }
                })
            })

            setLoaded(true)
            setMakers(marker)
        })


    }

    const fetchTrackingFilter = (v) => {
        const start_date = v?.date ? moment(v?.date[0]).format("YYYY-MM-DD HH:mm:ss") : ''
        const end_date = v?.date ? moment(v?.date[1]).format("YYYY-MM-DD HH:mm:ss") : ''

        api("GET", `trackings?position_id=${positionIds}&user_id=${userIds}&start_date=${start_date}&end_date=${end_date}`).then((res) => {
            let marker = []
            res.map((item, i) => {
                let lat = parseFloat(item.coordinate.split(",")[0])
                let lng = parseFloat(item.coordinate.split(",")[1])

                marker.push({
                    id: 1000 + i + 1,
                    name: `${item.name} - ${item.position_name}, ${item.address} - ${moment(item.created_at).format('YYYY-MM-DD HH:mm')}`,
                    position: {
                        lat: lat,
                        lng: lng
                    }
                })
            })

            setLoaded(true)
            setMakers(marker)
        })


    }

    const handlePosition = (v) => {
        const position = []
        v.forEach((el) => {
            position.push(el)
        })

        if (position.length) fetchUser(position)
        else setUsers([])

        setPositionIds(position)
    }

    const handleUser = (v) => {
        const user = []
        v.forEach((el) => {
            user.push(el)
        })
        setUserIds(user)
    }

    useEffect(() => {
        fetchTracking()
        fetchPosition()
        fetchTrackingActivity()
        fetchLocation()

    }, []);

    const onFinishFilter = (v) => {
        fetchTrackingFilter(v)
        setShowDrawer(false)
    }

    const resetFilter = async () => {
        fetchTracking()
        setShowDrawer(false)
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Department',
            dataIndex: 'department_name',
            key: 'department_name'
        },
        {
            title: 'Position',
            dataIndex: 'position_name',
            key: 'position_name'
        },
        {
            title: 'Still',
            dataIndex: 'still',
            key: 'still',
            align: 'center'
        },
        {
            title: 'Moving',
            dataIndex: 'moving',
            key: 'moving',
            align: 'center'
        },
        {
            title: 'Unknown',
            dataIndex: 'unknown',
            key: 'unknown',
            align: 'center'
        },
        {
            title: 'Total Activity',
            dataIndex: 'total_activity',
            key: 'total_activity',
            align: 'center'
        }
    ];

    const columnRights = [
        {
            title: 'Employee Name',
            key: 'name',
            render: (row) => (
                <Button onClick={() => changeCoordinate(row?.coordinate)} type="text" size="small">{row?.name}</Button>
            ),
            align: 'center',
            width: "15%"
        },
        {
            title: 'Coordinate',
            render: (row) => (
                <Popover content={row.coordinate} title="Coordinate" trigger="hover">
                    <Button type="text">
                        {
                            `${row?.coordinate.split(',')[0].substring(0, 8)}... - ${row?.coordinate.split(',')[1].substring(0, 8)}...`
                        }
                    </Button>
                </Popover>
            ),
            key: 'coordinate',
            align: 'center'
        }
    ];

    const exportReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/generate-xlsx`);
    }

    const center = {
        lat: parseInt(centerValue.split(",")[0]),
        lng: parseInt(centerValue.split(",")[1])
    };

    const changeCoordinate = (v) => {
        setLoaded(false)
        setCenterValue(v)
        const m = markers.filter((el) => el.position.lat == v.split(',')[0] && el.position.lng == v.split(',')[1])
        if (m.length > 0) {
            handleActiveMarker(m[0].id)
        }
        setLoaded(true)
    }

    return (
        <Card className="content-container">
            <PageTitle title="Tracking" breadcrumbs={[['Tracking']]} />
            <Card title={
                <div className="full-width">
                    <Row justify="space-between">
                        <Col>
                            <Text strong style={{ float: 'left' }}>Location</Text>
                        </Col>
                        <Col>
                            <Space>
                                <Button onClick={() => setShowDrawer(true)} shape="round" icon={<FilterOutlined />} size="middle" />
                            </Space>
                        </Col>
                    </Row>
                </div>}>
                <Row justify='space-between' style={{ marginBottom: 20 }}>
                    <Col span={14}>
                        {
                            isLoaded && loaded ?
                                <GoogleMap
                                    onLoad={handleOnLoad}
                                    onClick={() => setActiveMarker(null)}
                                    mapContainerStyle={{ width: "100%", height: "100%", maxWidth: "1000px", maxHeight: "500px" }}
                                    center={center}
                                    zoom={10}
                                >
                                    {markers.map(({ id, name, position }) => (
                                        <Marker
                                            key={id}
                                            position={position}
                                            onClick={() => handleActiveMarker(id)}
                                        >
                                            {activeMarker === id ? (
                                                <InfoWindow
                                                    onCloseClick={() => setActiveMarker(null)}>
                                                    <div>{name}</div>
                                                </InfoWindow>
                                            ) : null}
                                        </Marker>
                                    ))}

                                    {markerLocations.map(({ id, name, position }) => (
                                        <Marker
                                            key={id}
                                            position={position}
                                            onClick={() => handleActiveMarker(id)}
                                            icon={{
                                                url: `${process.env.REACT_APP_PUBLIC_URL}/location.png`
                                            }}
                                        >
                                            {activeMarker === id ? (
                                                <InfoWindow
                                                    onCloseClick={() => setActiveMarker(null)}>
                                                    <div>{name}</div>
                                                </InfoWindow>
                                            ) : null}
                                        </Marker>
                                    ))}
                                </GoogleMap>
                                :
                                null
                        }
                    </Col>
                    <Col span={9}>
                        <Card>
                            {
                                loaded ?
                                    <DataTable search={false} size='small' bordered dataSource={trackingActivty} columns={columnRights} />
                                    : null
                            }
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Card title={
                            <div style={{ float: 'right' }}>
                                <Button type="primary" onClick={exportReport}>
                                    Export Xlsx
                                </Button>
                            </div>
                        }>
                            {
                                loaded ?
                                    <DataTable size='small' bordered dataSource={trackingActivty} columns={columns} />
                                    : null
                            }
                        </Card>
                    </Col>
                </Row>
                {
                    showDrawer ?
                        <Drawer
                            title="Search Location"
                            placement="right"
                            width={400}
                            onClose={() => setShowDrawer(false)}
                            open={showDrawer}
                        >
                            <Form onFinish={onFinishFilter} layout='vertical'>
                                <Form.Item label="Position" name="position_id">
                                    <Select
                                        mode="multiple"
                                        placeholder="Select Position"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        onChange={handlePosition}
                                        options={positions}
                                    />
                                </Form.Item>
                                <Form.Item label="User" name="user_id">
                                    <Select
                                        mode="multiple"
                                        placeholder="Select User"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        onChange={handleUser}
                                        options={users}
                                    />
                                </Form.Item>

                                <Form.Item label="Date" name="date">
                                    <RangePicker showTime format="YYYY-MM-DD HH:mm" />
                                </Form.Item>

                                <Space>
                                    <Form.Item>
                                        <Button type="primary" htmlType='submit'>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button onClick={resetFilter} danger type="primary">
                                            Reset Filter
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Form>
                        </Drawer>
                        : null
                }
            </Card>
        </Card >
    )
}

export default Tracking