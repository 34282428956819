import { Card, Row, Col, Typography, Space, Button, Select, DatePicker, Drawer, Form } from 'antd';
import { useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import { FilterOutlined } from '@ant-design/icons';
import { PageTitle, Spinner } from '../../components';
import { api, currentUser } from '../../services/api'
import ProfileBox from './Profile';
import DataTicket from './DataTicket';
import DataKPI from './DataKPI';
import DataKPI2 from './DataKPI2';
import DataTechnician from './DataTechnician';
import moment from 'moment'
import DataTicketByType from './DataTicketType';
import DataTicketByStatus from './DataTicketStatus';

const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker;

Chart.register(...registerables);

const Index = () => {
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [showDrawer, setShowDrawer] = useState(false);
    const [countTickets, setCountTickets] = useState([])
    const [monthlyTickets, setMonthlyTickets] = useState([])
    const [monthlyKPI, setMonthlyKPI] = useState([])
    const [technicians, setTechnicians] = useState([])
    const [departments, setDepartments] = useState([])
    const [scopes, setScopes] = useState([])
    const [scopeIds, setScopeIds] = useState([])
    const [locations, setLocations] = useState([])
    const [locationIds, setLocationIds] = useState([])
    const [types, setTypes] = useState([])
    const [priorities, setPriorities] = useState([])
    const [outstanding, setOutstanding] = useState([])
    const [achievement, setAchievement] = useState([])

    const fetchDashboard = () => {
        api("GET", "dashboard").then((res) => {
            setCountTickets(res.tickets)
            setMonthlyTickets(res.monthly)
            setMonthlyKPI(res.kpis)
            setDepartments(res.departments)
            setOutstanding(res.types)
            setAchievement(res.status)
            setTechnicians(res.technicians)
            setLoaded(true)
        })
    }

    const fetchScope = () => {
        api("GET", "scopes").then((res) => {
            let scope = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                scope.push(obj)
            });
            setScopes(scope)
        })
    }

    const fetchType = () => {
        api("GET", "types").then((res) => {
            setTypes(res)
        })
    }

    const fetchPriority = () => {
        api("GET", "priorities").then((res) => {
            setPriorities(res)
        })
    }

    const fetchLocation = () => {
        api("GET", "locations").then((res) => {
            let location = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                location.push(obj)
            });
            setLocations(location)
        })
    }

    useEffect(() => {
        fetchDashboard()
        fetchScope()
        fetchLocation()
        fetchPriority()
        fetchType()
    }, []);

    const handleLocation = (v) => {
        const loc = []
        v.forEach((el) => {
            loc.push(el)
        })

        setLocationIds(loc)
    }

    const handleScope = (v) => {
        const scope = []
        v.forEach((el) => {
            scope.push(el)
        })

        setScopeIds(scope)
    }

    const onFinishFilter = (v) => {
        const year = moment(v.year).format("YYYY") 
        const start_date = v.requested_at ? moment(v.requested_at[0]).format("YYYY-MM-DD HH:mm:ss") : ''
        const end_date = v.requested_at ? moment(v.requested_at[1]).format("YYYY-MM-DD HH:mm:ss") : ''
        api("GET", `dashboard?year=${year}&scope_id=${scopeIds}&location_id=${locationIds}&priority_id=${v.priority_id ? v.priority_id : ''}&type_id=${v.type_id ? v.type_id : ''}&status=${v.status ? v.status : ''}&start_date=${start_date}&end_date=${end_date}`)
            .then((res) => {
                setCountTickets(res.tickets)
                setMonthlyTickets(res.monthly)
                setMonthlyKPI(res.kpis)
                setDepartments(res.departments)
                setOutstanding(res.types)
                setAchievement(res.status)
                setTechnicians(res.technicians)
                setLoaded(true)
                setShowDrawer(false)
                setScopeIds([])
                setLocationIds([])
            })
    }

    const resetFilter = () => {
        fetchDashboard()
        setShowDrawer(false)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Dashboard" breadcrumbs={[['Dashboard']]} />

                <Row justify='space-between'>
                    <Col span={7}>
                        {(loaded) ?
                            <ProfileBox departments={departments} />
                            :
                            <Spinner />
                        }
                    </Col>

                    <Col span={16}>
                        <Row style={{ marginBottom: 20 }} justify='space-between'>
                            <Col>
                                <Text strong style={{ float: 'left' }}>Tickets</Text>
                            </Col>
                            <Col>
                                <Space>
                                    <Button onClick={() => setShowDrawer(true)} shape="round" icon={<FilterOutlined />} size="middle" />
                                </Space>
                            </Col>
                        </Row>
                        <div style={{ marginBottom: 20 }}>
                            {(loaded) ?
                                <DataTicket monthlyTickets={monthlyTickets} countTickets={countTickets} />
                                :
                                <Spinner />
                            }
                        </div>

                        {currentUser.role_id != 2 ?
                            <div style={{ marginBottom: 20 }}>
                                {(loaded) ?
                                    <DataTechnician technician={technicians} />
                                    :
                                    <Spinner />
                                }
                            </div>
                        : null }
                        
                        {currentUser.role_id == 3 ?
                            <div style={{ marginBottom: 20 }}>
                                {(loaded) ?
                                    <DataKPI monthlyKPI={monthlyKPI} />
                                    :
                                    <Spinner />
                                }
                            </div>
                        : null }

                        {currentUser.role_id == 2 ?
                            <div style={{ marginBottom: 20 }}>
                                {(loaded) ?
                                    <DataKPI2 monthlyKPI={monthlyKPI} />
                                    :
                                    <Spinner />
                                }
                            </div>
                        : null }

                        <Row justify="space-between">
                            <Col span={12}>
                                {
                                    loaded ?
                                        <Card style={{ borderRadius: 5, width: '100%' }} className="custom-box-shadow"
                                            title={
                                                <Row justify="space-between">
                                                    <Col>
                                                        Outstanding
                                                    </Col>
                                                </Row>
                                            }
                                        >
                                            <DataTicketByType ticketType={outstanding} />
                                        </Card>
                                        :
                                        <Spinner />
                                }
                            </Col>
                            <Col span={11}>
                                {
                                    loaded ?
                                        <Card style={{ borderRadius: 5, width: '100%' }} className="custom-box-shadow"
                                            title={
                                                <Row justify="space-between">
                                                    <Col>
                                                        Achievement
                                                    </Col>
                                                </Row>
                                            }
                                        >
                                            <DataTicketByStatus ticketStatus={achievement} />
                                        </Card> :
                                        <Spinner />
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {
                    showDrawer ?
                        <Drawer
                            title="Filter Dashboard"
                            placement="right"
                            width={400}
                            onClose={() => setShowDrawer(false)}
                            open={showDrawer}
                        >
                            <Form onFinish={onFinishFilter} form={form} layout='vertical'>

                                <Form.Item label="Year" name="year">
                                    <DatePicker style={{ width: "100%" }} picker="year" />
                                </Form.Item>
                                <Form.Item label="Scopes" name="scopes">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select Scope"
                                        onChange={handleScope}
                                        options={scopes}
                                    />
                                </Form.Item>
                                <Form.Item label="Type" name="type_id">
                                    <Select placeholder="Select Type">
                                        {
                                            types.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Priority" name="priority_id">
                                    <Select placeholder="Select Priority">
                                        {
                                            priorities.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Locations" name="locations">
                                    <Select
                                        mode="tags"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select Location"
                                        onChange={handleLocation}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={locations}
                                    />
                                </Form.Item>

                                <Form.Item label="Requested At" name="requested_at">
                                    <RangePicker />
                                </Form.Item>

                                <Space>
                                    <Form.Item>
                                        <Button type="primary" htmlType='submit'>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button onClick={resetFilter} type="primary" danger>
                                            Reset Filter
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Form>
                        </Drawer>
                        : null
                }
            </Card>
        </div>
    )
}

export default Index