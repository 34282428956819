
import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Empty, Card, List, Checkbox, Typography, Descriptions, Avatar, Button, Col, Form, Input, message, Row, Select, Space, Drawer, Collapse, Image } from 'antd'
import moment from 'moment'
import { api } from '../../../services/api'
import { FormModal } from '../../../components/Modal'
import { Uploader, Spinner } from '../../../components'
import { roleAccess } from '../../../helpers/menus'

const { Text } = Typography
const { Option } = Select
const { Panel } = Collapse;


const Handling = () => {
    const [form] = Form.useForm()
    const history = useHistory()
    const { ticketId } = useParams()
    const [ticket, setTicket] = useState([])

    const [cannedReply, setCannedReply] = useState([])
    const [procedures, setProcedures] = useState([])
    const [modalTitle, setModalTitle] = useState('')
    const [showHandlingModal, setShowHandlingModal] = useState(false)
    const [showProcedure, setShowProcedure] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [hasSolved, setHasSolved] = useState('')
    const [attachment, setAttachment] = useState('')
    const [loaded, setLoaded] = useState(false)


    const fetchTicket = () => {
        api("GET", `ticket/${ticketId}`).then((res) => {
            res?.handlings?.forEach(e => {
                e.attachment_name = e.attachment.replace(process.env.REACT_APP_API_URL + '/attachments/', "")
                e.extFile = e.attachment_name.split('.').pop();
            });
            setTicket(res)
            setLoaded(true)
        })
    }

    const fetchCannedReply = () => {
        api("GET", "canned-replies").then((res) => {
            setCannedReply(res)
        })
    }

    const fetchProcedure = () => {
        api("GET", "lsr-procedures").then((res) => {
            setProcedures(res)
        })
    }

    useEffect(() => {
        fetchCannedReply()
        fetchTicket()
        fetchProcedure()
    }, [])

    const fileUploaded = (v) => {
        setAttachment(v.data.url)
    }

    const showHandling = () => {
        setShowHandlingModal(true)
        setModalTitle("Add Handling")
    }

    const setDescription = (v) => {
        form.setFieldsValue({
            description: v
        })
    }

    const saveReplyHandling = (v) => {
        let payload = {
            description: v.description,
            status: hasSolved,
            attachment: attachment
        }

        api("POST", `ticket/handling/${ticket.id}`, payload).then((res) => {
            message.success("Successfully saved data")
            fetchTicket()
            setShowHandlingModal(false)
            window.location.reload(false);
        }).catch((err) => {
            message.error("Failed to saving data")
        })
    }


    return (
        <Card title="Handling">
            {
                ticket?.handlings?.length == 0 ? <Empty /> : null
            }

            {loaded ?
                <>
                    <List itemLayout="vertical" size="medium">
                        {ticket?.handlings?.map((el, key) => (
                            <Descriptions style={{ marginBottom: 20 }} layout="vertical" bordered>
                                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label={
                                    el.user?.image !== null ?
                                        <><Avatar src={el.user?.image} size={50} /> {el.user.name} </>
                                        : <><Avatar size={50}>{(el.user?.name.substring(0, 2)).toUpperCase()}</Avatar> {el.user.name} </>
                                }>
                                    <h4>Created at : {moment(el.created_at).format('YYYY-MM-DD HH:mm')} </h4><br />
                                    <span dangerouslySetInnerHTML={{ __html: el.description }} />
                                    <br /><br />
                                    {el.attachment ?

                                        el.extFile == 'pdf' ?
                                            <Image
                                                width={120}
                                                src={`${process.env.REACT_APP_PUBLIC_URL}/pdf.png`}
                                                onClick={() => window.open(`${el.attachment}`)}
                                            />
                                            :
                                            <Image
                                                width={120}
                                                src={el.attachment}
                                            />
                                        : null}
                                </Descriptions.Item>
                            </Descriptions>
                        ))}
                    </List>
                    {roleAccess('TICKET_HANDLING_ADD') && ticket.engineers?.length ?
                        ticket.status === "OPEN" ?
                            <div style={{ float: "right" }}>
                                <Space>
                                    <Button className='button-handling' type='primary' onClick={showHandling}>Add Handling</Button>
                                    <Button type='primary' onClick={() => setShowProcedure(true)}>LSR Procedures</Button>
                                </Space>
                            </div>
                            : null
                        : null
                    }
                </>
                : <Spinner />}

            {
                (showHandlingModal) ?
                    <FormModal form={form} title={modalTitle} submitForm={(v) => saveReplyHandling(v)} width={800} showModal={showHandlingModal} onCloseModal={() => setShowHandlingModal(false)}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Row justify="space-between" >
                            <Col span={11}>
                                <Form.Item label="Canned Reply" name="canned_reply" rules={[{ required: true }]}>
                                    <Select placeholder="Select Canned Reply" onChange={(v) => setDescription(v)}>
                                        {
                                            cannedReply.map((el, key) => (
                                                <Option key={key} value={el.description}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                            <Input.TextArea placeholder="Description" rows={5} />
                        </Form.Item>
                        <Form.Item label="Mark when this ticket solved">
                            <Checkbox onChange={() => setHasSolved("SOLVED")}>Mark as Solved</Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Uploader multiple={false} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                        </Form.Item>
                    </FormModal> :
                    null
            }

            {
                showProcedure ?
                    <Drawer
                        title="LSR Procedures"
                        placement="right"
                        width={500}
                        onClose={() => setShowProcedure(false)}
                        open={showProcedure}
                    >
                        <Collapse defaultActiveKey={['0']}>
                            {procedures.map((item, i) => (
                                <Panel header={item.title} key={i}>
                                    <iframe src={`${item.pdf}#toolbar=0`} frameborder="0" height="500px" width="100%"></iframe>
                                    <p dangerouslySetInnerHTML={{ __html: item.description }} />
                                </Panel>
                            ))}
                        </Collapse>

                    </Drawer>
                    : null
            }


        </Card>
    )
}

export default Handling