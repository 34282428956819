import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Input, message, Row, Select, Switch } from 'antd'
import { PageTitle, Uploader } from '../../../components'
import { api } from '../../../services/api'
import { useHistory, useParams } from 'react-router-dom'

const { Option } = Select

const ChangePasswordUser = () => {
    const { userId } = useParams()
    const [form] = Form.useForm()
    const history = useHistory()
    const [saving, setSaving] = useState(false)

    const onFinish = (v) => {

        if (v.password !== v.confirm_password) {
            message.warning('Password and confirm password did not match')
        } else {

            let payload = {
                password: v.password,
            }

            api("POST", `user/change-password/${userId}`, payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                history.goBack()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
            })
        }
    }

    return (
        <Card className="content-container">
            <PageTitle title="User - Change Password" breadcrumbs={[['Administrator'], ['User', '/users'], ['Change Password']]} />
            <Card title="User">
                <Form layout="vertical" onFinish={onFinish} form={form}>
                    <Row justify="space-between">
                        <Col span={11}>
                            <Form.Item label="New Password" name="password" rules={[
                                { required: true, message: 'Please input your password' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        let error;
                                        let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
                                        if (getFieldValue('password').length < 8) {
                                            error = 'Password minimum 8 character';
                                        } else if (regex.exec(getFieldValue('password')) == null) {
                                            error = 'Must have atleast 1 uppercase, 1 lowercase letter, 1 special character and 1 number';
                                        } else {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error(error))
                                    },
                                }),
                            ]} >
                                <Input.Password placeholder='New Password' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between">
                        <Col span={11}>
                            <Form.Item label="Confirmation Password" name="confirm_password" rules={[
                                { required: true, message: 'Please input your password' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        let error;
                                        let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
                                        if (getFieldValue('password').length < 8) {
                                            error = 'Password minimum 8 character';
                                        } else if (regex.exec(getFieldValue('password')) == null) {
                                            error = 'Must have atleast 1 uppercase, 1 lowercase letter, 1 special character and 1 number';
                                        } else {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error(error))
                                    },
                                }),
                            ]} >
                                <Input.Password placeholder='Confirmation Password' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button style={{ width: '20%' }} type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Card>
    )
}

export default ChangePasswordUser