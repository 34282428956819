import React, { useEffect, useState } from 'react'
import { Card, Space, Tag, Typography, Form, Select, Input, Switch, message } from 'antd'
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { AddButton, DeleteButton, EditButton, ShowButton } from '../../../components/Button'
import { FormModal } from '../../../components/Modal'
import { roleAccess } from '../../../helpers/menus'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const { Text } = Typography

const Scope = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [scope, setScope] = useState([])
    const [defaultValues, setDefaultValues] = useState({});
    const [modalTitle, setModalTitle] = useState(null);
    const [status, setStatus] = useState(false);
    const [coordinators, setCoordinators] = useState([])
    const [listCoordinators, setListCoordinators] = useState([])

    const fetchScope = () => {
        api("GET", "scopes").then((res) => {
            setScope(res)
            setLoaded(true)
        })
    }

    const fetchCoordinator = () => {
        api("GET", "users").then((res) => {
            let newUser = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                newUser.push(obj)
            });
            setCoordinators(newUser)
        })
    }

    useEffect(() => {
        fetchScope()
        fetchCoordinator()

    }, []);

    const handleChange = (v) => {
        const coordinator = []
        v.forEach((el) => {
            let obj = { id: el }
            coordinator.push(obj)
        })

        setListCoordinators(coordinator)
    }


    const addScope = () => {
        setModalTitle('Add New Scope')
        setDefaultValues({
            id: 0,
            name: '',
            status: false,
        })
        setFormModal(true)
        setStatus(false)
    }

    const editScope = (v) => {
        setModalTitle('Edit Scope')

        const coordinator = []
        v.coordinators.forEach((el) => {
            let obj = { value: el.user_id, label: el?.user?.name }
            coordinator.push(obj)
        })

        setDefaultValues({
            id: v.id,
            name: v.name,
            status: v.status,
            coordinators: coordinator
        })

        setFormModal(true)
        setStatus(v.status === "ACTIVE" ? true : false)
    }

    const deleteScope = (v) => {
        api("DELETE", `scope/${v}`).then((res) => {
            message.success("Successfully deleted data")
            fetchScope()
        })
    }

    const saveScope = (v) => {
        let payload = {
            name: v.name,
            status: status ? "ACTIVE" : "NOT ACTIVE",
            coordinators: listCoordinators
        }

        if (v.id === 0) {
            api("POST", "scope", payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchScope()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        } else {
            api("PUT", `scope/${v.id}`, payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchScope()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        }
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Coordinators',
            key: 'coordinators_name',
            render: (row) => (
                row?.coordinators.map((el, key) => (
                    <Tag color='orange' key={key}>{el.user?.name}</Tag>
                ))
            ),
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "ACTIVE" ? "green" : "red"}>{(text === "ACTIVE") ? text : text}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Created At',
            key: 'created_at',
            render: (row) => (
                moment(row.created_at).format('YYYY-MM-DD HH:mm')
            ),
            width: 150
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        {roleAccess('MASTER_SCOPE_EDIT') ?
                            <>
                                {/* <ShowButton onShow={() => history.push(`/master/scope/working-instruction/${row.id}`)} /> */}
                                <EditButton onEdit={() => editScope(row)} />
                            </>
                            : null}
                        {roleAccess('MASTER_SCOPE_DELETE') ?
                            <DeleteButton onConfirm={() => deleteScope(row.id)} />
                            : null}
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Scopes" breadcrumbs={[['Master'], ['Scopes']]} />
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Scope</Text>
                        {roleAccess('MASTER_SCOPE_ADD') ?
                            <AddButton right onAdd={addScope} title="New Scope" />
                            : null}
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={scope} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveScope(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item label="Coordinator" name="coordinators" rules={[{ required: true }]}>
                                <Select
                                    mode="tags"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select Coordinator"
                                    onChange={handleChange}
                                    options={coordinators}
                                />
                            </Form.Item>
                            <Form.Item label="Status" name="status">
                                <Switch checkedChildren="Active" unCheckedChildren="Not Active" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status === "ACTIVE" ? true : false} />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default Scope