import React, { useState, useEffect } from 'react'
import { Card, Descriptions, Tag } from 'antd'
import { useParams } from 'react-router-dom'
import { DataTable, PageTitle } from '../../../components'
import { api } from '../../../services/api'

const StockAdjustmentDetail = () => {
    const { stockId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [stockDetail, setStockDetail] = useState([])
    const [itemList, setItemList] = useState([])

    const fetchStock = () => {
        api("GET", `stock-adjustment/${stockId}`).then((res) => {
            setStockDetail(res)
            setItemList(res.items)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchStock()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'SKU',
            key: 'sku',
            render: (row) => (
                row.item?.sku
            ),
            align: 'center'
        },
        {
            title: 'Item Name',
            key: 'item_name',
            render: (row) => (
                row.item?.name
            ),
            align: 'center'
        },
        {
            title: 'Quantity',
            dataIndex: 'new_quantity',
            key: 'new_quantity',
            align: 'center'
        },
        {
            title: 'UOM',
            key: 'uom',
            render: (row) => (
                row?.item?.uom?.name
            ),
            align: 'center'
        },
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Stock Adjustment - Detail" breadcrumbs={[['Inventory'], ['Stock Adjustment', '/inventory/stock-adjustments'], ['Detail']]} />

            <Card>
                {
                    loaded ?
                        <>
                            <div style={{ marginBottom: 20 }}>
                                <Descriptions title="Information" layout="vertical" bordered>
                                    <Descriptions.Item label="Reference">{stockDetail.reference}</Descriptions.Item>
                                    <Descriptions.Item label="Remark">{stockDetail.remark}</Descriptions.Item>
                                    <Descriptions.Item label="Status">
                                        <Tag color={stockDetail.status === 'ACTIVE' ? 'green' : 'blue'} >{stockDetail.status}</Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>

                            <Card title="Items">
                                <DataTable size='small' search={false} pagination={false} bordered dataSource={itemList} columns={columns} />
                            </Card>
                        </>
                        : null
                }
            </Card>
        </Card>
    )
}

export default StockAdjustmentDetail