import React, { useState, useEffect } from 'react'
import { Button, Card, Descriptions, Select, Tag, Image, Rate } from 'antd'
import moment from 'moment'

import { api, currentUser } from '../../../services/api'

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const DetailTicket = ({ ticket }) => {
    const forceUpdate = useForceUpdate()

    return (
        <Card title="Information">
            <Descriptions style={{ marginBottom: 20 }} layout="vertical" bordered>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Reference">{ticket.reference} {ticket.number ? <><br /><b style={{ color: 'red' }}>#{ticket.number}</b></> : ''}</Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Created At">
                    {
                        ticket.created_at ? moment(ticket.created_at).format('DD MMMM YYYY, HH:mm:ss') : '-'
                    }
                </Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Created By">
                    <b>{ticket.created?.name}</b>
                </Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Scope & Status">
                    {/* {
                        <Tag color={ticket.status === "OPEN" ? "red" : ticket.status === "CLOSED" ? "green" : "blue"}>
                            {ticket.status}
                        </Tag>
                    } */}

                    {ticket.scopes?.map((item, key) => (
                        <div style={{ marginBottom: '20px' }}>
                            <Tag color={"blue"}>
                                {item.scope?.name}
                            </Tag>
                            <Tag color={item.status === "OPEN" ? "red" : item.status === "CLOSED" ? "green" : "blue"}>
                                {item.status}
                            </Tag>
                            <span>
                                <br/>
                                <b>Closed At</b> : { item.closed_at ? moment(item.closed_at).format('DD MMMM YYYY, HH:mm:ss') : '-' }
                            </span>
                            <br/>
                        </div>
                    ))}

                </Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Requested At">
                    {
                        ticket.requested_at ? moment(ticket.requested_at).format('DD MMMM YYYY, HH:mm:ss') : '-'
                    }
                </Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Requested By">
                    <b>{ticket.requested?.name} ({ticket.requested?.email})</b>
                </Descriptions.Item>

                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Priority">
                    {
                        ticket.priority ? ticket.priority?.name : '-'
                    }
                </Descriptions.Item>

                {/* <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Scopes">
                    {
                        ticket.scopes?.map((el, key) => (
                            <Tag color='blue' key={key}>
                                {el.scope?.name}
                            </Tag>
                        ))
                    }
                </Descriptions.Item> */}
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Work Schedule">
                    {
                        ticket.work_schedule ? moment(ticket.work_schedule).format('DD MMMM YYYY, HH:mm:ss') : '-'
                    }
                </Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Baseline End Date">
                    {
                        ticket.target_completion ? moment(ticket.target_completion).format('DD MMMM YYYY, HH:mm:ss') : '-'
                    }
                </Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Location">
                    {
                        ticket.locations?.map((el, key) => (
                            <Tag color='blue' key={key}>
                                {el.location?.name}
                            </Tag>
                        ))
                    }
                </Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Closed At">
                    {
                        ticket.closed_at ? moment(ticket.closed_at).format('DD MMMM YYYY, HH:mm:ss') : '-'
                    }
                </Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Closed By">
                    {
                        ticket.closed ? <b>{ticket.closed?.name}</b> : '-'
                    }
                </Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Remark">
                    {
                        ticket.remark ? ticket.remark : '-'
                    }
                </Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Technician">
                    {
                        ticket.engineers?.map((el, key) => (
                            <Tag color='blue' key={key}>
                                {el.user?.name}
                            </Tag>
                        ))
                    }
                </Descriptions.Item>
            </Descriptions>

            {ticket.attachments?.length ?
                <Descriptions style={{ marginBottom: 20 }} layout="vertical" bordered>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Attachment">
                        {
                            ticket.attachments?.map((el, key) => (
                                el.extFile == 'pdf' ?
                                    <Image
                                        width={120}
                                        src={`${process.env.REACT_APP_PUBLIC_URL}/pdf.png`}
                                        onClick={() => window.open(`${el.attachment}`)}
                                    />
                                    :
                                    <Image
                                        width={120}
                                        src={el.attachment}
                                    />
                            ))
                        }
                    </Descriptions.Item>
                </Descriptions>
                : null}

            <Descriptions style={{ marginBottom: 20 }} layout="vertical" bordered>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Description">{ticket.description}</Descriptions.Item>
            </Descriptions>

            {
                ticket.status === 'CLOSED' && ticket.feedback ?
                    <Descriptions style={{ marginBottom: 20 }} layout="vertical" bordered>
                        <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Star">
                            <Rate value={ticket.feedback.star} disabled />
                        </Descriptions.Item>
                        <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Feedback">{ticket.feedback.description}</Descriptions.Item>
                    </Descriptions>
                    : null
            }
        </Card>
    )
}

export default DetailTicket