import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, Col, DatePicker, Form, Input, InputNumber, message, Row, Space } from 'antd'
import { BasicModal, DataTable, PageTitle } from '../../../components'
import { roleAccess } from '../../../helpers/menus'
import { api } from '../../../services/api'
import { useHistory, useParams } from 'react-router-dom'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

const EditStockAdjusment = () => {
    const [form] = Form.useForm()
    const { stockId } = useParams()
    const history = useHistory()
    const [items, setItems] = useState([])
    const [itemSelectedUpdate, setItemSelectedUpdate] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [status, setStatus] = useState('')

    const fetchStockAdjusment = () => {
        api("GET", `stock-adjustment/${stockId}`).then((res) => {
            let items = []
            res.items.map((e, i) => {
                items.push({
                    ...e,
                    image: e.item.image,
                    sku: e.item.sku,
                    name: e.item.name,
                    uom: e.item?.uom?.name,
                    quantity: parseInt(e.old_quantity)
                })
            })
            setItemSelectedUpdate(items)
            form.setFieldsValue({
                remark: res.remark,
            })

            setLoaded(true)
        })
    }

    const fetchItem = () => {
        api("GET", "inventory-stocks").then((res) => {
            let items = []
            res.map((e, i) => {
                items.push({
                    ...e,
                    item_id: e.id,
                    old_quantity: e.quantity
                })
            })
            setItems(items)
        })
    }

    useEffect(() => {
        fetchStockAdjusment()
        fetchItem()

    }, []);

    const showItems = () => {
        setFormModal(true)
        setModalTitle('Items')
    }

    const selectItem = (v) => {
        const newItm = []
        const itm = itemSelectedUpdate
        newItm.push(v)

        const allItem = itm.concat(newItm)
        setItemSelectedUpdate(allItem)
    }

    const changeQuantity = (q, row) => {
        let items = []
        itemSelectedUpdate.map((item, i) => {
            if (item.item_id !== row.item_id) {
                items.push(item)
            } else {
                items.push({
                    ...item,
                    new_quantity: parseFloat(item.quantity) + parseFloat(q)
                })
            }
        })

        setItemSelectedUpdate(items)
    }

    const removeItem = (v) => {
        const itm = itemSelectedUpdate
        setItemSelectedUpdate(
            itm.filter(a =>
                a.item_id !== v
            )
        );
    }

    const saveStockAdjustment = (v) => {
        if(itemSelectedUpdate?.length > 0) {

            let payload = {
                remark: v.remark,
                items: itemSelectedUpdate,
                status: status
            }

            api("PUT", `stock-adjustment/${stockId}`, payload).then((res) => {
                message.success('Successfully saved data')
                history.push('/inventory/stock-adjustments')
            }).catch((err) => {
                message.warning('Failed to saving data')
            })
        } else {
            message.warning('Please select items')
        }
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Image',
            key: 'sku',
            render: (row) => (
                <Avatar
                    style={{
                        verticalAlign: 'middle',
                    }}
                    size="large"
                    src={row.image}
                />
            ),
            align: 'center',
            width: 80
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                itemSelectedUpdate.find(e =>
                    e.item_id === row.id) === undefined ?
                    <Button shape='circle' type='primary' onClick={() => selectItem(row)} icon={<PlusCircleOutlined />} />
                    :
                    <Button shape='circle' type='primary' danger onClick={() => removeItem(row.item_id !== undefined ? row.item_id : row.id)} icon={<MinusCircleOutlined />} />
            ),
            align: 'center',
            width: 80
        }
    ];

    const columnsSelectedItem = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Image',
            key: 'sku',
            render: (row) => (
                <Avatar
                    style={{
                        verticalAlign: 'middle',
                    }}
                    size="large"
                    src={row.image}
                />
            ),
            align: 'center',
            width: 80
        },
        {
            title: 'SKU',
            key: 'sku',
            dataIndex: 'sku',
            align: 'center'
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 300
        },
        {
            title: 'Old Quantity',
            key: 'old_quantity',
            render: (row) => (
                `${parseFloat(row.old_quantity) !== NaN ? parseFloat(row.old_quantity) : parseInt(row.old_quantity)} ${row.uom}`
            ),
            width: 120,
            align: 'center'
        },
        {
            title: 'Quantity',
            key: 'quantity',
            render: (row) => (
                <InputNumber value={parseFloat(row.new_quantity)-parseFloat(row.old_quantity)} onChange={(v) => changeQuantity(v, row)} style={{ width: '90%' }} />
            ),
            align: 'center',
            width: 120
        },
        {
            title: 'New Quantity',
            key: 'new_quantity',
            render: (row) => (
                `${row.new_quantity ? parseFloat(row.new_quantity) : 0} ${row.uom}`
            ),
            align: 'center',
        },
        {
            title: "Action",
            key: "action",
            render: (row) => (
                <Button type='primary' danger shape='circle' icon={<MinusCircleOutlined />} onClick={() => removeItem(row.item_id !== undefined ? row.item_id : row.id)} />
            ),
            align: 'center',
            width: 70
        }
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Stock Adjustment - Edit" breadcrumbs={[['Inventory'], ['Stock Adjustment', '/inventory/stock-adjustments'], ['Edit']]} />
            {
                loaded ?
                    <Form form={form} layout='vertical' onFinish={(v) => saveStockAdjustment(v)}>
                        <Card>
                            <Row justify='start'>
                                <Col span={11}>
                                    <Form.Item label="Remark" name="remark" rules={[{ required: true }]}>
                                        <Input.TextArea placeholder='Remark' rows={5} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='end' style={{ marginBottom: 20 }}>
                                <Col>
                                    <Button className='button-handling' type='primary' onClick={showItems}>Add Item</Button>
                                </Col>
                            </Row>

                            <Card title='Items' style={{ marginBottom: 20 }}>
                                {
                                    itemSelectedUpdate.length !== 0 ?
                                        < DataTable search={false} pagination={false} size='small' bordered dataSource={itemSelectedUpdate} columns={columnsSelectedItem} />
                                        : null
                                }
                            </Card>

                            <Form.Item>
                                <Button style={{ width: '20%' }} className='button-handling' type='primary' htmlType='submit' name="status" onClick={() => setStatus("ACTIVE")}>
                                    Save
                                </Button>
                                { roleAccess('INV_ADJUSTMENT_APPROVE') ? 
                                    <Button style={{ width: '20%', margin: '10px' }} className='button-part-request' type='primary' htmlType='submit' name="status" onClick={() => setStatus("COMPLETED")}>
                                        Complete
                                    </Button>
                                : null }
                            </Form.Item>

                            {
                                <BasicModal title={modalTitle} width={1000} showModal={formModal} onCloseModal={() => setFormModal(false)}>
                                    <div style={{ marginBottom: 20 }}>
                                        <DataTable size='small' bordered dataSource={items} columns={columns} />
                                    </div>
                                </BasicModal>
                            }
                        </Card>
                    </Form>
                    : null
            }
        </Card>
    )
}

export default EditStockAdjusment