import React, { useEffect, useState } from 'react'
import { Card, Typography, Button, Row, Col, Form, Rate, Input, message } from 'antd'
import { PageTitle } from '../../components/PageTitle'
import { useHistory, useLocation } from 'react-router-dom';
import { api } from '../../services/api'
import CompanyLogo from '../../assets/logo/spie_transparent.png'

const { Text } = Typography

const ReportTicket = () => {
    const search = useLocation().search;
    const reference = new URLSearchParams(search).get("reference");
    const history = useHistory()
    const [star, setStar] = useState(0)

    useEffect(() => {
    }, []);

    const onSubmit = (v) => {
        let payload = {
            reference: reference,
            description: v.description,
            star: star
        }

        api("POST", `ticket/feedback`, payload)
            .then((res) => {
                message.success('Success add Feedback')
                setTimeout(() => {
                    history.push('/login')
                }, 2000);
            })
    }

    return (
        <div>
            <Card className="content-container">
                <div className='text-center mb-4'>
                    <img style={{ width: 150 }} src={CompanyLogo} alt="company_logo" />
                </div>
                <Card title={
                    <div className="full-width">
                        <Row justify="space-between">
                            <Col>
                                <Text strong style={{ float: 'left' }}>Feedback</Text>
                            </Col>
                        </Row>
                    </div>}>
                    <div style={{ marginBottom: 10 }}>
                        <Text>Ticket Number</Text><br />
                        <Text><b>{reference}</b></Text>
                    </div>
                    <Form onFinish={onSubmit} layout='vertical'>
                        <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                            <Input.TextArea rows={5} />
                        </Form.Item>
                        <Form.Item label="Star" name="star" rules={[{ required: true }]}>
                            <Rate onChange={(v) => setStar(v)} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType='submit'>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Card>
        </div>
    )
}

export default ReportTicket