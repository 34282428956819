import React, { useEffect, useState } from 'react'
import { Card, Space, Tag, Typography, Form, Input, Table, Button, Select, Switch, message } from 'antd'

import { api } from '../../../services/api'
import { PageTitle } from '../../../components/PageTitle'
import { Spinner } from '../../../components/Spinner'

const { Text } = Typography

const WeeklyReport = () => {

    // const [form] = Form.useForm();

    const [loaded, setLoaded] = useState(true)

    const [generated, setGenerated] = useState(false)
    const [periodes, setPeriodes] = useState([]);
    
    const [periodeValue, setPeriodeValue] = useState({})

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);

    const periodeRange = () => {
        let start_year = 2022;
        let current_year = new Date().getFullYear();
        let periodes = [];
        for(let periode = start_year; periode <= current_year; periode++ ) {
            let value = {
                value : periode,
                label : periode,
            }
            periodes.push(value)
        }
        setPeriodes(periodes);
    }

    useEffect(() => {
        periodeRange();
    }, []);


    const generateReport = (v) => {

        setLoaded(false)
        setGenerated(false);

        api("GET", `report/weekly-report?year=${v.year}`).then((res) => {

            let column = [], data = []
            res.map((item, i) => {

                let weeks = [
                    item.weeks.week1,
                    item.weeks.week2,
                    item.weeks.week3,
                    item.weeks.week4,
                    item.weeks.week5,
                ]

                let weeksChild = []
                weeks.map((week, i) => {
                    let weekNo = i+1

                    weeksChild.push({
                        title: `Week ${weekNo}`,
                        children: [
                            {
                                title: week.start,
                                key: `week${weekNo}_start`,
                                children: [
                                    {
                                        title: <div><div style={{ transform: 'rotate(-90deg)', position: 'absolute', width: 100, bottom: 35, left: -10 }}><p>Request </p></div><br/><br/><br/></div>,
                                        dataIndex: `${item.month_number}_week${weekNo}_request`,
                                        key: `${item.month_number}_week${weekNo}_request`,
                                        width: 30,
                                    },
                                    {
                                        title: <div><div style={{ transform: 'rotate(-90deg)', position: 'absolute', width: 100, bottom: 35, left: -10 }}><p>Plan </p></div><br/><br/><br/></div>,
                                        dataIndex: `${item.month_number}_week${weekNo}_plan`,
                                        key: `${item.month_number}_week${weekNo}_plan`,
                                        width: 30,
                                    },
                                ]
                            },
                            {
                                title: week?.end,
                                key: `week${weekNo}_end`,
                                children: [
                                    {
                                        title: <div><div style={{ transform: 'rotate(-90deg)', position: 'absolute', width: 100, bottom: 35, left: -10 }}><p>Closed </p></div><br/><br/><br/></div>,
                                        dataIndex: `${item.month_number}_week${weekNo}_closed`,
                                        key: `${item.month_number}_week${weekNo}_closed`,
                                        width: 30,
                                    },
                                    {
                                        title: <div><div style={{ transform: 'rotate(-90deg)', position: 'absolute', width: 100, bottom: 35, left: -10 }}><p>Outstanding </p></div><br/><br/><br/></div>,
                                        dataIndex: `${item.month_number}_week${weekNo}_outstanding`,
                                        key: `${item.month_number}_week${weekNo}_outstanding`,
                                        width: 30,
                                    },
                                ]
                            },
                        ],
                    })
                })

                column.push({
                    title: `WEEKLY REPORT SPIE on ${item.month_name.toUpperCase()} ${v.year}`,
                    children: [
                        {
                            title: '',
                            dataIndex: 'status',
                            key: 'status',
                            width: 50
                        },
                        ...weeksChild
                    ],
                })

                data.push(
                    {
                        status: 'Weekly',
                        [`${item.month_number}_week1_request`]: item.weeks?.week1?.request,
                        [`${item.month_number}_week2_request`]: item.weeks?.week2?.request,
                        [`${item.month_number}_week3_request`]: item.weeks?.week3?.request,
                        [`${item.month_number}_week4_request`]: item.weeks?.week4?.request,
                        [`${item.month_number}_week5_request`]: item.weeks?.week5?.request,
                        [`${item.month_number}_week1_plan`]: item.weeks?.week1?.plan,
                        [`${item.month_number}_week2_plan`]: item.weeks?.week2?.plan,
                        [`${item.month_number}_week3_plan`]: item.weeks?.week3?.plan,
                        [`${item.month_number}_week4_plan`]: item.weeks?.week4?.plan,
                        [`${item.month_number}_week5_plan`]: item.weeks?.week5?.plan,
                        [`${item.month_number}_week1_closed`]: item.weeks?.week1?.close,
                        [`${item.month_number}_week2_closed`]: item.weeks?.week2?.close,
                        [`${item.month_number}_week3_closed`]: item.weeks?.week3?.close,
                        [`${item.month_number}_week4_closed`]: item.weeks?.week4?.close,
                        [`${item.month_number}_week5_closed`]: item.weeks?.week5?.close,
                        [`${item.month_number}_week1_outstanding`]: item.weeks?.week1?.outstanding,
                        [`${item.month_number}_week2_outstanding`]: item.weeks?.week2?.outstanding,
                        [`${item.month_number}_week3_outstanding`]: item.weeks?.week3?.outstanding,
                        [`${item.month_number}_week4_outstanding`]: item.weeks?.week4?.outstanding,
                        [`${item.month_number}_week5_outstanding`]: item.weeks?.week5?.outstanding,
                    },  
                );
            })

            setColumns(column)
            setData(data)

            setLoaded(true)
            setGenerated(true);
        })
    }
    

    let table = () => {
        return (
            <>
                <div>
                    { (generated) ?
                            columns.map((item, key) => {
                                return (
                                    <>
                                        <br/>
                                        <Table 
                                            key={key} 
                                            columns={[item]} 
                                            dataSource={[data[key]]} 
                                            bordered 
                                            size="middle"
                                            scroll={{
                                                x: 'calc(700px + 50%)',
                                                y: 240,
                                            }}
                                            pagination={{ position : [ 'none', 'none' ] }}/>
                                        <br/>
                                        <br/>
                                    </>
                                )
                            })
                        : null
                    }
                </div>
            </>
        )
    }
    
    return (
        <>
            <Card className="content-container">
                <PageTitle title="Weekly Report" breadcrumbs={[['Report'], ['Weekly Report']]} />
                <Card 
                    title={
                        <div className="full-width">
                            <Text strong style={{ float: 'left' }}>Weekly Report</Text>
                        </div>
                    }>
                        <div>
                            <Form defaultValues={periodeValue} initialValues={{ layout: "inline" }} onFinish={(v) => generateReport(v) }>
                                <Space>
                                    <Form.Item name="year" label="Select Period" rules={[{ required: true }]}>
                                        <Select style={{ width: 200 }} options={periodes}/>
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="full-width text-right">
                                            <Button className="button-primary" type="primary" htmlType="submit">Generate</Button>
                                        </div>
                                    </Form.Item>
                                </Space>
                            </Form>
                        </div>
                        <br />
                        <br />
                    { (loaded) ? table() : <Spinner /> }

                </Card>
            </Card>
        </>
    )
}

export default WeeklyReport;