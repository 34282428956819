import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Input, message, Row, Select, Switch } from 'antd'
import { PageTitle, Uploader } from '../../../components'
import { api } from '../../../services/api'
import { useHistory, useParams } from 'react-router-dom'

const { Option } = Select

const UpdateUser = () => {
    const { userId } = useParams()
    const [form] = Form.useForm()
    const history = useHistory()
    const [department, setDepartment] = useState([])
    const [position, setPosition] = useState([])
    const [role, setRole] = useState([])
    const [status, setStatus] = useState(false)
    const [saving, setSaving] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [image, setImage] = useState('')

    const fetchUser = () => {
        api("GET", `user/${userId}`).then((res) => {
            form.setFieldsValue({
                id: res.id,
                department_id: res.department_id,
                position_id: res.position_id,
                role_id: res.role_id,
                user_id: res.user_id,
                name: res.name,
                username: res.username,
                email: res.email,
                phone: res.phone
            })
            setStatus(res.status === "ACTIVE" ? true : false)
            setLoaded(true)
        })
    }

    const fetchDepartment = () => {
        api("GET", "departments").then((res) => {
            setDepartment(res)
        })
    }

    const fetchPosition = () => {
        api("GET", "positions").then((res) => {
            setPosition(res)
        })
    }

    const fetchRole = () => {
        api("GET", "roles").then((res) => {
            setRole(res)
        })
    }

    useEffect(() => {
        fetchDepartment()
        fetchPosition()
        fetchRole()
        fetchUser()

    }, []);

    const fileUploaded = (v) => {
        setImage(v.data.url)
    }

    const onFinish = (v) => {
        let payload = {
            department_id: v.department_id,
            position_id: v.position_id,
            role_id: v.role_id,
            user_id: v.user_id,
            name: v.name,
            username: v.username,
            phone: v.phone,
            email: v.email,
            status: status ? "ACTIVE" : "NOT ACTIVE"
        }

        if(image) payload.image = image

        api("PUT", `user/${v.id}`, payload).then((res) => {
            setSaving(false)
            message.success('Successfully saved data')
            history.goBack()
        }).catch((err) => {
            setSaving(false)
            message.warning('Failed to saving data')
        })
    }

    return (
        <Card className="content-container">
            <PageTitle title="User - Edit" breadcrumbs={[['Administrator'], ['User', '/users'], ['Edit']]} />
            <Card title="User">
                {
                    loaded ?
                        <Form layout="vertical" onFinish={onFinish} form={form}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Department" name="department_id" rules={[{ required: true }]}>
                                        <Select placeholder="Select Department">
                                            {
                                                department.map((el, key) => (
                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Position" name="position_id" rules={[{ required: true }]}>
                                        <Select placeholder="Select Position">
                                            {
                                                position.map((el, key) => (
                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="User ID" name="user_id" rules={[{ required: true }]} >
                                        <Input placeholder='User ID' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Role" name="role_id" rules={[{ required: true }]}>
                                        <Select placeholder="Select Role">
                                            {
                                                role.map((el, key) => (
                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Name" name="name" rules={[{ required: true }]} >
                                        <Input placeholder='Name' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Username" name="username" rules={[{ required: true }]} >
                                        <Input placeholder='Username' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email' }]} >
                                        <Input placeholder='Email' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Phone" name="phone" >
                                        <Input placeholder='Phone' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Avatar">
                                        <Uploader multiple={false} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label="Status" name="status">
                                <Switch checkedChildren="Active" unCheckedChildren="Not Active" onChange={(v) => setStatus(v)} defaultChecked={status} />
                            </Form.Item>

                            <Form.Item>
                                <Button style={{ width: '20%' }} type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                        : null
                }
            </Card>
        </Card>
    )
}

export default UpdateUser