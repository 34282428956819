import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Card, Empty, Typography, Form, Row, Col, Space, Button, Input, Tag, Select, Popconfirm, message, Descriptions, Image} from 'antd'
import { MinusCircleOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { api } from '../../../services/api'
import { FormModal } from '../../../components/Modal'
import { Uploader, DataTable, Spinner } from '../../../components'
import { roleAccess } from '../../../helpers/menus'
const { Text } = Typography

const PartRequest = () => {
    const [form] = Form.useForm()

    const { ticketId } = useParams()
    const [ticket, setTicket] = useState([])
    
    const [item, setItem] = useState([])
    const [uploading, setUploading] = useState(false)
    const [showPartRequestModal, setShowPartRequestModal] = useState(false)
    const [partReqModalTitle, setPartReqTitle] = useState('')
    const [attachmentPart, setAttachmentPart] = useState('')
    const [loaded, setLoaded] = useState(false)


    const fetchItem = () => {
        api("GET", "inventory-stocks").then((res) => {
            let items = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id, current_stock: parseFloat(e.quantity)}
                items.push(obj)
            });
            setItem(items)
        })
    }

    const fetchTicket = () => {
        api("GET", `ticket/${ticketId}`).then((res) => {
            res?.parts?.forEach(e => {
                e.attachment_name = e.attachment.replace(process.env.REACT_APP_API_URL+'/attachments/', "")
                e.extFile = e.attachment_name.split('.').pop();
            });
            setTicket(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchItem()
        fetchTicket()
    }, [])

    const showPartRequest = () => {
        setShowPartRequestModal(true)
        setPartReqTitle("Add Part Request")
    }

    const fileUploadedPart = (v) => {
        setAttachmentPart(v.data.url)
    }

    const acceptItem = (row) => {
        api("GET", `part-request/accept/${row.id}`).then((res) => {
            message.success("Successfully updated data")
            fetchTicket()
        })
    }

    const downloadReceipt = (row) => {
        api("GET", `part-request/download-receipt/${row.id}`).then((res) => {
            window.open(`${process.env.REACT_APP_API_URL}/receipts/${row.reference}.pdf`)
        })
    }

    const savePartRequest = (v) => {
        if(v.items == undefined || v.items?.length == 0) {
            message.error('Item cannot be empty')
        } else {

            let status = true
            v.items?.map((el, key) => {
                if(parseFloat(el.quantity) > el.current_stock){
                    let stock = item.find(e => e.value === el.item_id);
                    message.error(`${stock.label} maximum quantity (${el.current_stock})`)
                    status = false
                }
            })

            if(status){
                let payload = {
                    items: v.items,
                    description: v.description,
                    attachment: attachmentPart
                }
    
                api("POST", `/ticket/part/${ticket.id}`, payload).then((res) => {
                    message.success("Successfully saved data")
    
                    fetchTicket()
                    setShowPartRequestModal(false)
                }).catch((err) => {
                    message.error("Failed to saving data")
                })
            }
        }
    }

    const currentStock = (v) => {
        let stock = item.find(el => el.value === v);

        let fields = form.getFieldsValue()
        fields?.items?.map((item, key) => {
            if(item.item_id == v) item.current_stock = stock.current_stock
        })
        form.setFieldsValue(fields)
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'SKU',
            key: 'sku',
            render: (row) => (
                row.item?.sku
            ),
            align: 'center'
        },
        {
            title: 'Item Name',
            key: 'item_name',
            render: (row) => (
                row.item?.name
            ),
            align: 'center'
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center'
        },
        {
            title: 'UOM',
            key: 'uom',
            render: (row) => (
                row?.item?.uom?.name
            ),
            align: 'center'
        },
    ];

    return (
        <Card title="Part Requests">
            {
                ticket?.parts?.length == 0 ? <Empty /> : null
            }

            { loaded ?
                <>
                    { ticket?.parts?.map((el, key) => (
                        <Card title={`Request #${el.reference ? el.reference : '-'}`} style={{ marginBottom: 20 }}>
                            <div style={{ marginBottom: 20 }}>
                                <Descriptions layout="vertical" bordered>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Ticket Number">
                                        <Button type='link'>
                                            {ticket?.reference}
                                        </Button>
                                    </Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Requested By">{el.user?.name}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Requested At">{moment(el.created_at).format('YYYY-MM-DD HH:mm')}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Status">
                                        <Tag color={el.status === 'COMPLETED' ? 'green' : 'red'} >{el.status}</Tag>
                                    </Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Approved By">{el.approved?.name}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Approved At">{el.approved_at ? moment(el.approved_at).format('YYYY-MM-DD HH:mm') : '-'}</Descriptions.Item>

                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Receipt">
                                        <Space>
                                            {!el.accepted_by && el.status == 'APPROVED' ?
                                                <Popconfirm title="Are you sure ?" okText="Yes" cancelText="No" onConfirm={() => acceptItem(el)}>
                                                    <Button className='button-handling' type='primary' >
                                                        Accept Item
                                                    </Button>
                                                </Popconfirm>
                                            : null }
                                            <Button  icon={<DownloadOutlined />} type='primary' onClick={() => downloadReceipt(el)}>Download</Button>
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Description">{el.description}</Descriptions.Item>
                                </Descriptions>
                            </div>

                            <Card title="Items">
                                <DataTable size='small' search={false} pagination={false} bordered dataSource={el.items} columns={columns} />
                            </Card>


                            { el.attachment ?
                                <div style={{ marginBottom: 20, marginTop: 20 }}>
                                    <Descriptions title="Attachment" layout="vertical" bordered>
                                        <Descriptions.Item title="Attachment" layout="vertical" bordered>
                                            {el.attachment ? 
                                                el.extFile == 'pdf' ?
                                                    <Image
                                                    width={120}
                                                    src={`${process.env.REACT_APP_PUBLIC_URL}/pdf.png`}
                                                    onClick={() => window.open(`${el.attachment}`)}
                                                    />
                                                :  
                                                    <Image
                                                        width={120}
                                                        src={el.attachment}
                                                    />
                                            : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </div>
                            : null }
                        </Card>
                    ))}

                    {roleAccess('TICKET_PART_REQUEST_ADD') ?
                        ticket.status === "OPEN" ?
                            <div style={{ float: "right" }}>
                                <Space>
                                    <Button className='button-handling' type='primary' onClick={showPartRequest}>Add Part Request</Button>
                                </Space>
                            </div>
                        : null
                    : null }
                </>
            : <Spinner/> }

            {
                (showPartRequestModal) ?
                    <FormModal form={form} title={partReqModalTitle} submitForm={(v) => savePartRequest(v)} width={800} showModal={showPartRequestModal} onCloseModal={() => setShowPartRequestModal(false)}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Card title="Items" style={{ marginBottom: 20 }}>
                            <Form.List name="items">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Row key={key} justify="space-between" style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Col span={10}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'item_id']}
                                                        rules={[{ required: true, message: 'Missing item name' }]}
                                                        
                                                    >
                                                        <Select
                                                            showSearch
                                                            placeholder="Select Item"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={item}
                                                            onChange={(v) => currentStock(v)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'current_stock']}
                                                    >
                                                        <Input readOnly placeholder="Current Stock" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'quantity']}
                                                        rules={[{ required: true, message: 'Missing quantity' }]}
                                                    >
                                                        <Input placeholder="Quantity" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={3}>
                                                    <Button type='primary' danger shape='circle' icon={<MinusCircleOutlined />} onClick={() => remove(name)} />
                                                </Col>
                                            </Row>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Item
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>
                        <Form.Item label="Attachment" rules={[{ required: true }]}>
                            <Uploader multiple={false} dragger onUploaded={(v, key) => fileUploadedPart(v)} isUploading={(v) => setUploading(v)} />
                        </Form.Item>
                        <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                            <Input.TextArea placeholder="Description" rows={5} />
                        </Form.Item>
                    </FormModal> :
                    null
            }
        </Card>
    )
}

export default PartRequest