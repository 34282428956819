import React from 'react';
import { Typography } from 'antd';
import { Breadcrumbs } from './Breadcrumb';
// import { BackButton } from './Button'
const { Title } = Typography;

export const PageTitle = (props) => {
    const { breadcrumbs = null, back = false } = props
    return (
        <div style={{ marginBottom: 40 }} className="full-width">
            <div style={{ float: 'left' }}>
                <Title level={3} style={{ marginBottom: 0 }}>{props.title}</Title>
                {
                    breadcrumbs ? <Breadcrumbs items={breadcrumbs} /> : null
                }
            </div>
            <div style={{ float: 'right' }}>
                {
                    back ? props.back : null
                }
            </div>
            <br />
        </div>
    );
}

