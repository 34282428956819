import React from 'react';
import { Statistic, Card, Row, Col } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import DashboardItem from './DashboardItem';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip
);

const DataTicketByType = ({ ticketType }) => {
    var label = []
    var dataTicket = []

    ticketType.map((el) => {
        label.push(el.type)
        dataTicket.push(el.total)
    })

    const data = {
        labels: label,
        datasets: [
            {
                data: dataTicket,
                backgroundColor: '#273C75',
                borderColor: '#273C75',
                borderWidth: 1
            },
        ]
    }

    return (
        <>
            <div style={{ marginBottom: 10 }}>
                <Row>
                    <Col span={24}>
                        <Bar
                            data={data}
                            width={100}
                            height={320}
                            options={{ maintainAspectRatio: false, indexAxis: 'y', plugins: { legend: false } }}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default DataTicketByType;
