import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Space, Tag, Typography } from 'antd'
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { FileExcelOutlined } from '@ant-design/icons'

const { Text } = Typography

const ReportGoodsReceipt = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false);
    const [goodsReceipts, setGoodsReceipts] = useState([]);

    const fetchGoodsReceipt = () => {
        api("GET", "report/goods-receipts").then((res) => {
            setGoodsReceipts(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchGoodsReceipt()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Reference',
            key: 'reference',
            dataIndex: 'reference',
            align: 'center',
            width: 150,
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark',
        },
        {
            title: 'Created by',
            key: 'created_by',
            dataIndex: 'created_by',
        },
        {
            title: 'Created at',
            key: 'created_at',
            render: (row) => (
                moment(row.created_at).format('YYYY-MM-DD HH:mm')
            ),
        },
        {
            title: 'Item',
            key: 'item',
            dataIndex: 'item_name',
            align: 'center'
        },
        {
            title: 'Qty',
            key: 'qty',
            render: (row) => (
                `${row.quantity} ${row.uom}`
            ),
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "COMPLETED" ? "green" : "red"}>{text}</Tag>
            ),
            align: 'center'
        },
    ];

    const exportReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/goods-receipt/xlsx`);
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Goods Receipts" breadcrumbs={[['Report'], ['Goods Receipts']]} />
                <Card title={
                    <div className="full-width">
                        <Row justify="space-between">
                            <Col>
                                <Text strong style={{ float: 'left' }}>Goods Receipts</Text>
                            </Col>
                            <Col>
                                <Button onClick={() => exportReport()} shape="round" size="middle" >
                                    <FileExcelOutlined /> Generate Excel
                                </Button>
                            </Col>
                        </Row>
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={goodsReceipts} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default ReportGoodsReceipt