import React from 'react';
import { Row, Card, Form, Input, Button, message, Typography } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { api } from '../../services/api';
import CompanyLogo from '../../assets/logo/spie_transparent.png'

const { Text } = Typography

const ForgotPassword = () => {
    const history = useHistory()

    const onFinish = (v) => {
        api('POST', 'auth/reset-password', v)
        .then((res) => {
            if(res) {
                message.success('Successfully reset password, please check your email')
                setTimeout(() => {
                    history.push('/login')
                }, 1000);
            }
        })
    }

    return (
        <div>
            <Row type="flex" align="middle" justify="center" className="px-3 bg-white mh-page" style={{ minHeight: '100vh', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <Card style={{ width: 500, borderRadius: 15, boxShadow: '1px 2px 18px 4px rgba(0,0,0,0.33)' }}>
                    <div style={{ maxWidth: '400px', zIndex: 2, minWidth: '300px', margin: '0 auto' }}>
                        <div className='text-center mb-4'>
                            <img style={{ width: 150 }} src={CompanyLogo} alt="company_logo" />
                        </div>
                        <div className='text-center mb-5'>
                            <h1>{process.env.REACT_APP_NAME}</h1>
                            <h3>Forgot Password</h3>
                        </div>
                        
                        <Form layout="vertical" onFinish={onFinish}>
                            <Form.Item name="email" rules={[{ required: true, message: 'Please input your email' }]} >
                                <Input prefix={
                                    <MailOutlined />
                                } placeholder="Email" style={{ borderRadius: 15 }} size="large" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" shape='round' size="large" htmlType="submit" block className="button-primary mt-3">
                                    Submit
                                </Button>
                            </Form.Item>
                            <Text>
                                Have an account ? <Link to="/login">Login</Link>
                            </Text>
                        </Form>
                    </div>
                </Card>
            </Row>
        </div>
    );
}

export default ForgotPassword;
