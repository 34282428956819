import {
    FileTextOutlined, DashboardOutlined, UserOutlined, CheckCircleOutlined, EnvironmentOutlined, UserSwitchOutlined
} from '@ant-design/icons';
import { FiCircle } from 'react-icons/fi'

// Dashboard Menu
export const DashboardMenu = {
    name: "Dashboard",
    to: "/",
    icon: <DashboardOutlined />
}

// Ticket Menu
export const TicketMenu = {
    name: "Tickets",
    to: "/tickets",
    icon: <FileTextOutlined />
}

// Knowledge Base Menu
export const KnowledgeBaseMenu = {
    name: "Knowledge Base",
    to: "/knowledge-base",
    icon: <CheckCircleOutlined />
}

// Tracking Menu
export const TrackingMenu = {
    name: "Tracking",
    to: "/trackings",
    icon: <EnvironmentOutlined />
}

// User Menu
export const UserMenu = {
    name: "Users",
    to: "/users",
    icon: <UserOutlined />
}

// Role Menu
export const RoleMenu = {
    name: "Roles",
    to: "/roles",
    icon: <UserSwitchOutlined />
}

// Inventory
export const InventoryCategoryItem = {
    name: "Categories",
    to: "/inventory/categories",
    icon: <FiCircle />
}

export const InventoryItem = {
    name: "Items",
    to: "/inventory/items",
    icon: <FiCircle />
}

export const InventoryStockAdjusment = {
    name: "Adjustments",
    to: "/inventory/stock-adjustments",
    icon: <FiCircle />
}

export const InventoryStockList = {
    name: "Stocks",
    to: "/inventory/stocks",
    icon: <FiCircle />
}

export const InventoryGoodsReceipt = {
    name: "Goods Receipts",
    to: "/inventory/goods-receipts",
    icon: <FiCircle />
}

export const InventoryPartRequest = {
    name: "Part Requests",
    to: "/inventory/part-requests",
    icon: <FiCircle />
}

// Master Menu
export const MasterDivision = {
    name: "Departments",
    to: "/master/departments",
    icon: <FiCircle />
}

export const MasterPosition = {
    name: "Positions",
    to: "/master/positions",
    icon: <FiCircle />
}

export const MasterCustomers = {
    name: "Customers",
    to: "/master/customers",
    icon: <FiCircle />
}

export const MasterPriority = {
    name: "Priorities",
    to: "/master/priorities",
    icon: <FiCircle />
}

export const MasterType = {
    name: "Types",
    to: "/master/types",
    icon: <FiCircle />
}

export const MasterWorkingShift = {
    name: "Working Shifts",
    to: "/master/working-shifts",
    icon: <FiCircle />
}

export const MasterCannedRepplies = {
    name: "Canned Replies",
    to: "/master/canned-replies",
    icon: <FiCircle />
}

export const MasterScope = {
    name: "Scopes",
    to: "/master/scopes",
    icon: <FiCircle />
}

export const MasterLocation = {
    name: "Locations",
    to: "/master/locations",
    icon: <FiCircle />
}

export const MasterUom = {
    name: "Uom",
    to: "/master/uoms",
    icon: <FiCircle />
}

export const MasterLSRProcedure = {
    name: "LSR Procedures",
    to: "/master/lsr-procedures",
    icon: <FiCircle />
}

// KPI
export const KPIIndicator = {
    name: "Indicators",
    to: "/kpi/indicators",
    icon: <FiCircle />
}

export const KPICategory = {
    name: "Categories",
    to: "/kpi/categories",
    icon: <FiCircle />
}

export const KPIPerformance = {
    name: "Performance",
    to: "/kpi/performances",
    icon: <FiCircle />
}

// Reports
export const ReportKPIPerformance = {
    name: "KPI Performance",
    to: "/report/kpi",
    icon: <FiCircle />
}

export const ReportWeeklyReport = {
    name: "Weekly Report",
    to: "/report/weekly-report",
    icon: <FiCircle />
}

export const ReportTicket = {
    name: "Tickets",
    to: "/report/tickets",
    icon: <FiCircle />
}

export const ReportStock = {
    name: "Stock",
    to: "/report/stocks",
    icon: <FiCircle />
}

export const ReportPartRequest = {
    name: "Part Requests",
    to: "/report/part-requests",
    icon: <FiCircle />
}

export const ReportStockAdjustment = {
    name: "Stock Adjustments",
    to: "/report/stock-adjustments",
    icon: <FiCircle />
}

export const ReportGoodsReceipt = {
    name: "Goods Receipts",
    to: "/report/goods-receipts",
    icon: <FiCircle />
}

export const ReportTicketFeedback = {
    name: "Ticket Feedback",
    to: "/report/ticket-feedback",
    icon: <FiCircle />
}