import React from 'react';
import {Tag} from 'antd';

export const TagStatus = (props) => {
    const {type=null} = props
    return (
        <div>
            {
                (type==="WAITING") ? <Tag style={{color:'#808080',width:120}}>Waiting Approval</Tag> : null
            }
            {
                (type==="APPROVED") ? <Tag color="green" style={{width:120}}>APPROVED</Tag> : null
            }
            {
                (type==="REJECTED") ? <Tag color="red" style={{width:120}}>REJECTED</Tag> : null
            }
        </div>
    );
}
