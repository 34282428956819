import React, { useEffect } from 'react';
import { Row, Card, Form, Input, Button, message, Typography } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '../../services/api';
import CompanyLogo from '../../assets/logo/spie_transparent.png'

const { Text } = Typography

const ResetPassword = () => {
    const history = useHistory()
    const query = new URLSearchParams(window.location.search);

    const checkLink = () => {
        let payload = {
            email: query.get('email'),
            token: query.get('token')
        }
        api("POST", `auth/check-link`, payload)
            .then((res) => {
                if (!res) {
                    message.error('Link reset password invalid or expired')
                    setTimeout(() => {
                        history.push('/login')
                    }, 1000);
                }
            })
    }

    useEffect(() => {
        checkLink()
    }, []);


    const onFinish = (v) => {
        let payload = {
            email: query.get('email'),
            token: query.get('token'),
            password: v.password
        }

        if (v.password == v.confirm_password) {
            api('POST', 'auth/change-password', payload)
                .then((res) => {
                    message.success('Successfully change password')
                    setTimeout(() => {
                        history.push('/login')
                    }, 1000);
                })
        } else {
            message.warning('Password did not match')
        }
    }

    return (
        <div>
            <Row type="flex" align="middle" justify="center" className="px-3 bg-white mh-page" style={{ minHeight: '100vh', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <Card style={{ width: 500, borderRadius: 15, boxShadow: '1px 2px 18px 4px rgba(0,0,0,0.33)' }}>
                    <div style={{ maxWidth: '400px', zIndex: 2, minWidth: '300px', margin: '0 auto' }}>
                        <div className='text-center mb-4'>
                            <img style={{ width: 150 }} src={CompanyLogo} alt="company_logo" />
                        </div>
                        <div className='text-center mb-5'>
                            <h1>{process.env.REACT_APP_NAME}</h1>
                            <h3>Reset Password</h3>
                        </div>

                        <Form layout="vertical" onFinish={onFinish}>
                            <Form.Item name="password" rules={[
                                { required: true, message: 'Please input your password' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        let error;
                                        let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
                                        if (getFieldValue('password').length < 8) {
                                            error = 'Password minimum 8 character';
                                        } else if (regex.exec(getFieldValue('password')) == null) {
                                            error = 'Must have atleast 1 uppercase, 1 lowercase letter, 1 special character and 1 number';
                                        } else {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error(error))
                                    },
                                }),
                            ]} >
                                <Input.Password prefix={
                                    <LockOutlined />
                                } type="password" placeholder="Password" style={{ borderRadius: 15 }} size="large" />
                            </Form.Item>
                            <Form.Item name="confirm_password" rules={[
                                { required: true, message: 'Please input your password' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        let error;
                                        let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
                                        if (getFieldValue('password').length < 8) {
                                            error = 'Password minimum 8 character';
                                        } else if (regex.exec(getFieldValue('password')) == null) {
                                            error = 'Must have atleast 1 uppercase, 1 lowercase letter, 1 special character and 1 number';
                                        } else {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error(error))
                                    },
                                }),
                            ]} >
                                <Input.Password prefix={
                                    <LockOutlined />
                                } type="password" placeholder="Confirm Password" style={{ borderRadius: 15 }} size="large" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" shape='round' size="large" htmlType="submit" block className="button-primary mt-3">
                                    Submit
                                </Button>
                            </Form.Item>
                            <Text>
                                Have an account ? <Link to="/login">Login</Link>
                            </Text>

                        </Form>
                    </div>
                </Card>
            </Row>
        </div>
    );
}

export default ResetPassword;
