import React, { useEffect, useState } from 'react'
import { Card, Space, Tag, Typography, message, Select, Avatar } from 'antd'
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { AddButton, DeleteButton, EditButton, ShowButton } from '../../../components/Button'
import { roleAccess } from '../../../helpers/menus'
import { useHistory } from 'react-router-dom'

const { Text } = Typography
const { Option } = Select

const User = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false)
    const [user, setUser] = useState([]);

    const fetchUser = () => {
        api("GET", "users").then((res) => {
            setUser(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchUser()

    }, []);

    const deleteUser = (v) => {
        api("DELETE", `user/${v}`).then((res) => {
            message.success("Successfully deleted user")
            fetchUser()
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Avatar',
            key: 'avatar',
            render: (row) => (
                <Avatar
                    style={{
                        verticalAlign: 'middle',
                    }}
                    size="large"
                    src={row.image}/>
            ),
        },
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
            align: 'center'
        },
        {
            title: 'Department',
            render: (row) => (
                row?.department?.name
            ),
            align: 'center'
        },
        {
            title: 'Position',
            render: (row) => (
                row?.position?.name
            ),
            align: 'center'
        },
        {
            title: 'Role',
            render: (row) => (
                row?.role?.name
            ),
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "ACTIVE" ? "green" : "red"}>{(text === "ACTIVE") ? text : text}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        { roleAccess('ADMINISTRATOR_USER_EDIT') ? 
                            <>
                                <ShowButton onShow={() => history.push(`/user/change-password/${row.id}`)} />
                                <EditButton onEdit={() => history.push(`/user/edit/${row.id}`)} />
                            </>
                        : null }
                        { roleAccess('ADMINISTRATOR_USER_DELETE') ? 
                            <DeleteButton onConfirm={() => deleteUser(row.id)} />
                        : null }
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];
    
    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Users" breadcrumbs={[['Administrator'], ['Users']]} />
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Users</Text>
                        { roleAccess('ADMINISTRATOR_USER_ADD') ? 
                            <AddButton right onAdd={() => history.push('/user/create')} title="New User" />
                        : null }
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={user} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default User