import { decrypt } from './helpers'

export const permissions = [
    {
        key: "DASHBOARD",
        title: `Dashboard`,
    },
    {
        key: "TICKET",
        title: `Ticket - List`,
    },
    {
        key: "TICKET_ADD",
        title: `Ticket - Add`,
    },
    {
        key: "TICKET_EDIT",
        title: `Ticket - Edit`,
    },
    {
        key: "TICKET_DELETE",
        title: `Ticket - Delete`,
    },
    {
        key: "TICKET_VIEW",
        title: `Ticket - View`,
    },
    {
        key: "TICKET_CLOSE",
        title: `Ticket - Close`,
    },
    {
        key: "TICKET_ASSIGN",
        title: `Ticket - Assign`,
    },
    {
        key: "TICKET_REOPEN",
        title: `Ticket - Reopen`,
    },
    {
        key: "TICKET_FREEZE",
        title: `Ticket - Freeze`,
    },
    {
        key: "TICKET_HANDLING",
        title: `Ticket - Handling`,
    },
    {
        key: "TICKET_HANDLING_ADD",
        title: `Ticket - Handling - Add`,
    },
    {
        key: "TICKET_PART_REQUEST",
        title: `Ticket - Part Request`,
    },
    {
        key: "TICKET_PART_REQUEST_ADD",
        title: `Ticket - Part Request - Add`,
    },
    {
        key: "TICKET_PART_REQUEST_CONFIRM_RECEIPT",
        title: `Ticket - Part Request - Receipt`,
    },
    {
        key: "TICKET_HISTORY",
        title: `Ticket - History`,
    },
    {
        key: "KNOWLEDGE_BASE",
        title: `Knowledge Base`,
    },
    {
        key: "TRACKING",
        title: `Tracking`,
    },
    {
        key: "INV_ITEM",
        title: `Inv > Item - List`,
    },
    {
        key: "INV_ITEM_ADD",
        title: `Inv > Item - Add`,
    },
    {
        key: "INV_ITEM_EDIT",
        title: `Inv > Item - Edit`,
    },
    {
        key: "INV_ITEM_DELETE",
        title: `Inv > Item - Delete`,
    },
    {
        key: "INV_CATEGORY",
        title: `Inv > Category - List`,
    },
    {
        key: "INV_CATEGORY_ADD",
        title: `Inv > Category - Add`,
    },
    {
        key: "INV_CATEGORY_EDIT",
        title: `Inv > Category - Edit`,
    },
    {
        key: "INV_CATEGORY_DELETE",
        title: `Inv > Category - Delete`,
    },
    {
        key: "INV_STOCK",
        title: `Inv > Stock - List`,
    },
    {
        key: "INV_STOCK_VIEW",
        title: `Inv > Stock - View`,
    },
    {
        key: "INV_ADJUSTMENT",
        title: `Inv > Adjustment - List`,
    },
    {
        key: "INV_ADJUSTMENT_ADD",
        title: `Inv > Adjustment - Add`,
    },
    {
        key: "INV_ADJUSTMENT_EDIT",
        title: `Inv > Adjustment - Edit`,
    },
    {
        key: "INV_ADJUSTMENT_DELETE",
        title: `Inv > Adjustment - Delete`,
    },
    {
        key: "INV_ADJUSTMENT_VIEW",
        title: `Inv > Adjustment - View`,
    },
    {
        key: "INV_ADJUSTMENT_APPROVE",
        title: `Inv > Adjustment - Approval`,
    },
    {
        key: "INV_GOODS_RECEIPT",
        title: `Inv > GR - List`,
    },
    {
        key: "INV_GOODS_RECEIPT_ADD",
        title: `Inv > GR - Add`,
    },
    {
        key: "INV_GOODS_RECEIPT_EDIT",
        title: `Inv > GR - Edit`,
    },
    {
        key: "INV_GOODS_RECEIPT_DELETE",
        title: `Inv > GR - Delete`,
    },
    {
        key: "INV_GOODS_RECEIPT_VIEW",
        title: `Inv > GR - View`,
    },
    {
        key: "INV_GOODS_RECEIPT_APPROVE",
        title: `Inv > GR - Approval`,
    },
    {
        key: "INV_PART_REQUEST",
        title: `Inv > Part Request - List`,
    },
    {
        key: "INV_PART_REQUEST_VIEW",
        title: `Inv > Part Request - View`,
    },
    {
        key: "INV_PART_REQUEST_APPROVE",
        title: `Inv > Part Request - Approval`,
    },
    {
        key: "INV_PART_REQUEST_DELETE",
        title: `Inv > Part Request - Delete`,
    },
    {
        key: "MASTER_SCOPE",
        title: `Master > Scope - List`,
    },
    {
        key: "MASTER_SCOPE_ADD",
        title: `Master > Scope - Add`,
    },
    {
        key: "MASTER_SCOPE_EDIT",
        title: `Master > Scope - Edit`,
    },
    {
        key: "MASTER_SCOPE_DELETE",
        title: `Master > Scope - Delete`,
    },
    {
        key: "MASTER_DEPARTMENT",
        title: `Master > Department - List`,
    },
    {
        key: "MASTER_DEPARTMENT_ADD",
        title: `Master > Department - Add`,
    },
    {
        key: "MASTER_DEPARTMENT_EDIT",
        title: `Master > Department - Edit`,
    },
    {
        key: "MASTER_DEPARTMENT_DELETE",
        title: `Master > Department - Delete`,
    },
    {
        key: "MASTER_POSITION",
        title: `Master > Position - List`,
    },
    {
        key: "MASTER_POSITION_ADD",
        title: `Master > Position - Add`,
    },
    {
        key: "MASTER_POSITION_EDIT",
        title: `Master > Position - Edit`,
    },
    {
        key: "MASTER_POSITION_DELETE",
        title: `Master > Position - Delete`,
    },
    {
        key: "MASTER_CUSTOMER",
        title: `Master > Customer - List`,
    },
    {
        key: "MASTER_CUSTOMER_ADD",
        title: `Master > Customer - Add`,
    },
    {
        key: "MASTER_CUSTOMER_EDIT",
        title: `Master > Customer - Edit`,
    },
    {
        key: "MASTER_CUSTOMER_DELETE",
        title: `Master > Customer - Delete`,
    },
    {
        key: "MASTER_PRIORITY",
        title: `Master > Priority - List`,
    },
    {
        key: "MASTER_PRIORITY_ADD",
        title: `Master > Priority - Add`,
    },
    {
        key: "MASTER_PRIORITY_EDIT",
        title: `Master > Priority - Edit`,
    },
    {
        key: "MASTER_PRIORITY_DELETE",
        title: `Master > Priority - Delete`,
    },
    {
        key: "MASTER_TYPE",
        title: `Master > Type - List`,
    },
    {
        key: "MASTER_TYPE_ADD",
        title: `Master > Type - Add`,
    },
    {
        key: "MASTER_TYPE_EDIT",
        title: `Master > Type - Edit`,
    },
    {
        key: "MASTER_TYPE_DELETE",
        title: `Master > Type - Delete`,
    },
    {
        key: "MASTER_UOM",
        title: `Master > Uom - List`,
    },
    {
        key: "MASTER_UOM_ADD",
        title: `Master > Uom - Add`,
    },
    {
        key: "MASTER_UOM_EDIT",
        title: `Master > Uom - Edit`,
    },
    {
        key: "MASTER_UOM_DELETE",
        title: `Master > Uom - Delete`,
    },
    {
        key: "MASTER_WORKING_SHIFT",
        title: `Master > Working Shift - List`,
    },
    {
        key: "MASTER_WORKING_SHIFT_ADD",
        title: `Master > Working Shift - Add`,
    },
    {
        key: "MASTER_WORKING_SHIFT_EDIT",
        title: `Master > Working Shift - Edit`,
    },
    {
        key: "MASTER_WORKING_SHIFT_DELETE",
        title: `Master > Working Shift - Delete`,
    },
    {
        key: "MASTER_LOCATION",
        title: `Master > Location - List`,
    },
    {
        key: "MASTER_LOCATION_ADD",
        title: `Master > Location - Add`,
    },
    {
        key: "MASTER_LOCATION_EDIT",
        title: `Master > Location - Edit`,
    },
    {
        key: "MASTER_LOCATION_DELETE",
        title: `Master > Location - Delete`,
    },
    {
        key: "MASTER_LSR_PROCEDURE",
        title: `Master > LSR Procedure - List`,
    },
    {
        key: "MASTER_LSR_PROCEDURE_ADD",
        title: `Master > LSR Procedure - Add`,
    },
    {
        key: "MASTER_LSR_PROCEDURE_EDIT",
        title: `Master > LSR Procedure - Edit`,
    },
    {
        key: "MASTER_LSR_PROCEDURE_DELETE",
        title: `Master > LSR Procedure - Delete`,
    },
    {
        key: "MASTER_CANNED_REPLY",
        title: `Master > Canned Reply - List`,
    },
    {
        key: "MASTER_CANNED_REPLY_ADD",
        title: `Master > Canned Reply - Add`,
    },
    {
        key: "MASTER_CANNED_REPLY_EDIT",
        title: `Master > Canned Reply - Edit`,
    },
    {
        key: "MASTER_CANNED_REPLY_DELETE",
        title: `Master > Canned Reply - Delete`,
    },
    {
        key: "ADMINISTRATOR_USER",
        title: `Administrator > User - List`,
    },
    {
        key: "ADMINISTRATOR_USER_ADD",
        title: `Administrator > User - Add`,
    },
    {
        key: "ADMINISTRATOR_USER_EDIT",
        title: `Administrator > User - Edit`,
    },
    {
        key: "ADMINISTRATOR_USER_DELETE",
        title: `Administrator > User - Delete`,
    },
    {
        key: "ADMINISTRATOR_USER_CHANGE_PASSWORD",
        title: `Administrator > User - Change Password`,
    },
    {
        key: "ADMINISTRATOR_ROLE",
        title: `Administrator > Role - List`,
    },
    {
        key: "ADMINISTRATOR_ROLE_ADD",
        title: `Administrator > Role - Add`,
    },
    {
        key: "ADMINISTRATOR_ROLE_EDIT",
        title: `Administrator > Role - Edit`,
    },
    {
        key: "ADMINISTRATOR_ROLE_DELETE",
        title: `Administrator > Role - Delete`,
    },
    {
        key: "KPI_CATEGORY",
        title: `KPI > Category - List`,
    },
    {
        key: "KPI_CATEGORY_ADD",
        title: `KPI > Category - Add`,
    },
    {
        key: "KPI_CATEGORY_EDIT",
        title: `KPI > Category - Edit`,
    },
    {
        key: "KPI_CATEGORY_DELETE",
        title: `KPI > Category - Delete`,
    },
    {
        key: "KPI_INDICATOR",
        title: `KPI > Indicator - List`,
    },
    {
        key: "KPI_INDICATOR_ADD",
        title: `KPI > Indicator - Add`,
    },
    {
        key: "KPI_INDICATOR_EDIT",
        title: `KPI > Indicator - Edit`,
    },
    {
        key: "KPI_INDICATOR_DELETE",
        title: `KPI > Indicator - Delete`,
    },
    {
        key: "KPI_PERFORMANCE",
        title: `KPI > Performance - List`,
    },
    {
        key: "REPORT_KPI_PERFORMANCE",
        title: `Report > KPI Coordinator`,
    },
    {
        key: "REPORT_WEEKLY_REPORT",
        title: `Report > Weekly Report`,
    },
    {
        key: "REPORT_TICKET",
        title: `Report > Ticket`,
    },
    {
        key: "REPORT_PART_REQUEST",
        title: `Report > Part Request`,
    },
    {
        key: "REPORT_STOCK",
        title: `Report > Stock`,
    },
    {
        key: "REPORT_STOCK_ADJUSTMENT",
        title: `Report > Stock Adjustment`,
    },
    {
        key: "REPORT_GOODS_RECEIPT",
        title: `Report > Goods Receipt`,
    },
    {
        key: "REPORT_TICKET_FEEDBACK",
        title: `Report > Ticket Feedback`,
    }
]

export function roleAccess(code) {
    let role_access = localStorage.getItem('roleAccess') ? JSON.parse(decrypt(localStorage.getItem('roleAccess'))) : []
    return role_access.filter(el => el.code == code)?.length ? true : false
}