import React from 'react';
import { Spin } from 'antd'
import '../assets/spinner.css'
export const Spinner = () => {
    const loadingStyle= {
        fontSize:40, color:'#d4d4d4',padding:0, position:'absolute', top:'50%', left:'50%', transform: 'translate(-50%, -50%)'
    }
    return (
        <div className="full-width text-center" style={{height:200}}>
            <Spin size="large" style={loadingStyle}/>
        </div>
    );
}
