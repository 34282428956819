import { useEffect, useState } from 'react';
import { Layout, Affix, Menu, Space } from 'antd';
import { Link } from 'react-router-dom'
import {
    DashboardMenu,
    UserMenu,
    RoleMenu,
    KnowledgeBaseMenu,
    MasterDivision,
    MasterCustomers,
    MasterPriority,
    MasterCannedRepplies,
    MasterPosition,
    MasterWorkingShift,
    MasterScope,
    MasterLocation,
    MasterType,
    MasterUom,
    MasterLSRProcedure,
    InventoryCategoryItem,
    InventoryStockAdjusment,
    InventoryStockList,
    InventoryGoodsReceipt,
    InventoryPartRequest,
    InventoryItem,
    TicketMenu,
    TrackingMenu,
    KPIIndicator,
    KPICategory,
    ReportKPIPerformance,
    ReportWeeklyReport,
    ReportPartRequest,
    ReportTicket,
    ReportStock,
    ReportStockAdjustment,
    ReportGoodsReceipt,
    ReportTicketFeedback
} from './ListMenu';
import { UserOutlined, DatabaseOutlined, TagsOutlined, BarsOutlined } from '@ant-design/icons';
import SPIELogo from '../assets/logo/spie-logo.png';
import { FiCircle } from 'react-icons/fi';
import { roleAccess } from '../helpers/menus'

const { Sider } = Layout;
const { SubMenu } = Menu

const SideBar = (props) => {
    const [collapsed, setCollapsed] = useState(true)

    const getMenu = (menu, addIcon = false, icon = null) => {
        return (
            (!collapsed) ?
                <Link style={{ color: 'white' }} to={menu.to} key={menu.to}>
                    <Space>
                        {
                            addIcon ?
                                { icon }
                                :
                                menu.icon
                        }
                        {menu.name}
                    </Space>
                </Link>
                :
                <Link style={{ color: 'white' }} to={menu.to} key={menu.to}>{menu.icon}</Link>
        )
    }

    const collapseMenu = (v) => {
        if (props.display === 'auto') {
            localStorage.setItem('menu-collapsed', JSON.stringify(v))
            setCollapsed(v)
        }
    }

    useEffect(() => {
        if (props.display === 'collapsed' || props.display === 'auto') {
            setCollapsed(true)
        } else {
            setCollapsed(false)
        }
    }, [props])

    return (
        <div>
            <Affix
                offsetTop={0}
            >
                <Sider
                    className="sider-shadow border-0 scroll-y sidebar-scroll"
                    onMouseEnter={() => collapseMenu(false)}
                    onMouseLeave={() => collapseMenu(true)}
                    collapsed={props.display === 'collapsed' || (props.display === 'auto' && collapsed)}
                    hidden={props.hiddenSide}
                    trigger={null}
                    collapsible
                    width="200px"
                    style={{ padding: '0px', height: '100vh', overflow: 'auto', paddingLeft: '0px', textAlign: 'center', backgroundColor: '#273c75' }}
                >
                    <Link to="/">
                        {
                            (collapsed) ?
                                <div style={{ padding: '5px', backgroundColor: '#273c75' }}>
                                    <img src={SPIELogo} width={50} alt="cms-logo" />
                                </div>
                                :
                                <div style={{ padding: '10px', backgroundColor: '#273c75' }}>
                                    <img src={SPIELogo} width={120} alt="cms-logo" />
                                </div>
                        }
                    </Link>

                    <Menu className='list-menu' mode="inline" style={{ minHeight: '85vh', paddingTop: '10px', backgroundColor: '#273c75', color: 'white' }}>
                        {roleAccess('DASHBOARD') ?
                            <Menu.Item className='single-menu' key="dashboard">
                                {getMenu(DashboardMenu)}
                            </Menu.Item>
                            : null}
                        {roleAccess('TICKET') ?
                            <Menu.Item className='single-menu' key="ticket">
                                {getMenu(TicketMenu)}
                            </Menu.Item>
                            : null}
                        {roleAccess('KNOWLEDGE_BASE') ?
                            <Menu.Item className='single-menu' key="knowledgebase">
                                {getMenu(KnowledgeBaseMenu)}
                            </Menu.Item>
                            : null}
                        {roleAccess('TRACKING') ?
                            <Menu.Item className='single-menu' key="tracking">
                                {getMenu(TrackingMenu)}
                            </Menu.Item>
                            : null}


                        {roleAccess('INV_ITEM') || roleAccess('INV_CATEGORY') || roleAccess('INV_STOCK') || roleAccess('INV_ADJUSTMENT') || roleAccess('INV_GOODS_RECEIPT') || roleAccess('INV_PART_REQUEST') ?
                            <SubMenu className='sidebar-submenu' title={"Inventory"} icon={< TagsOutlined />} key={'list_menu_inventory'}>
                                {roleAccess('INV_ITEM') || roleAccess('INV_CATEGORY') ?
                                    <SubMenu className='sidebar-submenu' style={{ backgroundColor: '#192A56', color: 'white' }} title={"Product"} icon={<FiCircle />} key={'list_menu_product_management'}>
                                        {roleAccess('INV_ITEM') ?
                                            <Menu.Item className='list-submenu' key="inventoryItem">
                                                {getMenu(InventoryItem)}
                                            </Menu.Item>
                                            : null}
                                        {roleAccess('INV_CATEGORY') ?
                                            <Menu.Item className='list-submenu' key="inventoryCategoryItem">
                                                {getMenu(InventoryCategoryItem)}
                                            </Menu.Item>
                                            : null}
                                    </SubMenu>
                                    : null}

                                {roleAccess('INV_STOCK') ?
                                    <Menu.Item className='list-submenu' key="inventoryStockList">
                                        {getMenu(InventoryStockList)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('INV_ADJUSTMENT') ?
                                    <Menu.Item className='list-submenu' key="inventoryStockAdjustment">
                                        {getMenu(InventoryStockAdjusment)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('INV_GOODS_RECEIPT') ?
                                    <Menu.Item className='list-submenu' key="inventoryGoodsReceipt">
                                        {getMenu(InventoryGoodsReceipt)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('INV_PART_REQUEST') ?
                                    <Menu.Item className='list-submenu' key="inventoryPartRequest">
                                        {getMenu(InventoryPartRequest)}
                                    </Menu.Item>
                                    : null}
                            </SubMenu>
                            : null}

                        {roleAccess('KPI_INDICATOR') || roleAccess('KPI_CATEGORY') || roleAccess('KPI_PERFORMANCE') ?
                            <SubMenu className='sidebar-submenu' title={"KPI"} icon={< TagsOutlined />} key={'list_menu_kpi'}>
                                {roleAccess('KPI_INDICATOR') ?
                                    <Menu.Item className='list-submenu' key="kpiIndicator">
                                        {getMenu(KPIIndicator)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('KPI_CATEGORY') ?
                                    <Menu.Item className='list-submenu' key="kpiCategory">
                                        {getMenu(KPICategory)}
                                    </Menu.Item>
                                    : null}
                            </SubMenu>
                            : null}


                        {roleAccess('MASTER_SCOPE') || roleAccess('MASTER_DEPARTMENT') || roleAccess('MASTER_POSITION') || roleAccess('MASTER_CUSTOMER') || roleAccess('MASTER_PRIORITY') ||
                            roleAccess('MASTER_TYPE') || roleAccess('MASTER_WORKING_SHIFT') || roleAccess('MASTER_LOCATION') || roleAccess('MASTER_SOURCE') || roleAccess('MASTER_CANNED_REPLY') ?
                            <SubMenu className='sidebar-submenu' title={"Master"} icon={<DatabaseOutlined />} key={'list_menu_master'}>
                                {roleAccess('MASTER_SCOPE') ?
                                    <Menu.Item className='list-submenu' key="masterScope">
                                        {getMenu(MasterScope)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('MASTER_DEPARTMENT') ?
                                    <Menu.Item className='list-submenu' key="masterDivision">
                                        {getMenu(MasterDivision)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('MASTER_POSITION') ?
                                    <Menu.Item className='list-submenu' key="masterPosition">
                                        {getMenu(MasterPosition)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('MASTER_CUSTOMER') ?
                                    <Menu.Item className='list-submenu' key="masterCustomer">
                                        {getMenu(MasterCustomers)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('MASTER_PRIORITY') ?
                                    <Menu.Item className='list-submenu' key="masterPriority">
                                        {getMenu(MasterPriority)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('MASTER_TYPE') ?
                                    <Menu.Item className='list-submenu' key="masterType">
                                        {getMenu(MasterType)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('MASTER_UOM') ?
                                    <Menu.Item className='list-submenu' key="masterUom">
                                        {getMenu(MasterUom)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('MASTER_WORKING_SHIFT') ?
                                    <Menu.Item className='list-submenu' key="masterWorkingShift">
                                        {getMenu(MasterWorkingShift)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('MASTER_LOCATION') ?
                                    <Menu.Item className='list-submenu' key="masterLocation">
                                        {getMenu(MasterLocation)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('MASTER_LSR_PROCEDURE') ?
                                    <Menu.Item className='list-submenu' key="masterProcedure">
                                        {getMenu(MasterLSRProcedure)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('MASTER_CANNED_REPLY') ?
                                    <Menu.Item className='list-submenu' key="masterCannedReply">
                                        {getMenu(MasterCannedRepplies)}
                                    </Menu.Item>
                                    : null}
                            </SubMenu>
                            : null}

                        {roleAccess('ADMINISTRATOR_USER') || roleAccess('ADMINISTRATOR_ROLE') ?
                            <SubMenu className='sidebar-submenu' title={"Administrator"} icon={< UserOutlined />} key={'list_menu_user'}>
                                {roleAccess('ADMINISTRATOR_USER') ?
                                    <Menu.Item className='list-submenu' key="user">
                                        {getMenu(UserMenu)}
                                    </Menu.Item>
                                    : null}
                                {roleAccess('ADMINISTRATOR_ROLE') ?
                                    <Menu.Item className='list-submenu' key="roles">
                                        {getMenu(RoleMenu)}
                                    </Menu.Item>
                                    : null}
                            </SubMenu>
                            : null}

                        {
                            roleAccess('REPORT_KPI_PERFORMANCE')
                                ||
                                roleAccess('REPORT_WEEKLY_REPORT')
                                ||
                                roleAccess('REPORT_TICKET')
                                ||
                                roleAccess('REPORT_PART_REQUEST')
                                ||
                                roleAccess('REPORT_STOCK')
                                ||
                                roleAccess('REPORT_STOCK_ADJUSTMENT')
                                ||
                                roleAccess('REPORT_GOODS_RECEIPT')
                                ?
                                <SubMenu className='sidebar-submenu' title={"Reports"} icon={<BarsOutlined />} key={'list_menu_report'}>

                                    {roleAccess('REPORT_TICKET') ?
                                        <Menu.Item className='list-submenu' key="reportTicket">
                                            {getMenu(ReportTicket)}
                                        </Menu.Item>
                                        : null}
                                    {roleAccess('REPORT_PART_REQUEST') ?
                                        <Menu.Item className='list-submenu' key="reportPartRequest">
                                            {getMenu(ReportPartRequest)}
                                        </Menu.Item>
                                        : null}
                                    {roleAccess('REPORT_STOCK') ?
                                        <Menu.Item className='list-submenu' key="reportStock">
                                            {getMenu(ReportStock)}
                                        </Menu.Item>
                                        : null}
                                    {roleAccess('REPORT_STOCK_ADJUSTMENT') ?
                                        <Menu.Item className='list-submenu' key="reportStockAdjustment">
                                            {getMenu(ReportStockAdjustment)}
                                        </Menu.Item>
                                        : null}
                                    {roleAccess('REPORT_GOODS_RECEIPT') ?
                                        <Menu.Item className='list-submenu' key="reportGoodsReceipt">
                                            {getMenu(ReportGoodsReceipt)}
                                        </Menu.Item>
                                        : null}
                                    {roleAccess('REPORT_KPI_PERFORMANCE') ?
                                        <Menu.Item className='list-submenu' key="reportKPIPerformance">
                                            {getMenu(ReportKPIPerformance)}
                                        </Menu.Item>
                                        : null}
                                    {roleAccess('REPORT_WEEKLY_REPORT') ?
                                        <Menu.Item className='list-submenu' key="reportWeeklyReport">
                                            {getMenu(ReportWeeklyReport)}
                                        </Menu.Item>
                                        : null}
                                    {roleAccess('REPORT_TICKET_FEEDBACK') ?
                                        <Menu.Item className='list-submenu' key="reportTicketFeedback">
                                            {getMenu(ReportTicketFeedback)}
                                        </Menu.Item>
                                        : null}
                                </SubMenu>
                                : null}



                    </Menu>
                </Sider>
            </Affix>
        </div >
    );
}

export default SideBar;