import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom'
import { roleAccess } from '../helpers/menus'
import Dashboard from '../views/dashboard'
import { Layout } from 'antd'
import Header from './Header'
import Footer from './Footer'
import Sider from './Sider'

import NotFound from '../views/NotFound'
import NotAllowed from '../views/NotAllowed'

// Ticket
import Ticket from '../views/ticket';
import CreateTicket from '../views/ticket/create';
import EditTicket from '../views/ticket/edit';
import TicketDetail from '../views/ticket/detail';

// Master
import CannedReply from '../views/master/canned_reply';
import Priority from '../views/master/priority';
import Department from '../views/master/department';
import Position from '../views/master/position';
import Customer from '../views/master/customer';
import Scope from '../views/master/scope';
// import ScopeWorkingInstruction from '../views/master/scope/working-instruction.js';
import Location from '../views/master/location';
import WorkingShift from '../views/master/working_shift';
import Type from '../views/master/type';
import Uom from '../views/master/uom';
import Procedure from '../views/master/lsr_procedure';

// Inventory
import Item from '../views/inventory/item';
import Category from '../views/inventory/category';
import GoodsReceipt from '../views/inventory/goods_receipt';
import GoodsReceiptDetail from '../views/inventory/goods_receipt/detail';
import CreateGoodsReceipt from '../views/inventory/goods_receipt/create';
import EditGoodsReceipt from '../views/inventory/goods_receipt/edit';
import StockAdjustment from '../views/inventory/stock_adjustment';
import StockAdjustmentDetail from '../views/inventory/stock_adjustment/detail';
import CreateStockAdjusment from '../views/inventory/stock_adjustment/create';
import EditStockAdjusment from '../views/inventory/stock_adjustment/edit';
import PartRequest from '../views/inventory/part_request';
import PartRequestDetail from '../views/inventory/part_request/detail';
import Stock from '../views/inventory/stock';
import StockDetail from '../views/inventory/stock/detail';

// User
import User from '../views/administrator/user';
import CreateUser from '../views/administrator/user/create';
import EditUser from '../views/administrator/user/edit';
import ChangePasswordUser from '../views/administrator/user/change-password';

// Role
import Role from '../views/administrator/role';

// Knowledge base
import KnowledgeBase from '../views/knowledge_base';

// KPI
import KPIIndicator from '../views/kpi/indicator';
import KPICategory from '../views/kpi/category';


// REPORT
import WeeklyReport from '../views/report/weekly-report';
import TicketReport from '../views/report/ticket';
import PartRequestReport from '../views/report/part-request';
import StockReport from '../views/report/stock/stock';
import StockAdjustmentReport from '../views/report/stock/adjustment';
import GoodsReceiptReport from '../views/report/stock/goods-receipt';
import KpiPerformance from '../views/report/kpi-performance';

// Ticket Feedback
import TicketFeedback from '../views/report/ticket_feedback'


// Tracking
import Tracking from '../views/tracking';

// Profile
import Profile from '../views/profile';
import { ReportKPIPerformance } from './ListMenu';

const { Content } = Layout

const AppContent = () => {
    const [sidebarDisplay, setSidebarDisplay] = useState('auto')

    useEffect(() => {
        let sideBarMode = sessionStorage.getItem('sidebar')
        if (sideBarMode) {
            setSidebarDisplay(sideBarMode)
        } else {
            setSidebarDisplay('auto')
        }
    }, []);

    return (
        <div>
            {/* <HeaderOld/>  */}
            <Layout className="site-layout site-content">
                <Sider display={sidebarDisplay} />
                <Content style={{ margin: '10px 16px' }}>
                    <Header display={sidebarDisplay} setSidebar={(v) => setSidebarDisplay(v)} />
                    <Switch>
                        <Route exact path="/" component={Dashboard} />

                        <Route exact path="/profile" component={Profile} />

                        {/* Ticket */}
                        {roleAccess('TICKET') ?
                            <Route exact path="/tickets" component={Ticket} />
                            : null}
                        {roleAccess('TICKET_ADD') ?
                            <Route exact path="/ticket/create" component={CreateTicket} />
                            : null}
                        {roleAccess('TICKET_EDIT') ?
                            <Route exact path="/ticket/edit/:ticketId" component={EditTicket} />
                            : null}
                        {roleAccess('TICKET_VIEW') ?
                            <Route exact path="/ticket/view/:ticketId" component={TicketDetail} />
                            : null}

                        {/* Master */}
                        {roleAccess('MASTER_CUSTOMER') ?
                            <Route exact path="/master/customers" component={Customer} />
                            : null}
                        {roleAccess('MASTER_DEPARTMENT') ?
                            <Route exact path="/master/departments" component={Department} />
                            : null}
                        {roleAccess('MASTER_POSITION') ?
                            <Route exact path="/master/positions" component={Position} />
                            : null}
                        {roleAccess('MASTER_CANNED_REPLY') ?
                            <Route exact path="/master/canned-replies" component={CannedReply} />
                            : null}
                        {roleAccess('MASTER_PRIORITY') ?
                            <Route exact path="/master/priorities" component={Priority} />
                            : null}
                        {roleAccess('MASTER_WORKING_SHIFT') ?
                            <Route exact path="/master/working-shifts" component={WorkingShift} />
                            : null}
                        {roleAccess('MASTER_SCOPE') ?
                            <Route exact path="/master/scopes" component={Scope} />
                            : null}
                        {roleAccess('MASTER_LOCATION') ?
                            <Route exact path="/master/locations" component={Location} />
                            : null}
                        {roleAccess('MASTER_TYPE') ?
                            <Route exact path="/master/types" component={Type} />
                            : null}
                        {roleAccess('MASTER_UOM') ?
                            <Route exact path="/master/uoms" component={Uom} />
                            : null}
                        {roleAccess('MASTER_LSR_PROCEDURE') ?
                            <Route exact path="/master/lsr-procedures" component={Procedure} />
                            : null}

                        {/* Inventory */}
                        {roleAccess('INV_ITEM') ?
                            <Route exact path="/inventory/items" component={Item} />
                            : null}
                        {roleAccess('INV_CATEGORY') ?
                            <Route exact path="/inventory/categories" component={Category} />
                            : null}
                        {roleAccess('INV_ADJUSTMENT') ?
                            <Route exact path="/inventory/stock-adjustments" component={StockAdjustment} />
                            : null}
                        {roleAccess('INV_ADJUSTMENT_VIEW') ?
                            <Route exact path="/inventory/stock-adjustment/view/:stockId" component={StockAdjustmentDetail} />
                            : null}
                        {roleAccess('INV_ADJUSTMENT_ADD') ?
                            <Route exact path="/inventory/stock-adjustment/create" component={CreateStockAdjusment} />
                            : null}
                        {roleAccess('INV_ADJUSTMENT_EDIT') ?
                            <Route exact path="/inventory/stock-adjustment/edit/:stockId" component={EditStockAdjusment} />
                            : null}
                        {roleAccess('INV_STOCK') ?
                            <Route exact path="/inventory/stocks" component={Stock} />
                            : null}
                        {roleAccess('INV_STOCK_VIEW') ?
                            <Route exact path="/inventory/stock/view/:itemId" component={StockDetail} />
                            : null}
                        {roleAccess('INV_GOODS_RECEIPT') ?
                            <Route exact path="/inventory/goods-receipts" component={GoodsReceipt} />
                            : null}
                        {roleAccess('INV_GOODS_RECEIPT_VIEW') ?
                            <Route exact path="/inventory/goods-receipt/view/:stockId" component={GoodsReceiptDetail} />
                            : null}
                        {roleAccess('INV_GOODS_RECEIPT_ADD') ?
                            <Route exact path="/inventory/goods-receipt/create" component={CreateGoodsReceipt} />
                            : null}
                        {roleAccess('INV_GOODS_RECEIPT_EDIT') ?
                            <Route exact path="/inventory/goods-receipt/edit/:stockId" component={EditGoodsReceipt} />
                            : null}
                        {roleAccess('INV_PART_REQUEST') ?
                            <Route exact path="/inventory/part-requests" component={PartRequest} />
                            : null}
                        {roleAccess('INV_PART_REQUEST_VIEW') ?
                            <Route exact path="/inventory/part-request/view/:stockId" component={PartRequestDetail} />
                            : null}

                        {/* User */}
                        {roleAccess('ADMINISTRATOR_USER') ?
                            <Route exact path="/users" component={User} />
                            : null}
                        {roleAccess('ADMINISTRATOR_USER_ADD') ?
                            <Route exact path="/user/create" component={CreateUser} />
                            : null}
                        {roleAccess('ADMINISTRATOR_USER_EDIT') ?
                            <Route exact path="/user/edit/:userId" component={EditUser} />
                            : null}
                        {roleAccess('ADMINISTRATOR_USER_EDIT') ?
                            <Route exact path="/user/change-password/:userId" component={ChangePasswordUser} />
                            : null}
                        {/* Role */}
                        {roleAccess('ADMINISTRATOR_ROLE') ?
                            <Route exact path="/roles" component={Role} />
                            : null}

                        {/* KPI */}
                        {roleAccess('KPI_INDICATOR') ?
                            <Route exact path="/kpi/indicators" component={KPIIndicator} />
                            : null}
                        {roleAccess('KPI_CATEGORY') ?
                            <Route exact path="/kpi/categories" component={KPICategory} />
                            : null}


                        {/* Knowledge Base */}
                        {roleAccess('KNOWLEDGE_BASE') ?
                            <Route exact path="/knowledge-base" component={KnowledgeBase} />
                            : null}

                        {/* Tracking */}
                        {roleAccess('TRACKING') ?
                            <Route exact path="/trackings" component={Tracking} />
                            : null}



                        {/* Report */}
                        {roleAccess('REPORT_WEEKLY_REPORT') ?
                            <Route exact path="/report/weekly-report" component={WeeklyReport} />
                            : null}
                        {roleAccess('REPORT_KPI_PERFORMANCE') ?
                            <Route exact path="/report/kpi" component={KpiPerformance} />
                            : null}
                        {roleAccess('REPORT_TICKET') ?
                            <Route exact path="/report/tickets" component={TicketReport} />
                            : null}
                        {roleAccess('REPORT_PART_REQUEST') ?
                            <Route exact path="/report/part-requests" component={PartRequestReport} />
                            : null}
                        {roleAccess('REPORT_STOCK') ?
                            <Route exact path="/report/stocks" component={StockReport} />
                            : null}
                        {roleAccess('REPORT_STOCK_ADJUSTMENT') ?
                            <Route exact path="/report/stock-adjustments" component={StockAdjustmentReport} />
                            : null}
                        {roleAccess('REPORT_GOODS_RECEIPT') ?
                            <Route exact path="/report/goods-receipts" component={GoodsReceiptReport} />
                            : null}
                        {roleAccess('REPORT_TICKET_FEEDBACK') ?
                            <Route exact path="/report/ticket-feedback" component={TicketFeedback} />
                            : null}

                        <Route exact path="/not-allowed" component={NotAllowed} />

                        <Route component={NotFound} />

                    </Switch>
                </Content>
            </Layout>
            <Footer />
        </div>
    );
}

export default AppContent;
