import React from 'react';
import { Row, Col } from 'antd';
import DashboardItem from './DashboardItem';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const DataKPI = ({ monthlyKPI }) => {

    var outstanding = []
    var achievement = []
    var assignment = []
    var score = []
    monthlyKPI?.map((item, i) => {
        outstanding.push(item.data.outstanding)
        achievement.push(item.data.closed)
        assignment.push(item.data.assignment)
        score.push(item.data.valueKpi)
    })

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Outstanding',
                data: outstanding,
                backgroundColor: 'rgb(220, 53, 69)',
                borderColor: 'rgb(220, 53, 69)',
                borderWidth: 1
            },
            {
                label: 'Achievement',
                data: achievement,
                backgroundColor: 'rgb(255, 193, 7)',
                borderColor: 'rgb(255, 193, 7)',
                borderWidth: 1
            },
            {
                label: 'Assignment',
                data: assignment,
                backgroundColor: 'rgb(23, 162, 184)',
                borderColor: 'rgb(23, 162, 184)',
                borderWidth: 1
            },
            {
                label: 'KPI Score',
                data: score,
                backgroundColor: 'rgb(39, 174, 96)',
                borderColor: 'rgb(39, 174, 96)',
                borderWidth: 1
            }
        ]
    }

    return (
        <>
            <div style={{ marginBottom: 10 }}>
                <Row>
                    <Col span={24}>
                        <DashboardItem title={
                            <Row justify="space-between">
                                Monthly KPI
                            </Row>
                        } height={400}>
                            <Bar
                                data={data}
                                width={100}
                                height={320}
                                options={{ maintainAspectRatio: false }}
                            />
                        </DashboardItem>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default DataKPI;
