import React, { useEffect, useState } from 'react';
import { Button, Badge, Row, Col, Typography, Dropdown, Divider, Space, List, Avatar } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { LogoutOutlined, SettingOutlined, MenuOutlined, MenuFoldOutlined, AlignLeftOutlined } from '@ant-design/icons';
import Icon from '../components/Icon';
import { decrypt } from '../helpers/helpers'
import { api, logout } from '../services/api';

const { Text } = Typography

const Header = (props) => {
    const history = useHistory()
    const [notifications, setNotifications] = useState([])
    const [notificationNew, setNotificationNew] = useState(0)
    const currentUser = localStorage.getItem('currentUser') ? JSON.parse(decrypt(localStorage.getItem('currentUser'))) : ''

    const fetchNotification = () => {
        api("GET", "user-notifications").then((res) => {
            setNotifications(res)
            setNotificationNew(res.filter(el => el.status == 'NEW')?.length)
        })
    }

    useEffect(() => {
        if (currentUser) fetchNotification()
    }, []);

    const saveNotification = (v) => {
        api("PUT", `user-notification/${v.id}`, { status: "READ" }).then((res) => {
            fetchNotification()
        })
        history.push(`/ticket/view/${v.trigger_id}`)
    }

    const changeSidebar = (v) => {
        props.setSidebar(v)
        sessionStorage.setItem('sidebar', v)
    }

    const notificationBox = (
        <div className="dropdown-notification-box">
            {
                (notifications.length > 0) ?
                    <div>
                        <List
                            itemLayout="horizontal"
                            style={{ paddingLeft: 10, paddingRight: 10 }}
                            dataSource={notifications}
                            renderItem={item => (
                                <List.Item onClick={() => saveNotification(item)}>
                                    <div style={{ width: '100%', cursor: 'pointer' }}>
                                        {
                                            item.status === "NEW" ?
                                                <List.Item.Meta
                                                    title={item.title}
                                                    description={
                                                        <b>{item.notification}</b>
                                                    }
                                                />
                                                :
                                                <List.Item.Meta
                                                    title={item.title}
                                                    description={item.notification}
                                                />
                                        }
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                    :
                    <div className="full-width text-center">
                        <Text type="secondary">You don't have any notification</Text>
                    </div>
            }
        </div>
    )

    const profileBox = (
        <div className="dropdown-profile-box text-center" style={{ marginRight: 10 }}>
            {
                (currentUser && currentUser.image) ?
                    <Avatar src={currentUser.image} style={{ width: 70, height: 70, border: '1px solid #ededed' }} />
                    :
                    <Avatar src={process.env.PUBLIC_URL + '/images/user-male.png'} style={{ width: 70, height: 70, border: '1px solid #ededed' }} />
            }
            <br />
            <Text strong>{(currentUser) && currentUser.name ? currentUser.name : '-'}</Text><br />
            <Text type="secondary">{(currentUser) && currentUser.department ? currentUser.department?.name : '-'} - {(currentUser) && currentUser.position ? currentUser.position?.name : '-'}</Text>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <div className="full-width">
                <Row justify="space-around">
                    <Link to="/profile">
                        <Button className='button-primary' shape='round' icon={<SettingOutlined />} type="primary">Profile</Button>
                    </Link>
                    <Button shape='round' icon={<LogoutOutlined />} type="danger" onClick={() => logout()}>Log out</Button>
                </Row>
            </div>
        </div>
    )

    return (
        <Row className="mb-3">
            <Col span={16}>
                {
                    (props.display === 'expanded') ?
                        <MenuOutlined style={{ color: '#242830', fontSize: '25px', marginRight: '10px' }} onClick={() => changeSidebar('collapsed')} />
                        : (props.display === 'collapsed') ?
                            <MenuFoldOutlined style={{ color: '#242830', fontSize: '25px', marginRight: '10px' }} onClick={() => changeSidebar('auto')} />
                            : <AlignLeftOutlined style={{ color: '#242830', fontSize: '25px', marginRight: '10px', }} onClick={() => changeSidebar('expanded')} />
                }
                {/* <span style={{ fontSize: '25px', fontWeight: 'bold', color: '#444444', display: 'inline-block' }}>Hi, {(user) ? user.employee.name : null}</span> */}
            </Col>
            <Col span={8}>
                <div style={{ float: 'right', paddingRight: '15px', cursor: 'pointer' }}>
                    <Space>
                        <Dropdown overlay={notificationBox}>
                            <Button className='button-primary' type="primary" icon={
                                <Badge count={notificationNew} size="large"> <Icon name="bell" /></Badge>
                            } shape="circle" size="large" />
                        </Dropdown>
                        <Dropdown overlay={profileBox}>
                            <Button className='button-primary' type="primary" icon={<Icon name="user" />} shape="circle" size="large" />
                        </Dropdown>
                    </Space>
                </div>
            </Col>
            {JSON.stringify(props.breadcrumbs) || ''}
        </Row >
    );
}

export default Header;
