import React from 'react';
import { Form, Input, Button, message, Typography, Row, Col } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import './auth.css'
import BgIllustration from '../../assets/images/spie-center.png'
import CompanyLogo from '../../assets/logo/spie_transparent.png'
import LoginText from '../../assets/images/text_1.png';
import LoginText2 from '../../assets/images/text_2.png';
import LoginText3 from '../../assets/images/text_3.png';
import Qr from '../../assets/images/qr.png';
import SPIE from '../../assets/images/spie.png'
import { api } from '../../services/api';

const { Text } = Typography

const Login = () => {
    const onFinish = (v) => {
        message.loading('authenticating', 0)
        let payload = {
            username: v.username,
            password: v.password
        }

        api('POST', 'auth/login', payload).catch((err) => {
            message.destroy()
            message.error('Invalid credentials', 2)
        })
    }

    return (
        <div className='main-page'>
            <div className='left'>
                <div className='left-content mb-3'>
                    <div className='content mb-3'>
                        <span>
                            <img style={{ width: 270 }} className='img-responsive' src={LoginText} alt="login-text" />
                        </span>
                        <span style={{ textAlign: 'end' }}>
                            <img style={{ width: 290 }} className='img-responsive' src={LoginText2} alt="login-text_2" />
                        </span>
                        {/* <h1>WELCOME TO SPIERIT - CAMP MANAGEMENT</h1> */}
                    </div>
                    <div className='img-ilusstration'>
                        <div className="img-absolute">
                            <img className='img-responsive' src={BgIllustration} alt="bg-illustrator" />
                        </div>
                    </div>
                    <div className='row-bottom' style={{ position: 'fixed', bottom: 10, zIndex: 999 }}>
                        <img style={{ maxWidth: 60, width: '100%' }} src={LoginText3} alt="login-text" />
                        <img style={{ maxWidth: 150, width: '100%', height: 50, marginTop: 30 }} className='img-responsive' src={Qr} alt="login-text_2" />
                        <img style={{ maxWidth: 90, width: '100%', height: 40, marginTop: 30, marginRight: 20 }} className='img-responsive' src={SPIE} alt="login-text_2" />
                    </div>
                </div>
            </div>
            <div className='right'>
                <div className='text-center mb-2'>
                    <h1 className='app-title'>WELCOME TO<br /> SPIERIT - CAMP MANAGEMENT</h1>
                </div>
                <div className='text-center mb-4'>
                    <img style={{ width: 150 }} src={CompanyLogo} alt="company_logo" />
                </div>
                <div className='text-center mb-5'>
                    <h1 style={{ fontSize: 20 }}>Login SPIERIT</h1>
                </div>
                <Form layout="vertical" onFinish={onFinish}>
                    <Form.Item name="username" rules={[{ required: true, message: 'Please input your username' }]}>
                        <Input prefix={
                            <UserOutlined />
                        } placeholder="Username" style={{ borderRadius: 15 }} size="large" />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: 'Please input your password' }]} >
                        <Input.Password prefix={
                            <LockOutlined />
                        } type="password" placeholder="Password" style={{ borderRadius: 15 }} size="large" />
                    </Form.Item>
                    <Form.Item style={{ margin: 0 }}>
                        <Link to={"/forgot-password"}>
                            <span style={{ float: 'right' }}>
                                Forgot password ?
                            </span>
                        </Link>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" shape='round' size="large" htmlType="submit" block className="button-primary mt-3">
                            Log in
                        </Button>
                    </Form.Item>
                </Form>

                <div className='text-center' style={{ bottom: 10, position: 'relative', margin: '0 40px 0 40px' }}>
                    <Text>
                        © Copyright PT SPIE Oil & Gas Services Indonesia. All Rights Reserved.
                    </Text>
                </div>
                <div className='text-bottom'>
                    SPIE, sharing a vision for the future
                </div>
            </div>
        </div>
    );
}

export default Login;
