import React, { useState } from 'react'
import { Upload, Button, message } from 'antd'
import { UploadOutlined, InboxOutlined } from '@ant-design/icons'
import { decrypt } from '../helpers/helpers'

const { Dragger } = Upload
const token = localStorage.getItem('accessToken') ? JSON.parse(decrypt(localStorage.getItem('accessToken'))) : []

export const Uploader = (props) => {
    const [files, setFiles] = useState([])

    const { title = 'Click to Upload', accept = '.jpg,.jpeg,.png', dragger = false, multiple = false } = props

    const removeFile = (v) => {
        // props.isRemove(true)

        if (v.status === 'error') {
            props.isRemove(false)
        } else {
            props.isRemove(false)
        }

        props.isRemove(false)
    }

    const handleChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        props.isUploading(true)
        // if (info.file.status !== 'uploading') {
        // }

        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            props.isUploading(false)
            props.onUploaded(info.file.response)
            // removeFile()
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setTimeout(() => {
                message.error(info.file.response.msg)
            }, 1000);
            props.isUploading(false)
        }
        setFiles(fileList)
    }

    const uploadHandler = {
        name: 'attachment',
        multiple: multiple ? true : false,
        action: `${process.env.REACT_APP_API_URL}/upload`,
        headers: {
            'authorization': `Bearer ${token}`,
            'client-id': `${process.env.REACT_APP_CLIENT_ID}`,
            'client-secret': `${process.env.REACT_APP_CLIENT_SECRET}`
        },
        accept: accept,
        onChange: handleChange,
        onRemove: removeFile
    };

    return (
        <div>
            {
                dragger ?
                    <Dragger {...uploadHandler}>
                        <p className="text-center">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area for upload</p>
                    </Dragger>
                    :
                    <Upload {...uploadHandler} fileList={files}>
                        <Button icon={<UploadOutlined />}>{title}</Button>
                    </Upload>
            }
        </div>
    );
}

export const ImportXlsx = (props) => {
    const [files, setFiles] = useState([])

    const { title = 'Click to Upload', accept = '.xlsx', dragger = false, multiple = false } = props

    const removeFile = (v) => {
        // props.isRemove(true)

        if (v.status === 'error') {
            props.isRemove(false)
        } else {
            props.isRemove(false)
        }

        props.isRemove(false)
    }

    const handleChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        props.isUploading(true)
        // if (info.file.status !== 'uploading') {
        // }

        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            props.isUploading(false)
            props.onUploaded(info.file.response)
            // removeFile()
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setTimeout(() => {
                message.error(info.file.response.msg)
            }, 1000);
            props.isUploading(false)
        }
        setFiles(fileList)
    }

    const uploadHandler = {
        name: 'files',
        multiple: multiple ? true : false,
        action: `${process.env.REACT_APP_API_URL}/upload-xlsx`,
        headers: {
            'client-id': `${process.env.REACT_APP_CLIENT_ID}`,
            'client-secret': `${process.env.REACT_APP_CLIENT_SECRET}`
        },
        accept: accept,
        onChange: handleChange,
    };

    return (
        <div>
            {
                dragger ?
                    <Dragger {...uploadHandler}>
                        <p className="text-center">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area for upload</p>
                    </Dragger>
                    :
                    <Upload {...uploadHandler} fileList={files} showUploadList={false}>
                        <Button icon={<UploadOutlined />}>{title}</Button>
                    </Upload>
            }
        </div>
    );
}
