import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, Col, DatePicker, Form, Input, InputNumber, message, Row, Space } from 'antd'
import { BasicModal, DataTable, PageTitle } from '../../../components'
import { roleAccess } from '../../../helpers/menus'
import { api } from '../../../services/api'
import { useHistory, useParams } from 'react-router-dom'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

const EditGoodsReceipt = () => {
    const history = useHistory()
    const [form] = Form.useForm()
    const { stockId } = useParams()
    const [items, setItems] = useState([])
    const [itemSelected, setItemSelected] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [status, setStatus] = useState('')

    const fetchGoodReceipt = () => {
        api("GET", `goods-receipt/${stockId}`).then((res) => {
            let itemList = []
            res.items.map((e, i) => {
                itemList.push({
                    ...e,
                    image: e.item.image,
                    sku: e.item.sku,
                    name: e.item.name,
                    uom: e.item?.uom?.name,
                })
            })
            setItemSelected(itemList)
            form.setFieldsValue({
                date: moment(res.date),
                remark: res.remark
            })

            setLoaded(true)
        })
    }

    const fetchItem = () => {
        api("GET", "inventory-stocks").then((res) => {
            let items = []
            res.map((item, i) => {
                items.push({
                    ...item,
                    old_quantity: item.quantity,
                })
            })
            setItems(items)
        })
    }

    useEffect(() => {
        fetchGoodReceipt()
        fetchItem()

    }, []);

    const showItems = () => {
        setFormModal(true)
        setModalTitle('Items')
    }

    const selectItem = (v) => {
        const newItm = []
        const itm = itemSelected
        newItm.push(v)

        const allItem = itm.concat(newItm)
        setItemSelected(allItem)
    }

    const changeQuantity = (q, row) => {
        let items = []
        itemSelected.map((item, i) => {
            if (item.id !== row.id) {
                items.push(item)
            } else {
                items.push({
                    ...item,
                    quantity: q
                })
            }
        })

        setItemSelected(items)
    }

    const removeItem = (v) => {
        const itm = itemSelected
        setItemSelected(
            itm.filter(a =>
                (a.item_id || a.id) !== v
            )
        );
    }

    const saveGoodsReceipt = (v) => {
        
        if(itemSelected?.length > 0){
            let payload = {
                date: moment(v.date).format('YYYY-MM-DD'),
                remark: v.remark,
                items: itemSelected,
                status: status
            }

            api("PUT", `goods-receipt/${stockId}`, payload).then((res) => {
                message.success('Successfully saved data')
                history.push('/inventory/goods-receipts')
            }).catch((err) => {
                message.warning('Failed to saving data')
            })
            
        } else {
            message.warning('Please select items')
        }
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Image',
            key: 'sku',
            render: (row) => (
                <Avatar
                    style={{
                        verticalAlign: 'middle',
                    }}
                    size="large"
                    src={row.image}
                />
            ),
            align: 'center',
            width: 80
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Quantity',
            key: 'quantity',
            render: (row) => (
                `${row.quantity} ${row.uom}`
            ),
            align: 'center'
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                itemSelected.find(e =>
                    e.item_id === row.id || e.id === row.id) === undefined ?
                    <Button shape='circle' type='primary' onClick={() => selectItem(row)} icon={<PlusCircleOutlined />} />
                    :
                    <Button shape='circle' type='primary' danger onClick={() => removeItem(row.item_id !== undefined ? row.item_id : row.id)} icon={<MinusCircleOutlined />} />
            ),
            align: 'center',
            width: 80
        }
    ];

    const columnsSelectedItem = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Image',
            key: 'sku',
            render: (row) => (
                <Avatar
                    style={{
                        verticalAlign: 'middle',
                    }}
                    size="large"
                    src={row.image}
                />
            ),
            align: 'center',
            width: 80
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Quantity',
            key: 'quantity',
            render: (row) => (
                <span>
                    <InputNumber value={row.quantity} onChange={(v) => changeQuantity(v, row)} style={{ width: '70%' }} /> {row.uom}
                </span>
            ),
            align: 'center',
            width: 350
        },
        {
            title: "Action",
            key: "action",
            render: (row) => (
                <Button type='primary' danger shape='circle' icon={<MinusCircleOutlined />} onClick={() => removeItem(row.item_id !== undefined ? row.item_id : row.id)} />
            ),
            align: 'center',
            width: 70
        }
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Goods Receipt - Edit" breadcrumbs={[['Inventory'], ['Goods Receipt', '/inventory/goods-receipts'], ['Edit']]} />
            {
                loaded ?
                    <Form form={form} layout='vertical' onFinish={(v) => saveGoodsReceipt(v)}>
                        <Card>
                            <Row justify='start'>
                                <Col span={11}>
                                    <Form.Item label="Date" name="date" rules={[{ required: true }]}>
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='start'>
                                <Col span={11}>
                                    <Form.Item label="Remark" name="remark" rules={[{ required: true }]}>
                                        <Input.TextArea placeholder='Remark' rows={5} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='end' style={{ marginBottom: 20 }}>
                                <Col>
                                    <Button className='button-handling' type='primary' onClick={showItems}>Add Item</Button>
                                </Col>
                            </Row>

                            <Card title='Items' style={{ marginBottom: 20 }}>
                                {
                                    itemSelected.length !== 0 ?
                                        <DataTable search={false} pagination={false} size='small' bordered dataSource={itemSelected} columns={columnsSelectedItem} />
                                        : null
                                }
                            </Card>

                            <Form.Item>
                                <Button style={{ width: '20%' }} className='button-handling' type='primary' htmlType='submit' name="status" onClick={() => setStatus("ACTIVE")}>
                                    Save
                                </Button>

                                { roleAccess('INV_GOODS_RECEIPT_APPROVE') ? 
                                    <Button style={{ width: '20%', margin: '10px' }} className='button-part-request' type='primary' htmlType='submit' name="status" onClick={() => setStatus("COMPLETED")}>
                                        Complete
                                    </Button>
                                : null }

                            </Form.Item>

                            {
                                <BasicModal title={modalTitle} width={1000} showModal={formModal} onCloseModal={() => setFormModal(false)}>
                                    <DataTable size='small' bordered dataSource={items} columns={columns} />
                                </BasicModal>
                            }
                        </Card>
                    </Form>
                    : null
            }
        </Card>
    )
}

export default EditGoodsReceipt