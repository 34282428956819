import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Input, Row, message, Tabs } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { api } from '../../services/api'
import { decrypt, encrypt } from '../../helpers/helpers'
import { PageTitle, Uploader } from '../../components'
import { useHistory } from 'react-router-dom'

const { TabPane } = Tabs
const { TextArea } = Input

const Profile = () => {
    const history = useHistory()
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [image, setImage] = useState('')

    const user = localStorage.getItem('currentUser') ? JSON.parse(decrypt(localStorage.getItem('currentUser'))) : {}

    const fetchUser = () => {
        api("GET", `user/${user.id}`).then((res) => {
            form.setFieldsValue({
                id: res.id,
                username: res.username,
                name: res.name,
                email: res.email,
                phone: res.phone,
                address: res.address
            })
            setLoaded(true)
        })
    }

    const fileUploaded = (v) => {
        console.log(v)
        setImage(v.data.url)
    }

    const onFinish = (v) => {
        let payload = {
            name: v.name,
            phone: v.phone,
            email: v.email,
            address: v.address,
        }
        if (image) payload.image = image

        api("PUT", `user/${v.id}`, payload).then((res) => {
            setSaving(false)
            message.success('Successfully saved data')
            fetchUser()
            let data = {
                ...user,
                name: v.name === '' ? user.name : v.name,
                phone: v.phone === '' ? user.phone : v.phone,
                email: v.email === '' ? user.email : v.email,
                address: v.address === '' ? user.address : v.address,
                image: image === '' ? user.image : image
            }

            localStorage.setItem('currentUser', encrypt(JSON.stringify(data)));
            window.location.reload(false);
        }).catch((err) => {
            setSaving(false)
            message.warning('Failed to saving data')
        })
    }

    const onFinishPassword = (v) => {
        if (v.password !== v.confirm_password) {
            message.warning('Password and confirm password did not match')
        } else {

            let payload = {
                password: v.password,
            }

            api("POST", `user/change-password/${user.id}`, payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                fetchUser()
                form.setFieldsValue({
                    password: '',
                    confirm_password: ''
                })
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
            })
        }
    }

    useEffect(() => {
        fetchUser()

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Profile" breadcrumbs={[['Profile']]} />
                <Tabs defaultActiveKey={'profile'} type='card'>
                    <TabPane tab='Profile' key='profile'>
                        {
                            loaded ?
                                <Form onFinish={onFinish} layout='vertical' form={form}>
                                    <Form.Item hidden name="id">
                                        <Input />
                                    </Form.Item>
                                    <Row justify="space-between">
                                        <Col span={11}>
                                            <Form.Item label="Username" name="username" rules={[{ required: true }]}>
                                                <Input placeholder="Username" disabled />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify="space-between">
                                        <Col span={11}>
                                            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                                <Input placeholder="Name" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify="space-between">
                                        <Col span={11}>
                                            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                                                <Input placeholder="Email" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify="space-between">
                                        <Col span={11}>
                                            <Form.Item label="Address" name="address">
                                                <TextArea rows={3} placeholder="Address" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify="space-between">
                                        <Col span={11}>
                                            <Form.Item label="Phone" name="phone">
                                                <Input placeholder="Phone" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify="space-between">
                                        <Col span={11}>
                                            <Form.Item label="Avatar">
                                                <Uploader multiple={false} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item className='mt-4'>
                                        <Button className="button-primary" style={{ width: 150 }} htmlType="submit">
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                                : null
                        }
                    </TabPane>
                    <TabPane tab='Password' key='password'>
                        <Form onFinish={onFinishPassword} form={form} layout='vertical'>

                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="New Password" name="password" rules={[
                                        { required: true, message: 'Please input your password' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                let error;
                                                let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
                                                if (getFieldValue('password').length < 8) {
                                                    error = 'Password minimum 8 character';
                                                } else if (regex.exec(getFieldValue('password')) == null) {
                                                    error = 'Must have atleast 1 uppercase, 1 lowercase letter, 1 special character and 1 number';
                                                } else {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error(error))
                                            },
                                        }),
                                    ]}>
                                        <Input.Password placeholder="New Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Confirm Password" name="confirm_password" rules={[
                                        { required: true, message: 'Please input your password' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                let error;
                                                let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
                                                if (getFieldValue('password').length < 8) {
                                                    error = 'Password minimum 8 character';
                                                } else if (regex.exec(getFieldValue('password')) == null) {
                                                    error = 'Must have atleast 1 uppercase, 1 lowercase letter, 1 special character and 1 number';
                                                } else {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error(error))
                                            },
                                        }),
                                    ]}>
                                        <Input.Password placeholder="Confirm New Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item className='mt-4'>
                                <Button className="button-primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                </Tabs>
            </Card>
        </div >
    )
}

export default Profile