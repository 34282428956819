import React, { useEffect, useState } from 'react'
import { Avatar, Card, Space, Typography } from 'antd'
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { ShowButton } from '../../../components'
import { roleAccess } from '../../../helpers/menus'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const { Text } = Typography

const StockList = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false);
    const [stockList, setStockList] = useState([]);

    const fetchStockList = () => {
        api("GET", "inventory-stocks").then((res) => {
            setStockList(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchStockList()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Image',
            key: 'sku',
            render: (row) => (
                <Avatar
                    style={{
                        verticalAlign: 'middle',
                    }}
                    size="large"
                    src={row.image}/>
            ),
            align: 'center',
            width: 80
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku'
        },
        {
            title: 'Item Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name'
        },
        {
            title: 'Stock',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center'
        },
        {
            title: 'UOM',
            dataIndex: 'uom',
            key: 'uom',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        { roleAccess('INV_STOCK_VIEW') ? 
                            <ShowButton onShow={() => history.push(`/inventory/stock/view/${row.id}`)} />
                        : null }
                    </Space>
                </div>
            ),
            align: 'center',
            fixed: 'right',
        },
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Stocks" breadcrumbs={[['Inventory'], ['Stocks']]} />
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Stocks</Text>
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={stockList} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default StockList