import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Image, Input, message, Row, Space, Tag, Typography, Descriptions } from 'antd'
import { PageTitle, Spinner } from '../../components'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import { api } from '../../services/api'
import EmptyFolderImage from '../../assets/icons/task.png'
import { Link } from 'react-router-dom'
import moment from 'moment'

const { Text } = Typography

const KnowledgeBase = () => {
    const [form] = Form.useForm()
    const [isSearch, setIsSearch] = useState(false)
    const [searchKnowledges, setSearchKnowledges] = useState([])
    const [knowledges, setKnowledges] = useState([])
    const [loaded, setLoaded] = useState(false)

    const searchKnowledge = (v) => {
        if (v.search === undefined) {
            message.warning("Your field is empty")
        } else {
            api("POST", "knowledge-base", v).then((res) => {
                setSearchKnowledges(res)
                setIsSearch(true)
            }).catch((err) => {
                message.warning("Knowledge not found", 2)
            })
        }
    }

    const fetchKnowledge = () => {
        api("GET", "knowledge-base").then((res) => {
            setKnowledges(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchKnowledge()
    }, []);

    const clearSearch = () => {
        setIsSearch(false)
        form.resetFields(['search']);
    }
    return (
        <Card className="content-container">
            <PageTitle title="Knowledge Base" breadcrumbs={[['Knowledge Base']]} />
            <Row justify="space-between">
                <Col span={24}>
                    <Card>
                        <div style={{ marginBottom: '15px' }}>
                            <Form form={form} onFinish={searchKnowledge}>
                                <Row justify='start'>
                                    <Col span={24}>
                                        <Form.Item name="search">
                                            <Input style={{ width: '100%', borderRadius: '20px' }} suffix=
                                                {
                                                    <Space>
                                                        <Button type='text' icon={<CloseOutlined />} size="small" onClick={clearSearch} />
                                                        <Button type="primary" htmlType='submit' shape="circle" icon={<SearchOutlined />} />
                                                    </Space>
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        {
                            isSearch ?
                                searchKnowledges.length !== 0 ?
                                    searchKnowledges.map((el, key) => (
                                        <div key={key} style={{ marginBottom: '10px' }}>
                                            <Card>
                                                <Descriptions style={{ marginBottom: 20 }} layout="vertical" bordered>
                                                    <Descriptions.Item contentStyle={{ width: '50%' }} labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Problem">
                                                        <Tag color={el.status.toLowerCase() === "open" ? "red" : el.status.toLowerCase() === "freezed" ? "blue" : "green"}>
                                                            {el.status}
                                                        </Tag>
                                                        {moment(el.created_at).format('YYYY-MM-DD HH:mm')}
                                                        <br /><br />
                                                        {el.problem}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item contentStyle={{ width: '50%' }} labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Handling">
                                                        {el.handling}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Card>
                                        </div>
                                    ))
                                    : <div className='text-center'>
                                        <div style={{ marginBottom: '20px' }}>
                                            <Image src={EmptyFolderImage} preview={false} width={100} />
                                        </div>
                                        <p style={{ fontSize: 14, color: 'black' }}>Data Not Found</p>
                                    </div>
                                :
                                <Card title="Top 5 Handling">
                                    {loaded ?
                                        knowledges.map((el, key) => (
                                            <div key={key} style={{ marginBottom: '10px' }}>
                                                <Card>
                                                    <Descriptions style={{ marginBottom: 20 }} layout="vertical" bordered>
                                                        <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16, width: '50%' }} label="Problem">
                                                            <Tag color={el.status.toLowerCase() === "open" ? "red" : el.status.toLowerCase() === "freezed" ? "blue" : "green"}>
                                                                {el.status}
                                                            </Tag>
                                                            {moment(el.created_at).format('YYYY-MM-DD HH:mm')}
                                                            <br /><br />
                                                            <span>{el.problem}</span>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16, width: '50%' }} label="Handling">
                                                            <span>{el.handling}</span>
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Card>
                                            </div>
                                        ))
                                        : <Spinner />
                                    }
                                </Card>
                        }
                    </Card>
                </Col>
            </Row>
        </Card >
    )
}

export default KnowledgeBase