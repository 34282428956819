import React from 'react';
import { Row, Col } from 'antd';
import DashboardItem from './DashboardItem';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const DataKPI2 = ({ monthlyKPI }) => {

    var target_high = []
    var target_medium = []
    var target_low = []
    var achievement_high = []
    var achievement_medium = []
    var achievement_low = []
    var star = []
    var score = []

    monthlyKPI?.map((item, i) => {
        target_high.push(item.data.target_high)
        target_medium.push(item.data.target_medium)
        target_low.push(item.data.target_low)
        achievement_high.push(item.data.achievement_high)
        achievement_medium.push(item.data.achievement_medium)
        achievement_low.push(item.data.achievement_low)
        star.push(item.data.star)
        score.push(item.data.valueKpi)
    })

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'HIGH',
                data: target_high,
                backgroundColor: 'rgb(220, 53, 69)',
                borderColor: 'rgb(220, 53, 69)',
                borderWidth: 1
            },
            {
                label: 'MEDIUM',
                data: target_medium,
                backgroundColor: 'rgb(255, 193, 7)',
                borderColor: 'rgb(255, 193, 7)',
                borderWidth: 1
            },
            {
                label: 'LOW',
                data: target_low,
                backgroundColor: 'rgb(23, 162, 184)',
                borderColor: 'rgb(23, 162, 184)',
                borderWidth: 1
            },
            {
                label: 'KPI Score',
                data: score,
                backgroundColor: 'rgb(39, 174, 96)',
                borderColor: 'rgb(39, 174, 96)',
                borderWidth: 1
            }
        ]
    }

    const data2 = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'HIGH',
                data: achievement_high,
                backgroundColor: 'rgb(220, 53, 69)',
                borderColor: 'rgb(220, 53, 69)',
                borderWidth: 1
            },
            {
                label: 'MEDIUM',
                data: achievement_medium,
                backgroundColor: 'rgb(255, 193, 7)',
                borderColor: 'rgb(255, 193, 7)',
                borderWidth: 1
            },
            {
                label: 'LOW',
                data: achievement_low,
                backgroundColor: 'rgb(23, 162, 184)',
                borderColor: 'rgb(23, 162, 184)',
                borderWidth: 1
            },
            {
                label: 'STAR',
                data: score,
                backgroundColor: 'rgb(39, 174, 96)',
                borderColor: 'rgb(39, 174, 96)',
                borderWidth: 1
            }
        ]
    }

    return (
        <>
            <div style={{ marginBottom: 10 }}>
                <Row>
                    <Col span={24}>
                        <DashboardItem title={
                            <Row justify="space-between">
                                Monthly KPI - Target Completion
                            </Row>
                        } height={400}>
                            <Bar
                                data={data}
                                width={100}
                                height={320}
                                options={{ maintainAspectRatio: false }}
                            />
                        </DashboardItem>
                    </Col>
                </Row>
            </div>

            <div style={{ marginBottom: 10 }}>
                <Row>
                    <Col span={24}>
                        <DashboardItem title={
                            <Row justify="space-between">
                                Monthly KPI - Achievement
                            </Row>
                        } height={400}>
                            <Bar
                                data={data2}
                                width={100}
                                height={320}
                                options={{ maintainAspectRatio: false }}
                            />
                        </DashboardItem>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default DataKPI2;
