import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, Col, Row, Space, Typography } from 'antd'
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { ShowButton } from '../../../components'
import { roleAccess } from '../../../helpers/menus'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { FileExcelOutlined } from '@ant-design/icons'

const { Text } = Typography

const ReportStock = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false);
    const [stocks, setStocks] = useState([]);

    const fetchStock = () => {
        api("GET", "report/inventory-stocks").then((res) => {
            setStocks(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchStock()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Image',
            key: 'sku',
            render: (row) => (
                <Avatar
                    style={{
                        verticalAlign: 'middle',
                    }}
                    size="large"
                    src={row.image} />
            ),
            align: 'center',
            width: 80
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku'
        },
        {
            title: 'Item Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name'
        },
        {
            title: 'Stock',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center'
        },
        {
            title: 'UOM',
            dataIndex: 'uom',
            key: 'uom',
            align: 'center'
        },
    ];

    const exportReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/stock/xlsx`);
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Stocks" breadcrumbs={[['Report'], ['Stocks']]} />
                <Card title={
                    <div className="full-width">
                        <Row justify="space-between">
                            <Col>
                                <Text strong style={{ float: 'left' }}>Stocks</Text>
                            </Col>
                            <Col>
                                <Button onClick={() => exportReport()} shape="round" size="middle" >
                                    <FileExcelOutlined /> Generate Excel
                                </Button>
                            </Col>
                        </Row>
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={stocks} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default ReportStock