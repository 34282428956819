import React, { useEffect, useState } from 'react'
import { GoogleMap, Autocomplete, useLoadScript, LoadScript } from "@react-google-maps/api";
import { Card, Space, Tag, Typography, Form, Input, Switch, message, Col, Row } from 'antd'
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { AddButton, DeleteButton, EditButton } from '../../../components/Button'
import { FormModal } from '../../../components/Modal'
import { roleAccess } from '../../../helpers/menus'
import moment from 'moment'

const { Text } = Typography
const { TextArea } = Input

const Location = () => {
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [location, setLocation] = useState([])
    const [defaultValues, setDefaultValues] = useState({});
    const [modalTitle, setModalTitle] = useState(null);
    const [status, setStatus] = useState(false);

    const fetchLocation = () => {
        api("GET", "locations").then((res) => {
            setLocation(res)
            setLoaded(true)
        })
    }

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAEfE7q8qQx1rRrO8ZA-gpq7YrpiFiBrzk", // Add your API key
    });

    const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        // markers.forEach(({ position }) => bounds.extend(position));
        map.fitBounds(bounds);
    };

    useEffect(() => {
        fetchLocation()

    }, []);

    const addLocation = () => {
        setModalTitle('Add New Location')
        setDefaultValues({
            id: 0,
            name: '',
            address: '',
            latitude: '',
            longitude: '',
            status: false,
        })
        setFormModal(true)
        setStatus(false)
    }

    const editLocation = (v) => {
        setModalTitle('Edit Location')
        setDefaultValues({
            id: v.id,
            name: v.name,
            address: v.address,
            latitude: v.latitude,
            longitude: v.longitude,
            status: v.status,
        })
        setFormModal(true)
        setStatus(v.status === "ACTIVE" ? true : false)
    }

    const deleteLocation = (v) => {
        api("DELETE", `location/${v}`).then((res) => {
            message.success("Successfully deleted data")
            fetchLocation()
        })
    }

    const saveLocation = (v) => {
        let payload = {
            name: v.name,
            address: v.address,
            latitude: v.latitude,
            longitude: v.longitude,
            status: status ? "ACTIVE" : "NOT ACTIVE"
        }

        if (v.id === 0) {
            api("POST", "location", payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchLocation()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        } else {
            api("PUT", `location/${v.id}`, payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchLocation()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saved data')
                setFormModal(false)
            })
        }
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: 'Coordinate',
            key: 'status',
            render: (row) => (
                `${row.latitude ? row.latitude : ''}, ${row.longitude ? row.longitude : ''}`
            ),
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "ACTIVE" ? "green" : "red"}>{(text === "ACTIVE") ? text : text}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Created At',
            key: 'created_at',
            render: (row) => (
                moment(row.created_at).format('YYYY-MM-DD HH:mm')
            ),
            width: 150
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        { roleAccess('MASTER_LOCATION_EDIT') ? 
                            <EditButton onEdit={() => editLocation(row)} />
                        : null }
                        { roleAccess('MASTER_LOCATION_DELETE') ? 
                            <DeleteButton onConfirm={() => deleteLocation(row.id)} />
                        : null }
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    const center = {
        lat: -6.1753924,
        lng: 106.8249641
    };

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Locations" breadcrumbs={[['Master'], ['Locations']]} />
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Locations</Text>
                        { roleAccess('MASTER_LOCATION_ADD') ? 
                            <AddButton right onAdd={addLocation} title="New Location" />
                        : null }
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={location} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveLocation(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item label="Address" name="address" rules={[{ required: true }]}>
                                <TextArea placeholder="Address" />
                            </Form.Item>
                            <Form.Item>
                                { isLoaded ? 
                                    <GoogleMap
                                        onLoad={handleOnLoad}
                                        mapContainerStyle={{ minWidth: "300px", minHeight: "300px" }}
                                        center={center}
                                        zoom={80}
                                    >
                                    </GoogleMap>
                                : null }
                            </Form.Item>
                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Latitude" name="latitude" rules={[{ required: true }]}>
                                        <Input placeholder="Latitude" />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Longitude" name="longitude" rules={[{ required: true }]}>
                                        <Input placeholder="Longitude" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label="Status" name="status">
                                <Switch checkedChildren="Active" unCheckedChildren="Not Active" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status === "ACTIVE" ? true : false} />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default Location