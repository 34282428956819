import React from 'react';
import { Table, Row, Col } from 'antd';
import DashboardItem from './DashboardItem';
import moment from 'moment'

const DataTechnician = ({ technician }) => {

    let columns = [{
        title: '',
        children: [
            {
                title: 'Name',
                dataIndex: 'name',
                key:  `name`,
                width: 200,
            },
        ],
    }], data = []
    technician.map((item, i) => {

        if(i == 0) {
            item.data?.map((dt) => {
                columns.push({
                    title: moment(['2023', dt.month-1]).format('MMM'),
                    children: [
                        {
                            title: <div><div style={{ transform: 'rotate(-90deg)', position: 'absolute', width: 100, bottom: 35, left: -20 }}><p>LOW </p></div><br/><br/><br/></div>,
                            dataIndex:  `low_${dt.month}`,
                            key:  `low_${dt.month}`,
                            width: 50,
                        },
                        {
                            title: <div><div style={{ transform: 'rotate(-90deg)', position: 'absolute', width: 100, bottom: 35, left: -20 }}><p>MEDIUM </p></div><br/><br/><br/></div>,
                            dataIndex:  `medium_${dt.month}`,
                            key:  `medium_${dt.month}`,
                            width: 50,
                        },
                        {
                            title: <div><div style={{ transform: 'rotate(-90deg)', position: 'absolute', width: 100, bottom: 35, left: -20 }}><p>HIGH </p></div><br/><br/><br/></div>,
                            dataIndex:  `high_${dt.month}`,
                            key:  `high_${dt.month}`,
                            width: 50,
                        },
                    ],
                })
            })
        }

        data.push(
            {
                name: item.name,
                [`low_1`]: item.data.filter(e => e.month === 1)[0]?.data?.low,
                [`medium_1`]: item.data.filter(e => e.month === 1)[0]?.data?.medium,
                [`high_1`]: item.data.filter(e => e.month === 1)[0]?.data?.high,
                [`low_2`]: item.data.filter(e => e.month === 2)[0]?.data?.low,
                [`medium_2`]: item.data.filter(e => e.month === 2)[0]?.data?.medium,
                [`high_2`]: item.data.filter(e => e.month === 2)[0]?.data?.high,
                [`low_3`]: item.data.filter(e => e.month === 3)[0]?.data?.low,
                [`medium_3`]: item.data.filter(e => e.month === 3)[0]?.data?.medium,
                [`high_3`]: item.data.filter(e => e.month === 3)[0]?.data?.high,
                [`low_4`]: item.data.filter(e => e.month === 4)[0]?.data?.low,
                [`medium_4`]: item.data.filter(e => e.month === 4)[0]?.data?.medium,
                [`high_4`]: item.data.filter(e => e.month === 4)[0]?.data?.high,
                [`low_5`]: item.data.filter(e => e.month === 5)[0]?.data?.low,
                [`medium_5`]: item.data.filter(e => e.month === 5)[0]?.data?.medium,
                [`high_5`]: item.data.filter(e => e.month === 5)[0]?.data?.high,
                [`low_6`]: item.data.filter(e => e.month === 6)[0]?.data?.low,
                [`medium_6`]: item.data.filter(e => e.month === 6)[0]?.data?.medium,
                [`high_6`]: item.data.filter(e => e.month === 6)[0]?.data?.high,
                [`low_7`]: item.data.filter(e => e.month === 7)[0]?.data?.low,
                [`medium_7`]: item.data.filter(e => e.month === 7)[0]?.data?.medium,
                [`high_7`]: item.data.filter(e => e.month === 7)[0]?.data?.high,
                [`low_8`]: item.data.filter(e => e.month === 8)[0]?.data?.low,
                [`medium_8`]: item.data.filter(e => e.month === 8)[0]?.data?.medium,
                [`high_8`]: item.data.filter(e => e.month === 8)[0]?.data?.high,
                [`low_9`]: item.data.filter(e => e.month === 9)[0]?.data?.low,
                [`medium_9`]: item.data.filter(e => e.month === 9)[0]?.data?.medium,
                [`high_9`]: item.data.filter(e => e.month === 9)[0]?.data?.high,
                [`low_10`]: item.data.filter(e => e.month === 10)[0]?.data?.low,
                [`medium_10`]: item.data.filter(e => e.month === 10)[0]?.data?.medium,
                [`high_10`]: item.data.filter(e => e.month === 10)[0]?.data?.high,
                [`low_11`]: item.data.filter(e => e.month === 11)[0]?.data?.low,
                [`medium_11`]: item.data.filter(e => e.month === 11)[0]?.data?.medium,
                [`high_11`]: item.data.filter(e => e.month === 11)[0]?.data?.high,
                [`low_12`]: item.data.filter(e => e.month === 12)[0]?.data?.low,
                [`medium_12`]: item.data.filter(e => e.month === 12)[0]?.data?.medium,
                [`high_12`]: item.data.filter(e => e.month === 12)[0]?.data?.high,
            },  
        );
    })
            
    return (
        <>
            <div style={{ marginBottom: 10 }}>
                <Row>
                    <Col span={24}>
                        <DashboardItem title={
                            <Row justify="space-between">
                                Ticket Technician
                            </Row>
                        } height={400}>

                            <Table 
                                columns={columns} 
                                dataSource={data} 
                                bordered 
                                size="middle"
                                scroll={{
                                    x: 'calc(700px + 50%)',
                                    y: 240,
                                }}
                                pagination={false}/>
                        </DashboardItem>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default DataTechnician;
