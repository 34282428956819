import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, Form, Input, message, Space, Switch, Tag, Typography } from 'antd'
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { AddButton, DeleteButton, EditButton, ShowButton } from '../../../components'
import { roleAccess } from '../../../helpers/menus'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const { Text } = Typography

const StockAdjustment = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false);
    const [stockAdjustment, setStockAdjustment] = useState([]);

    const fetchStockAdjustment = () => {
        api("GET", "stock-adjustments").then((res) => {
            setStockAdjustment(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchStockAdjustment()

    }, []);

    const deleteStockAdjustment = (v) => {
        api("DELETE", `stock-adjustment/${v}`).then((res) => {
            message.success("Successfully deleted data")
            fetchStockAdjustment()
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
            key: 'reference'
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark'
        },
        {
            title: 'Total Item',
            key: 'total_item',
            render: (row) => (
                row.items?.length
            ),
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "ACTIVE" ? "green" : "blue"}>{text}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Created At',
            key: 'created_at',
            render: (row) => (
                moment(row.created_at).format('YYYY-MM-DD HH:mm')
            ),
            width: 150
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        { roleAccess('INV_ADJUSTMENT_VIEW') ? 
                            <ShowButton onShow={() => history.push(`/inventory/stock-adjustment/view/${row.id}`)} />
                        : null }
                        { roleAccess('INV_ADJUSTMENT_EDIT') && row.status == 'ACTIVE' ? 
                            <EditButton onEdit={() => history.push(`/inventory/stock-adjustment/edit/${row.id}`)} />
                        : null }
                        { roleAccess('INV_ADJUSTMENT_DELETE') && row.status == 'ACTIVE' ? 
                            <DeleteButton onConfirm={() => deleteStockAdjustment(row.id)} />
                        : null }
                    </Space>
                </div>
            ),
            align: 'center',
            fixed: 'right',
        },
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Stock Adjustments" breadcrumbs={[['Inventory'], ['Stock Adjustments']]} />
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Stock Adjustments</Text>
                        { roleAccess('INV_ADJUSTMENT_ADD') ? 
                            <AddButton right onAdd={() => history.push('/inventory/stock-adjustment/create')} title="New Stock Adjusment" />
                        : null }
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={stockAdjustment} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default StockAdjustment