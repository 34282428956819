import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Card, Tabs, Space, Button, Select, Form, Input, Tag, message, DatePicker, Popconfirm, Row, Col, Switch } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { api, currentUser } from '../../services/api'
import { FormModal, PageTitle, Uploader, Spinner } from '../../components'
import { roleAccess } from '../../helpers/menus'
import Information from './tabs/information'
import Handling from './tabs/handling'
import History from './tabs/history'
import PartRequest from './tabs/part'
import moment from 'moment'

const { TabPane } = Tabs;
const { Option } = Select

const TicketDetail = () => {
    const { ticketId } = useParams()
    const history = useHistory()
    const [form] = Form.useForm()
    const [ticket, setTicket] = useState([])
    const [user, setUser] = useState([])
    const [users, setUsers] = useState([])
    const [priority, setPriority] = useState([])
    const [showAssignModal, setShowAssignModal] = useState(false)
    const [showFreezeModal, setShowFreezeModal] = useState(false)
    const [showCloseModal, setShowCloseModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [item, setItem] = useState([])
    const [attachmentPart, setAttachmentPart] = useState('')
    const [attachment, setAttachment] = useState('')
    const [uploading, setUploading] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [isPart, setIsPart] = useState(false);

    const fetchTicket = () => {
        api("GET", `ticket/${ticketId}`).then((res) => {
            res?.attachments?.forEach(e => {
                e.attachment_name = e.attachment.replace(process.env.REACT_APP_API_URL + '/attachments/', "")
                e.extFile = e.attachment_name.split('.').pop();
            });

            let scopes = []
            res.scopes?.map((item, i) => {
                item.scope?.coordinators?.map((coor, key) => {
                    let data = {
                        user_id: coor.user_id,
                        scope_id: item.scope_id,
                        status: item.status,
                        closed_at: item.closed_at,
                        scope: item.scope
                    }
                    scopes.push(data)
                })
            })
            
            res.scopes = scopes
            setTicket(res)
            setLoaded(true)
        })
    }

    const fetchItem = () => {
        api("GET", "inventory-stocks").then((res) => {
            let items = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id, current_stock: parseFloat(e.quantity)}
                items.push(obj)
            });
            setItem(items)
        })
    }

    const fetchUser = () => {
        api("GET", "users").then((res) => {
            let newUser = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                newUser.push(obj)
            });
            setUsers(newUser)
        })
    }

    const fetchPriority = () => {
        api("GET", "priorities").then((res) => {
            setPriority(res)
        })
    }

    useEffect(() => {
        fetchUser()
        fetchTicket()
        fetchPriority()
        fetchItem()
    }, []);

    const showAssign = () => {
        setShowAssignModal(true)
        setModalTitle("Assign To")

        let user = [], userIds = []
        ticket.engineers?.forEach((el) => {
            let obj = { label: el.user?.name, value: el.engineer_id }
            user.push(obj)
            userIds.push({
                engineer_id: el.engineer_id
            })
        })
        
        form.setFieldsValue({
            priority_id: ticket.priority_id,
            work_schedule: ticket.work_schedule ? moment(ticket.work_schedule) : null,
            assign_to: user
        })
        
        setUser(userIds)
    }

    const showFreeze = () => {
        setShowFreezeModal(true)
        setModalTitle("Freeze Ticket")
    }

    const handleChangeUser = (v) => {
        let user = []
        v.forEach((el) => {
            let obj = { engineer_id: el }
            user.push(obj)
        })

        setUser(user)
    }

    const saveAssignTo = (v) => {
        let payload = {
            priority_id: v.priority_id,
            work_schedule: moment(v.work_schedule).format('YYYY-MM-DD HH:mm'),
            engineers: user
        }
        api("POST", `ticket/assign/${ticketId}`, payload).then((res) => {
            setShowAssignModal(false)
            message.success("Successfully to assign technician")
            fetchTicket()
        })
    }

    const saveFreeze = (v) => {
        if (isPart) {

            if(v.items == undefined || v.items?.length == 0) {
                message.error('Item cannot be empty')
            } else {
    
                let status = true
                v.items?.map((el, key) => {
                    if(parseFloat(el.quantity) > el.current_stock){
                        let stock = item.find(e => e.value === el.item_id);
                        message.error(`${stock.label} maximum quantity (${el.current_stock})`)
                        status = false
                    }
                })
            
                if(status){
                    let payload = {
                        items: v.items,
                        description: v.description,
                        attachment: attachmentPart
                    }

                    api("POST", `/ticket/part-freeze/${ticket.id}`, payload).then((res) => {
                        message.success("Successfully saved data")

                        setShowFreezeModal(false)
                        fetchTicket()
                        setIsPart(false)
                        form.resetFields()
                    }).catch((err) => {
                        message.error("Failed to saving data")
                    })
                }
            }

        } else {
            let payload = {
                description: v.description
            }

            api("POST", `ticket/freeze/${ticketId}`, payload).then((res) => {
                setShowFreezeModal(false)
                message.success("Successfully freeze ticket")
                fetchTicket()
            })
        }
    }

    const showClose = () => {
        setShowCloseModal(true)
        setModalTitle("Close Ticket")
    }

    const saveClose = (v) => {
        if([2].includes(currentUser.role_id) && (attachment == undefined || attachment == '')) {
            message.error('Please upload JA')
        } else {

            let payload = {
                description: v.description,
                status: 'SOLVED',
                attachment: attachment
            }

            api("POST", `ticket/handling/${ticketId}`, payload).then((res) => {
                fetchTicket()
                setShowCloseModal(false)
                window.location.reload(false);
            }).catch((err) => {
                message.error("Failed to saving data")
            })

            api("GET", `ticket/close/${ticketId}`).then((res) => {
                message.success("Successfully closed ticket")
                fetchTicket()
            })
        }
    }
    
    const reopenTicket = () => {
        api("GET", `ticket/reopen/${ticketId}`).then((res) => {
            message.success("Successfully reopened ticket")
            fetchTicket()
        })
    }

    const fileUploadedPart = (v) => {
        setAttachmentPart(v.data.url)
    }

    const fileUploaded = (v) => {
        setAttachment(v.data.url)
    }

    const currentStock = (v, key) => {
        let stock = item.find(el => el.value === v);

        let fields = form.getFieldsValue()
        fields?.items?.map((item, key) => {
            if(item.item_id == v) item.current_stock = stock.current_stock
        })
        form.setFieldsValue(fields)
    }

    return (
        <Card className="content-container">
            <PageTitle title="Ticket - Detail" breadcrumbs={[['Ticket', '/tickets'], ['Detail']]} back={
                <Space>
                    {
                        ticket.status === "OPEN" ?
                            <>
                                {roleAccess('TICKET_ASSIGN') ?
                                    [2,3].includes(currentUser.role_id) ?
                                        ticket.scopes?.find(el => el.user_id == currentUser.id)?.status == 'OPEN' ? 
                                            <Button className='button-handling' type='primary' onClick={showAssign}>
                                                Assign
                                            </Button>
                                        :
                                        null
                                    :  
                                        <Button className='button-handling' type='primary' onClick={showAssign}>
                                            Assign
                                        </Button>
                                    : null}
                                {roleAccess('TICKET_FREEZE') ?
                                    [2,3].includes(currentUser.role_id) ?
                                        ticket.scopes?.find(el => el.user_id == currentUser.id)?.status == 'OPEN' ? 
                                            <Button type='primary' onClick={showFreeze}>
                                                Freeze
                                            </Button>
                                        :
                                        null
                                    : 
                                        <Button type='primary' onClick={showFreeze}>
                                            Freeze
                                        </Button>
                                : null}
                                {roleAccess('TICKET_CLOSE') ?
                                    [2,3].includes(currentUser.role_id) ?
                                        ticket.scopes?.find(el => el.user_id == currentUser.id)?.status == 'OPEN' ? 
                                            <Button danger type='primary' onClick={showClose}>
                                                Close
                                            </Button>
                                        :
                                        <Button danger>
                                            CLOSED
                                        </Button>
                                    : 
                                        <Button danger type='primary' onClick={showClose}>
                                            Close
                                        </Button>
                                : null}
                            </>
                            : ticket.status === "FREEZED" || ticket.status === "CLOSED" ?
                                roleAccess('TICKET_ADD') || roleAccess('TICKET_CLOSE') ?
                                    <Popconfirm title="Reopen Ticket ?" okText="Yes" cancelText="No" onConfirm={() => reopenTicket()}>
                                        <Button type='primary' >
                                            Reopen
                                        </Button>
                                    </Popconfirm>
                                    : null
                                : null
                    }
                </Space>
            } />

            <Tabs defaultActiveKey="1" type='card'>
                <TabPane tab="Information" key="detail_ticket">
                    {loaded ?
                        <Information ticket={ticket} />
                        : <Spinner />}
                </TabPane>

                {roleAccess('TICKET_HANDLING') ?
                    <TabPane tab="Handlings" key="handling">
                        {loaded ?
                            <Handling ticketId={ticketId} />
                            : <Spinner />}
                    </TabPane>
                    : null}
                {roleAccess('TICKET_PART_REQUEST') ?
                    <TabPane tab="Part Requests" key="part_request">
                        {loaded ?
                            <PartRequest ticketId={ticketId} />
                            : <Spinner />}
                    </TabPane>
                    : null}
                {roleAccess('TICKET_HISTORY') ?
                    <TabPane tab="Audit Trail" key="history">
                        {loaded ?
                            <History ticket={ticket} ticketId={ticketId} />
                            : <Spinner />}
                    </TabPane>
                    : null}
            </Tabs>

            {
                showAssign ?
                    <FormModal form={form} title={modalTitle} submitForm={(v) => saveAssignTo(v)} width={500} showModal={showAssignModal} onCloseModal={() => setShowAssignModal(false)}>
                        <Form.Item label="Priority" name="priority_id" rules={[{ required: true }]}>
                            <Select placeholder="Select Priority">
                                {
                                    priority.map((el, key) => (
                                        <Option key={key} value={el.id}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Work Schedule" name="work_schedule" rules={[{ required: true }]}>
                            <DatePicker showTime format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label="Assign To" name="assign_to" rules={[{ required: true }]}>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select Technician"
                                onChange={handleChangeUser}
                                options={users}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </FormModal> :
                    null
            }

            {
                showFreeze ?
                    <FormModal form={form} title={modalTitle} submitForm={saveFreeze} width={600} showModal={showFreezeModal} onCloseModal={() => setShowFreezeModal(false)}>
                        <Form.Item label="Use Sparepart" name="is_part">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(v) => setIsPart(v)} />
                        </Form.Item>

                        {isPart ?
                            <>
                                <Card title="Items" style={{ marginBottom: 20 }}>
                                    <Form.List name="items">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Row key={key} justify="space-between" style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                        <Col span={10}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'item_id']}
                                                                rules={[{ required: true, message: 'Missing item name' }]}

                                                            >
                                                                <Select
                                                                    showSearch
                                                                    placeholder="Select Item"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) =>
                                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    options={item}
                                                                    onChange={(v) => currentStock(v)}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={5}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'current_stock']}
                                                            >
                                                                <Input readOnly placeholder="Current Stock" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={5}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'quantity']}
                                                                rules={[{ required: true, message: 'Missing quantity' }]}
                                                            >
                                                                <Input placeholder="Quantity" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={3}>
                                                            <Button type='primary' danger shape='circle' icon={<MinusCircleOutlined />} onClick={() => remove(name)} />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        Add Item
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Card>
                                <Form.Item label="Attachment" rules={[{ required: true }]}>
                                    <Uploader multiple={false} dragger onUploaded={(v, key) => fileUploadedPart(v)} isUploading={(v) => setUploading(v)} />
                                </Form.Item>
                                <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                                    <Input.TextArea placeholder="Description" rows={7} />
                                </Form.Item>
                            </>
                            :
                            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                                <Input.TextArea placeholder="Description" rows={7} />
                            </Form.Item>
                        }
                    </FormModal> :
                    null
            }

            {
                showClose ?
                    <FormModal form={form} title={modalTitle} submitForm={saveClose} showModal={showCloseModal} onCloseModal={() => setShowCloseModal(false)}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Please upload JA (Job Acceptance)" name="description" rules={[{ required: true }]}>
                            <Input.TextArea placeholder="Description" rows={5} />
                        </Form.Item>
                        <Form.Item>
                            <Uploader multiple={false} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                        </Form.Item>
                    </FormModal> :
                null
            }
        </Card>
    )
}

export default TicketDetail