import React, { useEffect, useState } from 'react'
import { Card, Descriptions, Tag, Button, Popconfirm, message, Image } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { api } from '../../../services/api'
import { DataTable, PageTitle } from '../../../components'
import { roleAccess } from '../../../helpers/menus'
import moment from 'moment'

const PartRequestDetail = () => {
    const history = useHistory()
    const { stockId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [partRequest, setPartRequest] = useState([])

    const fetchPartRequest = () => {
        api("GET", `part-request/${stockId}`).then((res) => {
            res.attachment_name = res.attachment?.replace(process.env.REACT_APP_API_URL + '/attachments/', "")
            res.extFile = res.attachment_name.split('.').pop();

            setPartRequest(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchPartRequest()
    }, []);

    const approveRequest = () => {
        api("GET", `part-request/approve/${stockId}`).then((res) => {
            message.success("Successfully approved request")

            fetchPartRequest()
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'SKU',
            key: 'sku',
            render: (row) => (
                row.item?.sku
            ),
            align: 'center'
        },
        {
            title: 'Item Name',
            key: 'item_name',
            render: (row) => (
                row.item?.name
            ),
            align: 'center'
        },
        {
            title: 'Current Stock',
            key: 'current_stock',
            render: (row) => (
                `${row.current_stock} ${row?.item?.uom?.name}`
            ),
            align: 'center'
        },
        {
            title: 'Quantity',
            key: 'quantity',
            render: (row) => (
                `${row.quantity} ${row?.item?.uom?.name}`
            ),
            align: 'center'
        },
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Part Request - Detail" breadcrumbs={[['Inventory'], ['Part Request', '/inventory/part-requests'], ['Detail']]} />

            <Card>
                {
                    loaded ?
                        <>
                            <div style={{ marginBottom: 20 }}>
                                <Descriptions title={`Information #${partRequest.reference ? partRequest.reference : '-'}`} layout="vertical" bordered>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Ticket Number">
                                        <Button type='link' onClick={() => history.push(`/ticket/view/${partRequest.ticket?.id}`)}>
                                            {partRequest.ticket?.reference}
                                        </Button>
                                    </Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Requested By">{partRequest.user?.name}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Requested At">{moment(partRequest.created_at).format('YYYY-MM-DD HH:mm')}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Status">
                                        <Tag color={partRequest.status === 'COMPLETED' ? 'green' : 'red'} >{partRequest.status}</Tag>
                                    </Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Approved By">{partRequest.approved?.name}</Descriptions.Item>
                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Approved At">{partRequest.approved_at ? moment(partRequest.approved_at).format('YYYY-MM-DD HH:mm') : '-'}</Descriptions.Item>

                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 16 }} label="Description">{partRequest.description}</Descriptions.Item>
                                </Descriptions>
                            </div>

                            <Card title="Items">
                                <DataTable size='small' search={false} pagination={false} bordered dataSource={partRequest.items} columns={columns} />
                            </Card>

                            {
                                partRequest.attachment ?
                                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                                        <Descriptions title="Attachment" layout="vertical" bordered>
                                            <Descriptions.Item title="Attachment" layout="vertical" bordered>
                                                {partRequest.attachment ?
                                                    partRequest.extFile == 'pdf' ?
                                                        <Image
                                                            width={120}
                                                            src={`${process.env.REACT_APP_PUBLIC_URL}/pdf.png`}
                                                            onClick={() => window.open(`${partRequest.attachment}`)}
                                                        />
                                                        :
                                                        <Image
                                                            width={120}
                                                            src={partRequest.attachment}
                                                        />
                                                    : null}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </div>
                                    : null
                            }

                            {roleAccess('INV_PART_REQUEST_APPROVE') ?
                                partRequest.status == "REQUEST" ?
                                    <div style={{ float: "right", marginTop: '10px' }}>
                                        <Popconfirm title="Approve to reduce the stock of item?" okText="Yes" cancelText="No" onConfirm={() => approveRequest()}>
                                            <Button className='button-handling' type='primary'>Approve Request</Button>
                                        </Popconfirm>

                                    </div>
                                    : null
                                : null
                            }
                        </>
                        : null
                }
            </Card>
        </Card>
    )
}

export default PartRequestDetail