import React, { useEffect, useState } from 'react'
import { Card, Space, Tag, Typography, Form, Input, Switch, message } from 'antd'
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { AddButton, DeleteButton, EditButton } from '../../../components/Button'
import { FormModal } from '../../../components/Modal'
import { roleAccess } from '../../../helpers/menus'
import moment from 'moment'

const { Text } = Typography

const CategoryItem = () => {
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [categoryItem, setCategoryItem] = useState([])
    const [defaultValues, setDefaultValues] = useState({});
    const [modalTitle, setModalTitle] = useState(null);
    const [status, setStatus] = useState(false);

    const fetchCategoryItem = () => {
        api("GET", "categories").then((res) => {
            setCategoryItem(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchCategoryItem()

    }, []);

    const addCategoryItem = () => {
        setModalTitle('Add New Category')
        setDefaultValues({
            id: 0,
            name: '',
            status: false,
        })
        setFormModal(true)
        setStatus(false)
    }

    const editCategoryItem = (v) => {
        setModalTitle('Edit Category')
        setDefaultValues({
            id: v.id,
            name: v.name,
            status: v.status,
        })
        setFormModal(true)
        setStatus(v.status === "ACTIVE" ? true : false)
    }

    const deleteCategoryItem = (v) => {
        api("DELETE", `category/${v}`).then((res) => {
            message.success("Successfully deleted data")
            fetchCategoryItem()
        })
    }

    const saveCategoryItem = (v) => {
        let payload = {
            name: v.name,
            status: status ? "ACTIVE" : "NOT ACTIVE"
        }

        if (v.id === 0) {
            api("POST", "category", payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchCategoryItem()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        } else {
            api("PUT", `category/${v.id}`, payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchCategoryItem()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        }
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "ACTIVE" ? "green" : "red"}>{(text === "ACTIVE") ? text : text}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Created At',
            key: 'created_at',
            render: (row) => (
                moment(row.created_at).format('YYYY-MM-DD HH:mm')
            ),
            width: 150
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        { roleAccess('INV_CATEGORY_EDIT') ? 
                            <EditButton onEdit={() => editCategoryItem(row)} />
                        : null }
                        { roleAccess('INV_CATEGORY_DELETE') ? 
                            <DeleteButton onConfirm={() => deleteCategoryItem(row.id)} />
                        : null }
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Categories" breadcrumbs={[['Inventory'], ['Categories']]} />
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Categories</Text>
                        { roleAccess('INV_CATEGORY_ADD') ? 
                            <AddButton right onAdd={addCategoryItem} title="New Category" />
                        : null }
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={categoryItem} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveCategoryItem(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item label="Status" name="status">
                                <Switch checkedChildren="Active" unCheckedChildren="Not Active" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status === "ACTIVE" ? true : false} />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default CategoryItem