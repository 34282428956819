import React, { useEffect, useState } from 'react'
import { Card, Space, Tabs, Typography, Button, Drawer, Row, Col, Form, DatePicker, Modal, Statistic } from 'antd'
import { FileExcelOutlined, FilterOutlined, InfoCircleOutlined, FileDoneOutlined } from '@ant-design/icons';
import { PageTitle } from '../../../components/PageTitle'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { api } from '../../../services/api'
import moment from 'moment'
import './style.css'

const { TabPane } = Tabs
const { Text } = Typography

const KpiPerformance = () => {
    const [loaded, setLoaded] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [technicianKpi, setTechnicianKpi] = useState([])
    const [coordinatorKpi, setCoordinatorKpi] = useState([])
    const [tabsKey, setTabsKey] = useState('coordinator')
    const [yearFilter, setYearFilter] = useState(moment(Date.now()).format('YYYY'))
    const [showModalCoordinator, setShowModalCoordinator] = useState(false);
    const [showModalTechnician, setShowModalTechnician] = useState(false);
    const [kpi, setKpi] = useState();

    const fetchCoordinatorKpi = (v) => {
        api("GET", `report/kpi-coordinator?year=${v}`).then((res) => {
            let data = []
            res.map((item, i) => {
                data.push({
                    name: item.name,
                    jan: item.kpis.filter(e => e.month === 1)[0],
                    feb: item.kpis.filter(e => e.month === 2)[0],
                    mar: item.kpis.filter(e => e.month === 3)[0],
                    apr: item.kpis.filter(e => e.month === 4)[0],
                    may: item.kpis.filter(e => e.month === 5)[0],
                    jun: item.kpis.filter(e => e.month === 6)[0],
                    jul: item.kpis.filter(e => e.month === 7)[0],
                    aug: item.kpis.filter(e => e.month === 8)[0],
                    sep: item.kpis.filter(e => e.month === 9)[0],
                    oct: item.kpis.filter(e => e.month === 10)[0],
                    nov: item.kpis.filter(e => e.month === 11)[0],
                    des: item.kpis.filter(e => e.month === 12)[0],
                })
            })

            setCoordinatorKpi(data)
            setLoaded(true)
        })
    }

    const fetchTechnicianKpi = (v) => {
        api("GET", `report/kpi-technician?year=${v}`).then((res) => {
            let data = []
            res.map((item, i) => {
                data.push({
                    name: item.name,
                    jan: item.kpis.filter(e => e.month === 1)[0],
                    feb: item.kpis.filter(e => e.month === 2)[0],
                    mar: item.kpis.filter(e => e.month === 3)[0],
                    apr: item.kpis.filter(e => e.month === 4)[0],
                    may: item.kpis.filter(e => e.month === 5)[0],
                    jun: item.kpis.filter(e => e.month === 6)[0],
                    jul: item.kpis.filter(e => e.month === 7)[0],
                    aug: item.kpis.filter(e => e.month === 8)[0],
                    sep: item.kpis.filter(e => e.month === 9)[0],
                    oct: item.kpis.filter(e => e.month === 10)[0],
                    nov: item.kpis.filter(e => e.month === 11)[0],
                    des: item.kpis.filter(e => e.month === 12)[0],
                })
            })
            setTechnicianKpi(data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchCoordinatorKpi(yearFilter)
        fetchTechnicianKpi(yearFilter)

    }, []);

    const onFinishFilter = (v) => {
        setLoaded(false)
        setYearFilter(moment(v.year).format('YYYY'))
        fetchCoordinatorKpi(moment(v.year).format('YYYY'))
        fetchTechnicianKpi(moment(v.year).format('YYYY'))
        setShowDrawer(false)
    }

    const resetFilter = () => {
        fetchCoordinatorKpi(yearFilter)
        fetchTechnicianKpi(yearFilter)
        setShowDrawer(false)
    }

    const showModal = (row) => {
        setKpi(row.kpi)
        
        console.log(row)
        if(row.type == 'coordinator') setShowModalCoordinator(true)
        else setShowModalTechnician(true)
    }

    const handleOk = () => {
        setShowModalCoordinator(false)
        setShowModalTechnician(false)
    };

    const exportReportCoordinator = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/kpi-coordinator/xlsx?year=${yearFilter}`);
    }

    const exportReportTechnician = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/kpi-technician/xlsx?year=${yearFilter}`);
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (row) => (
                row.toUpperCase()
            ),
            align: 'center',
            fixed: 'left',
            width: 300,
        },
        {
            title: 'January',
            dataIndex: 'jan',
            key: 'jan',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'February',
            key: 'feb',
            dataIndex: 'feb',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'March',
            key: 'mar',
            dataIndex: 'mar',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'April',
            key: 'apr',
            dataIndex: 'apr',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'May',
            key: 'may',
            dataIndex: 'may',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'June',
            key: 'jun',
            dataIndex: 'jun',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'July',
            key: 'jul',
            dataIndex: 'jul',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'August',
            key: 'aug',
            dataIndex: 'aug',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'September',
            key: 'sep',
            dataIndex: 'sep',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'October',
            key: 'oct',
            dataIndex: 'oct',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'November',
            key: 'nov',
            dataIndex: 'nov',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'December',
            key: 'des',
            dataIndex: 'des',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Report KPI" breadcrumbs={[['Report'], ['Report KPI']]} />
                <Card title={
                    <div className="full-width">
                        <Row justify="space-between">
                            <Col>
                                <Text strong style={{ float: 'left' }}>Report KPI Period {yearFilter}</Text>
                            </Col>
                            <Col>
                                <Space>
                                    <Button onClick={() => setShowDrawer(true)} shape="round" icon={<FilterOutlined />} size="middle" />
                                    <Button onClick={() => tabsKey === 'technician' ? exportReportTechnician() : exportReportCoordinator()} shape="round" size="middle" >
                                        <FileExcelOutlined /> Generate Excel
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>}
                >
                    {
                        (loaded) ?
                            <Tabs defaultActiveKey={'coordinator'} onChange={(v) => setTabsKey(v)} type='card'>
                                <TabPane tab='Coordinator' key='coordinator'>
                                    <DataTable size='small' bordered dataSource={coordinatorKpi} columns={columns} />
                                </TabPane>
                                <TabPane tab='Technician' key='technician'>
                                    <DataTable size='small' bordered dataSource={technicianKpi} columns={columns} />
                                </TabPane>
                            </Tabs>
                            :
                            <Spinner />
                    }
                </Card>

                {
                    showDrawer ?
                        <Drawer
                            title="Search Ticket"
                            placement="right"
                            width={400}
                            onClose={() => setShowDrawer(false)}
                            open={showDrawer}
                        >
                            <Form onFinish={onFinishFilter} layout='vertical'>
                                <Form.Item label="Year" name="year">
                                    <DatePicker style={{ width: '100%' }} picker="year" defaultValue={moment(Date.now())} />
                                </Form.Item>

                                <Space>
                                    <Form.Item>
                                        <Button type="primary" htmlType='submit'>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button onClick={resetFilter} danger type="primary">
                                            Reset
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Form>
                        </Drawer>
                        : null
                }


                {showModalCoordinator ? 
                    <Modal title="KPI" open={showModalCoordinator} onOk={handleOk} onClose={handleOk}>

                        <Row justify='space-between' className='mb-5'>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#dc3545" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>OUTSTANDING</Text>
                                    </div>
                                    <Statistic
                                        value={kpi.outstanding ?? 0 + ` WO`}
                                        valueStyle={{ color: 'white', fontSize: 14 }}
                                        prefix={<FileDoneOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#28a745" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>ACHIEVEMENT</Text>
                                    </div>
                                    <Statistic
                                        value={kpi.closed ?? 0 + ` WO`}
                                        valueStyle={{ color: 'white', fontSize: 14 }}
                                        prefix={<FileDoneOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#17a2b8" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>ASSIGNMENT</Text>
                                    </div>
                                    <Statistic
                                        value={kpi.assignment ?? 0}
                                        valueStyle={{ color: 'white', fontSize: 14 }}
                                        prefix={<FileDoneOutlined />}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Modal>
                : null }


                {showModalTechnician ? 
                    <Modal title="KPI" open={showModalTechnician} onOk={handleOk} onClose={handleOk}>

                        <Text style={{ fontSize: 14, marginBottom: 15 }}>TARGET COMPLETION</Text>
                        <Row justify='space-between' className='mb-5'>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#ffc107" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>LOW</Text>
                                    </div>
                                    <Statistic
                                        value={kpi.target_low ?? 0 + ` DAY`}
                                        valueStyle={{ color: 'white', fontSize: 14 }}
                                        prefix={<FileDoneOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#28a745" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>MEDIUM</Text>
                                    </div>
                                    <Statistic
                                        value={kpi.target_medium ?? 0 + ` DAY`}
                                        valueStyle={{ color: 'white', fontSize: 14 }}
                                        prefix={<FileDoneOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#dc3545" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>HIGH</Text>
                                    </div>
                                    <Statistic
                                        value={kpi.target_high ?? 0 + ` DAY`}
                                        valueStyle={{ color: 'white', fontSize: 14 }}
                                        prefix={<FileDoneOutlined />}
                                    />
                                </Card>
                            </Col>
                        </Row>

                        <Text style={{ fontSize: 14, marginBottom: 15 }}>ACHIEVEMENT</Text>
                        <Row justify='space-between' className='mb-5'>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#ffc107" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>LOW</Text>
                                    </div>
                                    <Statistic
                                        value={kpi.achievement_low ?? 0 + ` WO`}
                                        valueStyle={{ color: 'white', fontSize: 14 }}
                                        prefix={<FileDoneOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#28a745" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>MEDIUM</Text>
                                    </div>
                                    <Statistic
                                        value={kpi.achievement_medium ?? 0 + ` WO`}
                                        valueStyle={{ color: 'white', fontSize: 14 }}
                                        prefix={<FileDoneOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#dc3545" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>HIGH</Text>
                                    </div>
                                    <Statistic
                                        value={kpi.achievement_high ?? 0 + ` WO`}
                                        valueStyle={{ color: 'white', fontSize: 14 }}
                                        prefix={<FileDoneOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col span={7} style={{ marginTop: 15 }}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#17a2b8" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>STAR</Text>
                                    </div>
                                    <Statistic
                                        value={kpi.star ?? 0}
                                        valueStyle={{ color: 'white', fontSize: 14 }}
                                        prefix={<FileDoneOutlined />}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Modal>
                : null }
            </Card>
        </div>
    )
}

export default KpiPerformance