import React from 'react';
import { Statistic, Card, Row, Col } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import DashboardItem from './DashboardItem';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const DataTicket = ({ countTickets, monthlyTickets }) => {
    const bgColors = ['#28a745', '#17a2b8', '#ffc107', '#dc3545']

    var open = []
    var freezed = []
    var closed = []
    monthlyTickets?.map((item, i) => {
        if (item.data.length) {
            item.data?.map((ticket, t) => {
                if (ticket.status == 'OPEN') open.push(ticket.total)
                if (ticket.status == 'FREEZED') freezed.push(ticket.total)
                if (ticket.status == 'CLOSED') closed.push(ticket.total)
            })
        } else {
            open.push(0)
            freezed.push(0)
            closed.push(0)
        }
    })

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Open',
                data: open,
                backgroundColor: 'rgb(220, 53, 69)',
                borderColor: 'rgb(220, 53, 69)',
                borderWidth: 1
            },
            {
                label: 'Freezed',
                data: freezed,
                backgroundColor: 'rgb(23, 162, 184)',
                borderColor: 'rgb(23, 162, 184)',
                borderWidth: 1
            },
            {
                label: 'Closed',
                data: closed,
                backgroundColor: 'rgb(39, 174, 96)',
                borderColor: 'rgb(39, 174, 96)',
                borderWidth: 1
            }
        ]
    }

    return (
        <>
            <div style={{ marginBottom: 10 }}>
                <Row justify='space-between' className='mb-5'>
                    {countTickets?.map((item, i) => (
                        <Col key={i} span={5}>
                            <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: bgColors[i] }}>
                                <div className='mb-3'>
                                    <Text style={{ fontSize: 20, color: 'white' }}>{item.status}</Text>
                                </div>
                                <Statistic
                                    value={item.total}
                                    valueStyle={{ color: 'white', fontSize: 20 }}
                                    prefix={<FileDoneOutlined />}
                                />
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col span={24}>
                        <DashboardItem title={
                            <Row justify="space-between">
                                Monthly Tickets
                            </Row>
                        } height={400}>
                            <Bar
                                data={data}
                                width={100}
                                height={320}
                                options={{ maintainAspectRatio: false }}
                            />
                        </DashboardItem>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default DataTicket;
