import { Card } from 'antd';
import React from 'react';

const DashboardItem = (props) => {
    return (
        <Card style={{ borderRadius: 5, width: '100%' }} className="custom-box-shadow">
            <div style={{ marginBottom: 20 }}>
                <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: 10 }}>
                    <div style={{ width: '100%', height: 50, padding: 5, borderBottom: '1px solid #f0f0f0', fontSize: 16, fontWeight: 500, verticalAlign: 'middle' }}>
                        {props.title}
                    </div>
                    <div style={{ height: props.height, paddingTop: 5, verticalAlign: 'middle' }}>
                        {props.children}
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default DashboardItem;
