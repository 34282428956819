import React from 'react';
import { Layout, Card } from 'antd';

const { Footer } = Layout;

const Home = () => {
    const changeLanguage = (lang) => {
        localStorage.setItem('lang', lang)
        window.location.reload()
    }

    const currentLanguage = localStorage.getItem('lang') || 'id'

    return (
        <React.Fragment>
            <Footer style={{ textAlign: 'center', height: '8vh', padding: 0 }}>
                <Card className="box-shadow-4 full-width">
                    © Copyright {new Date().getFullYear()} PT SPIE Oil & Gas Services Indonesia. All Rights Reserved.
                    <div style={{ float: 'right' }}>
                        {
                            (currentLanguage === 'id') ?
                                <span onClick={() => changeLanguage('en')}>
                                    <img src={process.env.PUBLIC_URL + '/images/flag-us.png'} style={{ border: '1px solid #808080', borderRadius: '50%', cursor: 'pointer' }} width="20px" alt="lg-en" />
                                </span>
                                :
                                <span onClick={() => changeLanguage('id')}>
                                    <img src={process.env.PUBLIC_URL + '/images/flag-id.png'} style={{ border: '1px solid #808080', borderRadius: '50%', cursor: 'pointer' }} width="20px" alt="lg-id" />
                                </span>
                        }
                    </div>
                </Card>
            </Footer>
        </React.Fragment>
    );
}

export default Home;
