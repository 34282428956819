import React, { useEffect, useState } from 'react'
import { Card, Space, Tag, Typography, message, Button, Drawer, Row, Col, Form, Select, DatePicker } from 'antd'
import { FileExcelOutlined, FilterOutlined } from '@ant-design/icons';
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker;

const ReportTicket = () => {
    const history = useHistory()
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [scopes, setScopes] = useState([])
    const [scopeIds, setScopeIds] = useState([])
    const [locations, setLocations] = useState([])
    const [locationIds, setLocationIds] = useState([])
    const [types, setTypes] = useState([])
    const [priorities, setPriorities] = useState([])

    const fetchTicket = () => {
        api("GET", `tickets`).then((res) => {
            setTickets(res)
            setLoaded(true)
        })
    }

    const fetchScope = () => {
        api("GET", "scopes").then((res) => {
            let scope = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                scope.push(obj)
            });
            setScopes(scope)
        })
    }

    const fetchType = () => {
        api("GET", "types").then((res) => {
            setTypes(res)
        })
    }

    const fetchPriority = () => {
        api("GET", "priorities").then((res) => {
            setPriorities(res)
        })
    }

    const fetchLocation = () => {
        api("GET", "locations").then((res) => {
            let location = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                location.push(obj)
            });
            setLocations(location)
        })
    }

    useEffect(() => {
        fetchTicket()
        fetchScope()
        fetchType()
        fetchLocation()
        fetchPriority()

    }, []);

    const handleLocation = (v) => {
        const loc = []
        v.forEach((el) => {
            loc.push(el)
        })

        setLocationIds(loc)
    }

    const handleScope = (v) => {
        const scope = []
        v.forEach((el) => {
            scope.push(el)
        })

        setScopeIds(scope)
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: 50,
            fixed: 'left',
        },
        {
            title: 'Reference',
            key: 'reference',
            render: (row) => (
                <Button type='link' onClick={() => history.push(`/ticket/view/${row.id}`)}>
                    {row.reference}
                </Button>
            ),
            fixed: 'left',
            width: 150,
            align: 'center'
        },
        {
            title: 'WO Number',
            key: 'number',
            dataIndex: 'number',
            width: 150,
        },
        {
            title: 'Description',
            key: 'description',
            render: (row) => (
                `${row.description?.substring(0, 50)} ${row.description.length > 50 ? '..' : ''}`
            ),
            width: 200
        },
        {
            title: 'Scopes',
            key: 'scopes',
            render: (row) => (
                row?.scopes?.map((el) => (
                    <Tag color='blue'>{el.scope?.name}</Tag>
                ))
            ),
            align: 'center',
            width: 150
        },
        {
            title: 'Type',
            key: 'type',
            render: (text) => (
                text.type_id !== null ? text.type.name : '-'
            ),
            align: 'center',
            width: 100
        },
        {
            title: 'Assign To',
            key: 'assign_to',
            render: (row) => (
                row?.engineers.map((el) => (
                    <Tag color='cyan'>{el.user?.name}</Tag>
                ))
            ),
            align: 'center',
            width: 250
        },
        {
            title: 'Locations',
            key: 'locations',
            render: (row) => (
                row?.locations.map((el) => (
                    <Tag color='orange'>{el.location?.name}</Tag>
                ))
            ),
            align: 'center',
            width: 150
        },
        {
            title: 'Requested At',
            key: 'requested_at',
            render: (text) => (
                text.requested_at === null ? '-' : moment(text.requested_at).format('DD MMMM YYYY, HH:mm:ss')
            ),
            align: 'center',
            width: 200
        },
        {
            title: 'Baseline End Date',
            key: 'target_completion',
            render: (text) => (
                text.target_completion === null ? '-' : moment(text.target_completion).format('DD MMMM YYYY, HH:mm:ss')
            ),
            align: 'center',
            width: 200
        },
        {
            title: 'Closed At',
            key: 'closed_at',
            render: (text) => (
                text.closed_at === null ? '-' : moment(text.closed_at).format('DD MMMM YYYY, HH:mm:ss')
            ),
            align: 'center',
            width: 200
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "OPEN" ? "red" : text === "CLOSED" ? "green" : "blue"}>{text}</Tag>
            ),
            align: 'center',
            fixed: 'right',
            width: 100
        },
    ];

    const onFinishFilter = (v) => {
        const start_date = v.requested_at ? moment(v.requested_at[0]).format("YYYY-MM-DD HH:mm:ss") : ''
        const end_date = v.requested_at ? moment(v.requested_at[1]).format("YYYY-MM-DD HH:mm:ss") : ''
        api("GET", `report/tickets?scope_id=${scopeIds}&location_id=${locationIds}&priority_id=${v.priority_id ? v.priority_id : ''}&type_id=${v.type_id ? v.type_id : ''}&status=${v.status ? v.status : ''}&start_date=${start_date}&end_date=${end_date}`).then((res) => {
            setTickets(res)
            setLoaded(true)
            setShowDrawer(false)
        })
    }

    const resetFilter = () => {
        fetchTicket()
        setShowDrawer(false)
    }

    const exportReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/ticket/xlsx?scope_id=${scopeIds}&location_id=${locationIds}`);
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Tickets" breadcrumbs={[['Report'], ['Tickets']]} />
                <Card title={
                    <div className="full-width">
                        <Row justify="space-between">
                            <Col>
                                <Text strong style={{ float: 'left' }}>Tickets</Text>
                            </Col>
                            <Col>
                                <Space>
                                    <Button onClick={() => setShowDrawer(true)} shape="round" icon={<FilterOutlined />} size="middle" />
                                    <Button onClick={() => exportReport()} shape="round" size="middle" >
                                        <FileExcelOutlined /> Generate Excel
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={tickets} columns={columns} scroll={{ x: 900 }} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    showDrawer ?
                        <Drawer
                            title="Search Ticket"
                            placement="right"
                            width={400}
                            onClose={() => setShowDrawer(false)}
                            open={showDrawer}
                        >
                            <Form onFinish={onFinishFilter} layout='vertical'>
                                <Form.Item label="Scopes" name="scopes">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select Scope"
                                        onChange={handleScope}
                                        options={scopes}
                                    />
                                </Form.Item>
                                <Form.Item label="Type" name="type_id">
                                    <Select placeholder="Select Type">
                                        {
                                            types.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Priority" name="priority_id">
                                    <Select placeholder="Select Priority">
                                        {
                                            priorities.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Locations" name="locations">
                                    <Select
                                        mode="tags"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select Location"
                                        onChange={handleLocation}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={locations}
                                    />
                                </Form.Item>

                                <Form.Item label="Status" name="status">
                                    <Select placeholder="Select Status">
                                        <Option value="OPEN">Open</Option>
                                        <Option value="CLOSED">Closed</Option>
                                        <Option value="FREEZED">Freezed</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item label="Requested At" name="requested_at">
                                    <RangePicker />
                                </Form.Item>

                                <Space>
                                    <Form.Item>
                                        <Button type="primary" htmlType='submit'>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button onClick={resetFilter} danger type="primary">
                                            Reset
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Form>
                        </Drawer>
                        : null
                }
            </Card>
        </div>
    )
}

export default ReportTicket