import React from 'react';

export const DefaultTable = (props) => {
    const {width='100%', numbered=false, data=[]} = props

    const generateHeaderStyle=(el)=>{
        let style={};
        if(el.width){
            style.width=el.width
        }
        if(el.headerAlign){
            style.textAlign=el.headerAlign
        }
        return style
    }

    const generateColumnStyle=(el)=>{
        let style={};
        if(el.bodyAlign){
            style.textAlign=el.bodyAlign
        }
        return style
    }

    return (
        <div>
            <table className="table-collapse table-default" style={{width:width}}>
                <thead>
                    <tr>
                        {
                            (numbered)?<th className="text-center" style={{width:'5%'}}>No</th>:null
                        }
                        {
                            props.column.map((el, key)=>(
                                <th key={'table_header_'+key} style={generateHeaderStyle(el)}>
                                    {el.name}
                                </th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((el, key) => (
                            <tr key={'table_item_'+key}>
                                {
                                    (numbered)?<td className="text-center">{key+1}</td>:null
                                }
                                {
                                    props.column.map((row, index)=>{
                                        return(
                                            <td key={'table_item_data_'+row.key+'_'+index} style={generateColumnStyle(row)}>
                                                {el[row.key]}
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

// export default DefaultTable;
