import React, { useEffect, useState } from 'react'
import { Card, Space, Typography, Button, Drawer, Row, Col, Form, Rate } from 'antd'
import { FileExcelOutlined, FilterOutlined } from '@ant-design/icons';
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { useHistory } from 'react-router-dom';

const { Text } = Typography

const ReportTicket = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [ticketFeedback, setTicketFeedback] = useState([]);

    const fetchTicketFeedback = () => {
        api("GET", `report/ticket-feedback`).then((res) => {
            setTicketFeedback(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchTicketFeedback()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: 50,
            fixed: 'left',
        },
        {
            title: 'Ticket Reference',
            key: 'reference',
            render: (row) => (
                <Button type="link" onClick={() => history.push(`/ticket/view/${row.ticket_id}`)}>
                    {row.ticket?.reference}
                </Button>
            ),
            width: 150,
            align: 'center'
        },
        {
            title: 'Customer',
            key: 'customer',
            render: (row) => (
                row.ticket?.requested?.name
            ),
            align: 'center',
            width: 150,
        },
        {
            title: 'Satisfaction Level',
            key: 'star',
            dataIndex: 'star',
            render: (row) => (
                <Rate defaultValue={row} disabled />
            ),
            align: 'center',
            width: 200
        },
        {
            title: 'Description',
            key: 'description',
            dataIndex: 'description',
            width: 200
        },
    ];

    const exportReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/ticket-feedback/xlsx`);
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Ticket Feedback" breadcrumbs={[['Report'], ['Ticket Feedback']]} />
                <Card title={
                    <div className="full-width">
                        <Row justify="space-between">
                            <Col>
                                <Text strong style={{ float: 'left' }}>Ticket Feedback</Text>
                            </Col>
                            <Col>
                                <Space>
                                    <Button onClick={() => exportReport()} shape="round" size="middle" >
                                        <FileExcelOutlined /> Generate Excel
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={ticketFeedback} columns={columns} scroll={{ x: 900 }} />
                            :
                            <Spinner />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default ReportTicket