import React, { useState, useEffect } from 'react';
import { Affix, Card, Avatar, Row, Col, message } from 'antd';
import Text from 'antd/lib/typography/Text';
import { api, currentUser } from '../../services/api'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const fileUrl = process.env.REACT_APP_FILE_URL

const ProfileBox = ({ departments }) => {
    const [loaded, setLoaded] = useState(false)
    const [user, setUser] = useState(null)
    const [avatar, setAvatar] = useState([])

    const backgroundColor = [
        "#a870fe", "#fe895f", "#5968fd", "#2ee1ad",
        "#48c3fe", "#eea4a3", "#ffb119", "#8879f0",
        "#557aaa", "#405c80", "#cfd9e6", "#306ebf"
    ];
    const borderColor = [
        "#a870fe", "#fe895f", "#5968fd", "#2ee1ad",
        "#48c3fe", "#eea4a3", "#ffb119", "#8879f0",
        "#557aaa", "#405c80", "#cfd9e6", "#306ebf"
    ];

    var departmentNames = []
    var departmentCounts = []
    departments?.map((item, i) => {
        departmentNames.push(item.name)
    })
    departments?.map((item, i) => {
        departmentCounts.push(item.total)
    })

    const chartStatus = {
        labels: departmentNames,
        datasets: [
            {
                label: '# of Votes',
                data: departmentCounts,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 1,
            },
        ],
    }

    return (
        <>
            <div style={{ marginBottom: 10 }}>
                <Card style={{ width: '100%', minHeight: 100, padding: 0, color: 'white', borderRadius: 10 }} className="box-shadow-2">
                    <Row className='mb-4' justify="space-between">
                        <Col span={17}>
                            <Text className="m-0">
                                <b>Welcome Back, </b> <br /> Camp Management - SPIERIT Dashboard
                            </Text>
                        </Col>
                        <Col>
                            {
                                currentUser?.image !== null ?
                                    <Avatar src={currentUser?.image} size={50} />
                                    : <Avatar size={50}>{(currentUser?.name.substring(0, 2)).toUpperCase()}</Avatar>
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Text><b>{currentUser?.name}</b></Text>
                            <br />
                            <Text style={{ color: "#999999", fontWeight: 'bold' }}>{currentUser?.department?.name} - {currentUser?.position?.name}</Text>
                        </Col>
                    </Row>
                </Card>
                <br />
                <Affix offsetTop={10}>
                    <Card title="User by Department" style={{ borderRadius: 10 }} className="box-shadow-2">
                        <Row>
                            <Doughnut
                                data={chartStatus}
                                height={300}
                                options={{
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            display: true
                                        }
                                    }
                                }}
                            />
                        </Row>
                    </Card>
                </Affix>
            </div>
        </>
    );
}

export default ProfileBox;
