
import React from 'react'
import { Empty, Card, Timeline, Row, Col } from 'antd'
import moment from 'moment'


const History = ({ ticket }) => {
    return (
        <Card title="Audit Trail">
            {
                ticket?.histories?.length == 0 ? <Empty /> : null
            }
            <Row justify='space-between'>
                <Col span={12}>
                    <Timeline mode="left">
                        {ticket?.histories?.map((item, i) => (
                            <Timeline.Item key={i} label={moment(item.created_at).format('YYYY-MM-DD HH:mm:')}>
                                <span style={{ width: '500px' }} dangerouslySetInnerHTML={{ __html: item.description }} />
                            </Timeline.Item>
                        ))}
                    </Timeline>
                </Col>
            </Row>
        </Card>
    )
}

export default History